import firebase, { database } from "../firebase";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as actionTypes from "../actionTypes";
import * as constants from "../Constatnts";
import alertSound from "../audio/audio/electronic-school-bell-81033.mp3";
export function inventoryPreConditions(
  registration,
  store,
  transactionType,
  transaction = null
) {
  return async (dispatch, getState) => {
    //right now this is only for MOD
    let status = true;
    let msg = "";

    // if(registration.key==="Y1mBnL02vfcKtZKda3x7"){
    // console.log('registration',registration)
    if (registration.MonthEndStockUpdateCheck) {
      //check if transaction is updating stock qtys and is not stock update module
      if (
        transactionType.UpdateStockInwardOrOutward !== "" &&
        transactionType.TransactionType.toLowerCase() !== "stock update"
      ) {
        //check if stock update is done for previous month end or not
        let d = new Date(); // current date
        d.setDate(1); // going to 1st of the month
        d.setHours(-1); // going to last hour before this date even started.
        console.log("d", d);
        let transactionDate = d.toISOString().split("T")[0]; //"2022-09-30";//
        // console.log('transactionDate',transactionDate)
        let stockUpdateTransactionTypeKey = "";
        if (
          getState().config.TransactionTypes.filter(
            (f) => f.TransactionType.toLowerCase() === "stock update"
          ).length > 0
        );
        {
          stockUpdateTransactionTypeKey = getState().config.TransactionTypes.filter(
            (f) => f.TransactionType.toLowerCase() === "stock update"
          )[0].key;
          let searchParameters = {
            from: 0,
            size: 20,
            track_total_hits: true,
            query: {
              bool: {
                must: [
                  { match: { RegistrationID: registration.key } },
                  {
                    range: {
                      TransactionDate: {
                        lte: transactionDate,
                        gte: transactionDate,
                        format: "yyyy-MM-dd",
                      },
                    },
                  },
                  {
                    regexp: {
                      "TransactionType.key":
                        "@" +
                        stockUpdateTransactionTypeKey.toLocaleLowerCase() +
                        ".*",
                    },
                  },
                  { term: { "Store.key.keyword": store.key } },
                ],
                // "must_not":[],
                // "should":[
                //     {"term":{"Store.key.keyword":store.key}},
                //     {"term":{"ToStoreID.keyword":store.key}}
                // ]
                // ,
                // "filter":{
                //     "bool":
                //         {
                //             "must":
                //             [
                //                 {"regexp":{"TransactionType.key":"@" + stockUpdateTransactionTypeKey.toLocaleLowerCase() + ".*"}}
                //             ]
                //             ,"should":[]
                //         }
                //     },
                // "minimum_should_match":1
              },
            },
            sort: [{ ActionOn: { order: "desc" } }],
          };
          // console.log('searchParameters',JSON.stringify(searchParameters))
          let addMessage = firebase.functions().httpsCallable("getInventory");
          let transactionCount = await addMessage({
            text: searchParameters,
          }).then(function(result) {
            const inventory = [];
            // console.log('result',result);
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
              inventory.push({ key: data._id, ...data._source });
            });
            // console.log('inventory', inventory)
            return Number(sanitizedMessage.hits.total.value);
            // return { totalItemsCount: sanitizedMessage.hits.total.value, searchResult: inventory }
          });
          if (transactionCount === 0) {
            status = false;
            msg =
              "Stock update not done for previous month. Please perform stock update and then try again.";
            return { Status: status, Message: msg };
          }
        }
      }
    }
    return { Status: status, Message: msg };
  };
}

export function getModules() {
  return (dispatch) => {
    return database
      .collection("GlobalConfigurations")
      .doc("_Master Modules")
      .collection("aclModules")
      .get()
      .then((querySnapshot) => {
        let modules = [];
        querySnapshot.forEach((doc) => {
          let module = {
            name: doc.id,
            perms: doc.data().perms,
          };
          modules.push(module);
        });
        return modules;
      });
  };
}

export function saveFCMToken(token) {
  return (dispatch) => {
    return database
      .collection("FCMTokens")
      .add({
        Token: token,
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((docRef) => {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error saving Token: ", error);
      });
  };
}

export function updateFCMToken(token, registrationID, storeID) {
  return (dispatch) => {
    database
      .collection("FCMTokens")
      .where("Token", "==", token)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          if (doc.exists) {
            await database
              .collection("FCMTokens")
              .doc(doc.id)
              .update({
                RegistrationID: registrationID,
                StoreID: storeID,
                ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
              });
            return doc.id;
          }
        });
      });
  };
}

export function getFCMTokenDetails(token) {
  return (dispatch) => {
    return database
      .collection("FCMTokens")
      .where("Token", "==", token)
      .get()
      .then((querySnapshot) => {
        let fcmToken = null;
        querySnapshot.forEach((doc) => {
          fcmToken = {
            key: doc.id,
            ...doc.data(),
          };
        });
        return fcmToken;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function callEventHook(event, data) {
  return (dispatch, getState) => {
    let Event = {
      Event: event,
      Data: data,
      RegistrationID: getState().user.store.RegistrationID,
      StoreID: getState().user.store.key,
    };
    let addMessage = firebase.functions().httpsCallable("callEventHook");
    return addMessage({ text: Event }).then(function(result) {
      return true;
    });
  };
}

export function getToastr(
  message,
  options = null,
  type = "success",
  props = null,
  stopAlert = null,
  notificationType = "",
  notificationTitle = ""
) {
  return (dispatch) => {
    if (options) {
      if (!options.hasOwnProperty("positionClass")) {
        options.positionClass = "toast-bottom-right";
      }
      toastr.options = options;
    } else {
      toastr.options = {
        positionClass: "toast-bottom-right",
        hideDuration: 300,
        timeOut: 5000,
      };
    }
    if (type === "success") toastr.success(message);
    else {
      toastr.options = {
        closeButton: true,
        debug: false,
        newestOnTop: true,
        progressBar: false,
        positionClass: "toast-bottom-right",
        preventDuplicates: true,
        showDuration: "300",
        hideDuration: "1000",
        timeOut: "5000",
        extendedTimeOut: 0,
        showEasing: "swing",
        hideEasing: "linear",
        showMethod: "fadeIn",
        hideMethod: "fadeOut",
        tapToDismiss: false,
      };

      if (notificationType === "order") {
        toastr.options.onclick = function() {
          stopAlert();
          props.history.push({
            pathname: "/home/sales/onlineorders",
            state: {
              orderID: "",
              transactionType: null,
              type: actionTypes.ONLINE_ORDER,
            },
          });
        };
        toastr.info(message, "Order Placed!!");
      } else if (notificationType === "onlineorder") {
        //
        const audio = new Audio(alertSound);
        toastr.options.onShown = function() {
          // const audio = new Audio('https://drive.google.com/uc?export=download&id=1M95VOpto1cQ4FQHzNBaLf0WFQglrtWi7');
          audio.play();
        };
        toastr.options.onclick = function() {
          // stopAlert()
          audio.pause();
          audio.currentTime = 0;
          props.history.push({ pathname: "/home/sales/deliverylist" });
          // window.location.reload(false);
        };
        toastr.options.onCloseClick = function() {
          console.log("onclose click");
          audio.pause();
          audio.currentTime = 0;
        };
        toastr.options.onHidden = function() {
          console.log("onHidden");
          audio.pause();
          audio.currentTime = 0;
        };
        toastr.info(message, "");
      } else if (notificationType === "fcm") {
        toastr.info(message, notificationTitle);
      }
    }
  };
}

export function getVisibility(module, access) {
  return (dispatch, getState) => {
    let visibility = true;
    if (getState().user.acl) {
      if (access === "create")
        visibility = getState()
          .user.acl.filter((e) => e.id === module)
          .map((acl) => acl.perms.create)[0];
      else if (access === "read")
        visibility = getState()
          .user.acl.filter((e) => e.id === module)
          .map((acl) => acl.perms.read)[0];
      else if (access === "update")
        visibility = getState()
          .user.acl.filter((e) => e.id === module)
          .map((acl) => acl.perms.update)[0];
      else if (access === "delete")
        visibility = getState()
          .user.acl.filter((e) => e.id === module)
          .map((acl) => acl.perms.delete)[0];
      else if (access === "export")
        visibility = getState()
          .user.acl.filter((e) => e.id === module)
          .map((acl) => acl.perms.export)[0];
    }
    return visibility ? "inline" : "none";
  };
}

export function showModule(module, access = "read") {
  return (dispatch, getState) => {
    let visibility = false;

    if (
      getState().user.user.Role === "Power User" ||
      getState().user.user.Role === "Store Owner" ||
      getState().user.user.Role === "Store Manager" ||
      getState().user.user.Role === "Store Cashier"
    ) {
      // if (getState().user.acl) {
      //     if (access === "create")
      //         visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.create)[0];
      //     else if (access === "read")
      //         visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.read)[0];
      //     else if (access === "update")
      //         visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.update)[0];
      //     else if (access === "delete")
      //         visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.delete)[0];
      //     else if (access === "export")
      //         visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.export)[0];
      // }
      visibility = true;
    } else {
      if (
        getState().user.acl.filter((e) => e.id === module && e.perms.read)
          .length > 0
      ) {
        // console.log('i.TransactionType', i.TransactionType);
        // console.log('perms', this.props.user.acl.filter(e=>e.id === i.TransactionType && e.perms.read));
        visibility = true;
      }
    }
    return visibility;
  };
}

// export function getVisibility(module, access) {
//     return (dispatch, getState) => {
//         let visibility = true
//         if (getState().user.acl) {
//             if (access === "create")
//                 visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.create)[0];
//             else if (access === "read")
//                 visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.read)[0];
//             else if (access === "update")
//                 visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.update)[0];
//             else if (access === "delete")
//                 visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.delete)[0];
//             else if (access === "export")
//                 visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.export)[0];
//         }
//         return visibility ? "inline" : "none"
//     }
// }

export function importCSV(
  filePath,
  importType,
  fileName,
  emailID,
  productImportType,
  forStoreID,
  forStore,
  overWriteExistingCustomerData = false
) {
  return (dispatch, getState) => {
    console.log("forStoreID", forStoreID);
    return database
      .collection("Import")
      .add({
        FileName: fileName,
        EmailID: emailID,
        ImportType: importType,
        ProductImportType: productImportType,
        ForStoreID: forStoreID,
        ForStore: forStore,
        FilePath: filePath,
        RegistrationID: getState().user.user.RegistrationID,
        StoreID: getState().user.store.key,
        RowsProcessed: 0,
        Status: "Pending",
        ProcessingStartTime: null,
        ProcessingEndTime: null,
        OverWriteExistingCustomerData: overWriteExistingCustomerData,
        Action: "NEW",
        ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        ActionBy: getState().user.user.Name,
        ActionByEmailID: getState().user.user.EmailID,
        ActionByUID: getState().user.user.UID,
      })
      .then(function(docRef) {
        return docRef.id;
        // console.log(' import',docRef.id)
      })
      .catch(function(error) {
        console.error("Error adding import: ", error);
      });
  };
}

export function getImportDetails(key) {
  return (dispatch) => {
    return database
      .collection("ImportLogs")
      .doc(key)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log("Import found", doc.data());
          return doc.data();
        } else {
          console.log("No such import!");
        }
      });
  };
}

export function sendEmail(email) {
  return (dispatch) => {
    email.CreatedOn = firebase.firestore.FieldValue.serverTimestamp();
    return database
      .collection("Emails")
      .add({ ...email })
      .then((docRef) => {
        return docRef.id;
        // console.log(' Emails',docRef.id)
      })
      .catch(function(error) {
        console.error("Error adding Email: ", error);
      });
  };
}

export function sendSMS(sms) {
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable("sendSMS");
    return addMessage({ text: sms }).then(function(result) {
      if (result.data.text) {
        return true;
      } else {
        return false;
      }
    });
  };
}

export function getPrintTemplate(template) {
  // console.log(template);
  return (dispatch) => {
    return database
      .collection("PrintTemplates")
      .doc(template)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // console.log(doc.data().PrintTemplate);
          return doc.data();
        } else {
          console.log("No such template found!");
        }
      });
  };
}

export function getImportLogList(registrationID, importType) {
  return (dispatch) => {
    return database
      .collection("ImportLogs")
      .where("RegistrationID", "==", registrationID)
      .where("ImportType", "==", importType)
      .orderBy("ActionOn", "desc")
      .get()
      .then((querySnapshot) => {
        let logs = [];
        querySnapshot.forEach((doc) => {
          logs.push({ ...doc.data(), key: doc.id });
        });
        // console.log('logs',logs)
        return logs;
      });
  };
}

export function getImportLogs(key) {
  console.log("Key", key);
  return (dispatch) => {
    return database
      .collection("ImportLogs")
      .doc(key)
      .collection("Log")
      .get()
      .then((querySnapshot) => {
        let logs = [];
        querySnapshot.forEach((doc) => {
          logs.push(doc.data());
        });
        // console.log('logs',logs)
        return logs;
      });
  };
}

export function convertNumberToWords(amount) {
  return (dispatch) => {
    var words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
      var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
      var received_n_array = new Array();
      for (var i = 0; i < n_length; i++) {
        received_n_array[i] = number.substr(i, 1);
      }
      for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
        n_array[i] = received_n_array[j];
      }
      for (var i = 0, j = 1; i < 9; i++, j++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          if (n_array[i] == 1) {
            n_array[j] = 10 + parseInt(n_array[j]);
            n_array[i] = 0;
          }
        }
      }
      let value = "";
      for (var i = 0; i < 9; i++) {
        if (i == 0 || i == 2 || i == 4 || i == 7) {
          value = n_array[i] * 10;
        } else {
          value = n_array[i];
        }
        if (value != 0) {
          words_string += words[value] + " ";
        }
        if (
          (i == 1 && value != 0) ||
          (i == 0 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Crores ";
        }
        if (
          (i == 3 && value != 0) ||
          (i == 2 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Lakhs ";
        }
        if (
          (i == 5 && value != 0) ||
          (i == 4 && value != 0 && n_array[i + 1] == 0)
        ) {
          words_string += "Thousand ";
        }
        if (
          i == 6 &&
          value != 0 &&
          n_array[i + 1] != 0 &&
          n_array[i + 2] != 0
        ) {
          words_string += "Hundred and ";
        } else if (i == 6 && value != 0) {
          words_string += "Hundred ";
        }
      }
      words_string = words_string.split("  ").join(" ");
    }
    return "Rupees " + words_string;
  };
}

export function addDays(days) {
  return (dispatch) => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };
}

export function saveDataForBarcodePrint(barcodePrintData) {
  return (dispatch, getState) => {
    barcodePrintData.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    barcodePrintData.ActionBy = getState().user.user.Name;
    barcodePrintData.ActionByUID = getState().user.user.UID;
    barcodePrintData.ActionByEmailID = getState().user.user.EmailID;
    barcodePrintData.Action = "NEW";
    return database
      .collection("BarcodePrintData")
      .add({
        ...barcodePrintData,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding barcode print data: ", error);
      });
  };
}

export function isMobileApp() {
  return (dispatch) => {
    // return true //window cordova
    if (window.screen.width <= 480) {
      return true;
    } else {
      return false;
    }
  };
}

export function onStoreChangeResetConfig() {
  return (dispatch) => {
    let state = null;
    dispatch({ type: actionTypes.STORE_RESET_CONFIG, state });
  };
}

export function onStoreChangeResetUser() {
  return (dispatch) => {
    let state = null;
    dispatch({ type: actionTypes.STORE_RESET_USER, state });
  };
}

export function connectBlueToothPrinter() {
  return (dispatch) => {
    let connected = {};
    // alert("window" + window)
    window.BTPrinter.connect(
      (data) => {
        if (data.name && data.address) {
          connected = {
            PrinterName: data.name,
            PrinterAddress: data.address,
          };
        }
        alert("successfully connected");
        return connected;
      },
      (error) => {
        alert("error " + error);
        return null;
      }
    );
  };
}

export function printTextBlueToothPrinter(
  printerName,
  printerAddress,
  printText,
  printAlignment,
  printFontSize,
  printLineSpacing
) {
  return (dispatch, getState) => {
    alert("printText:" + printText);
    return window.BTPrinter.printText(
      (data) => {
        alert(JSON.stringify(data));
        return JSON.stringify(data);
      },
      (err) => {
        alert(JSON.stringify(err));
        console.log("Error");
        console.log(err);
        return JSON.stringify(err);
      },
      {
        name: printerName,
        address: printerAddress,
        text: printText,
        alignment: printAlignment === "" ? "center" : printAlignment, // center, left, right
        fontSize: printFontSize === "" ? "normal" : printFontSize, // large, normal
        lineSpacing: printLineSpacing === "" ? 5 : printLineSpacing,
      }
    );
  };
}

export function onInventoryLineItemChange(
  lineItem,
  placeOfSupply,
  computeTax = true,
  transactionType
) {
  return (dispatch, getState) => {
    let includePriceAndTax = false;
    if (transactionType.hasOwnProperty("IncludePriceAndTax")) {
      includePriceAndTax = transactionType.IncludePriceAndTax;
    }
    if (!includePriceAndTax) {
      lineItem.SubTotal = 0;
      lineItem.ChargeTaxOnProduct = false;
      lineItem.TaxInclusive = false;
      lineItem.DiscountPer = 0;
      lineItem.DiscountAmount = 0;
      lineItem.SubTotalWithDiscount = 0;
      lineItem.TaxableAmount = 0;
      lineItem.TaxComponentAmount = [];
      lineItem.TotalTax = 0;
      lineItem.Amount = 0;
      return lineItem;
    }

    let subTotal = this.roundUpTo2(
      Number(lineItem.SupplyPrice) * Number(lineItem.Qty)
    );
    let discountAmt;
    let discountPer;
    if (
      Number(lineItem.DiscountAmount) > 0 &&
      Number(lineItem.DiscountPer) === 0
    ) {
      //calculate discount per based on discount amount
      discountPer = Number(
        (
          (Number(lineItem.DiscountAmount) * 100) /
          Number(lineItem.SubTotal)
        ).toFixed(3)
      );
      discountAmt = this.roundUpTo2(lineItem.DiscountAmount);
    } else {
      discountPer = Number(lineItem.DiscountPer);
      discountAmt = this.roundUpTo2(
        (Number(subTotal) * Number(lineItem.DiscountPer)) / 100
      );
    }
    let subTotalWithDiscount = this.roundUpTo2(
      Number(subTotal) - Number(discountAmt)
    );
    let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct;
    let priceBasedTax = lineItem.PriceBasedTax;
    let taxInclusive = lineItem.TaxInclusive;
    let taxableAmount = 0;
    let totalTax = 0;
    let amount = 0;
    let taxComponentAmount = [];
    let supplyPriceWithoutTax = 0; //TBD

    if (chargeTaxOnProduct && computeTax) {
      if (priceBasedTax) {
        let unitPriceAfterDiscount = this.roundUpTo2(
          Number(subTotalWithDiscount) / Number(lineItem.Qty)
        );
        //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
        //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
        lineItem.Product.TaxPriceRange.map((t) => {
          if (
            Number(unitPriceAfterDiscount) >= Number(t.FromPrice) &&
            Number(unitPriceAfterDiscount) <= Number(t.ToPrice)
          ) {
            lineItem.TaxID = t.TaxID;
            lineItem.TaxGroup = t.TaxGroup;
            return;
          }
        });
      }
      let taxfound = false;
      let customerFromSameState = true; //TBD IMP IMP

      if (placeOfSupply !== "" && getState().user.store.State !== "") {
        if (
          placeOfSupply.toLowerCase() !==
          getState().user.store.State.toLowerCase()
        ) {
          customerFromSameState = false;
        }
      }
      getState()
        .config.ProductTaxes.filter((t) => t.key === lineItem.TaxID.trim())
        .map((taxGroup, index) => {
          taxfound = true;
          let taxAmount = 0;
          let taxComponentTemp = [];
          taxGroup.Taxes.map((t) => {
            if (
              customerFromSameState &&
              (t.TaxType === "CGST" || t.TaxType === "SGST")
            ) {
              taxComponentTemp.push({
                TaxName: t.TaxName,
                TaxPercentage: Number(t.TaxPercentage),
                TaxType: t.TaxType,
                TaxAmount: 0,
              });
            } else if (!customerFromSameState && t.TaxType === "IGST") {
              taxComponentTemp.push({
                TaxName: t.TaxName,
                TaxPercentage: Number(t.TaxPercentage),
                TaxType: t.TaxType,
                TaxAmount: 0,
              });
            } else if (
              t.TaxType !== "CGST" &&
              t.TaxType !== "IGST" &&
              t.TaxType !== "SGST"
            ) {
              taxComponentTemp.push({
                TaxName: t.TaxName,
                TaxPercentage: Number(t.TaxPercentage),
                TaxType: t.TaxType,
                TaxAmount: 0,
              });
            }
          });

          if (taxInclusive) {
            //tax inclusive
            let totalTaxRate = 0;
            taxComponentTemp.map((t) => {
              totalTaxRate = this.roundUpTo2(
                Number(t.TaxPercentage) + Number(totalTaxRate)
              );
            });
            totalTax = this.roundUpTo2(
              (Number(subTotalWithDiscount) * Number(totalTaxRate)) /
                (100 + Number(totalTaxRate))
            );
            taxableAmount = this.roundUpTo2(
              Number(subTotalWithDiscount) - Number(totalTax)
            );
            amount = this.roundUpTo2(subTotalWithDiscount);
            taxComponentTemp.map((t) => {
              taxAmount = this.roundUpTo2(
                (Number(totalTax) *
                  ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate))) /
                  100
              );
              taxComponentAmount.push({
                TaxName: t.TaxName,
                TaxPercentage: Number(t.TaxPercentage),
                TaxType: t.TaxType,
                TaxAmount: taxAmount,
              });
            });
          } else {
            taxableAmount = subTotalWithDiscount;
            taxComponentTemp.map((t) => {
              taxAmount = this.roundUpTo2(
                (Number(taxableAmount) * Number(t.TaxPercentage)) / 100
              );
              totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount));
              taxComponentAmount.push({
                TaxName: t.TaxName,
                TaxPercentage: Number(t.TaxPercentage),
                TaxType: t.TaxType,
                TaxAmount: taxAmount,
              });
            });
            amount = this.roundUpTo2(taxableAmount + totalTax);
          }
        });
      //tax is not found then
      if (!taxfound) {
        //alert("Tax not found for selected product. Please check item ")
        taxableAmount = 0;
        taxComponentAmount = [];
        totalTax = 0;
        amount = subTotalWithDiscount;
      }
    } else {
      taxableAmount = 0;
      taxComponentAmount = [];
      totalTax = 0;
      amount = subTotalWithDiscount;
    }
    // lineItem.Qty = qty
    // lineItem.RetailPrice = price
    lineItem.SubTotal = this.roundUpTo2(subTotal);
    lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
    lineItem.TaxInclusive = taxInclusive;
    lineItem.DiscountPer = this.roundUpTo2(discountPer);
    lineItem.DiscountAmount = this.roundUpTo2(discountAmt);
    lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount);
    lineItem.TaxableAmount = this.roundUpTo2(taxableAmount);
    lineItem.TaxComponentAmount = taxComponentAmount;
    lineItem.TotalTax = this.roundUpTo2(totalTax);
    lineItem.Amount = this.roundUpTo2(amount);
    return lineItem;
  };
}

export function getStateCodeMaster() {
  return (dispatch) => {
    let states = [
      { code: 1, name: "1-JAMMU AND KASHMIR" },
      { code: 2, name: "2-HIMACHAL PRADESH" },
      { code: 3, name: "3-PUNJAB" },
      { code: 4, name: "4-CHANDIGARH" },
      { code: 5, name: "5-UTTARAKHAND" },
      { code: 6, name: "6-HARYANA" },
      { code: 7, name: "7-DELHI" },
      { code: 8, name: "8-RAJASTHAN" },
      { code: 9, name: "9-UTTAR PRADESH" },
      { code: 10, name: "10-BIHAR" },
      { code: 11, name: "11-SIKKIM" },
      { code: 12, name: "12-ARUNACHAL PRADESH" },
      { code: 13, name: "13-NAGALAND" },
      { code: 14, name: "14-MANIPUR" },
      { code: 15, name: "15-MIZORAM" },
      { code: 16, name: "16-TRIPURA" },
      { code: 17, name: "17-MEGHALAYA" },
      { code: 18, name: "18-ASSAM" },
      { code: 19, name: "19-WEST BENGAL" },
      { code: 20, name: "20-JHARKHAND" },
      { code: 21, name: "21-ORISSA" },
      { code: 22, name: "22-CHHATTISGARH" },
      { code: 23, name: "23-MADHYA PRADESH" },
      { code: 24, name: "24-GUJARAT" },
      { code: 25, name: "25-DAMAN AND DIU" },
      { code: 26, name: "26-DADAR AND NAGAR HAVELI" },
      { code: 27, name: "27-MAHARASTRA" },
      { code: 29, name: "29-KARNATAKA" },
      { code: 30, name: "30-GOA" },
      { code: 31, name: "31-LAKSHADWEEP" },
      { code: 32, name: "32-KERALA" },
      { code: 33, name: "33-TAMIL NADU" },
      { code: 34, name: "34-PUDUCHERRY" },
      { code: 35, name: "35-ANDAMAN AND NICOBAR" },
      { code: 36, name: "36-TELANGANA" },
      { code: 37, name: "37-ANDHRA PRADESH" },
      { code: 97, name: "97-OTHER TERRITORY" },
      { code: 99, name: "99-OTHER COUNTRY" },
    ];
    return states;
  };
}

export function getModeOfTransport() {
  return (dispatch) => {
    let modeOfTransport = [
      {
        Code: "1",
        Name: "Road",
      },
      {
        Code: "2",
        Name: "Rail",
      },
      {
        Code: "3",
        Name: "Air",
      },
      {
        Code: "4",
        Name: "Ship",
      },
    ];
    return modeOfTransport;
  };
}

export function getVehicleType() {
  return (dispatch) => {
    let vehicleType = [
      {
        Code: "R",
        Name: "Regular",
      },
      {
        Code: "O",
        Name: "ODC(Over Dimentional Cargo)",
      },
    ];
    return vehicleType;
  };
}

export function setPageFilters(
  page,
  searchParameters,
  searchText,
  recordFrom,
  activePage
) {
  return (dispatch, getState) => {
    // console.log("page", page);
    // console.log("page", searchParameters);

    let pageFilters = getState().config.PageFilters;
    // console.log("pageFilters", JSON.stringify(pageFilters));

    if (pageFilters.filter((f) => f.PageName === page).length > 0) {
      // console.log('found')
      pageFilters.map((m) => {
        if (m.PageName === page) {
          m.PageFilters = searchParameters;
          m.SearchText = searchText ? searchText : "";
          m.RecordFrom = recordFrom ? recordFrom : 0;
          m.ActivePage = activePage ? activePage : 0;
          // console.log("pageFilters", m.PageFilters);
        }
      });
    } else {
      pageFilters.push({
        PageName: page,
        PageFilters: searchParameters,
        SearchText: searchText ? searchText : "",
        RecordFrom: recordFrom ? recordFrom : 0,
        ActivePage: activePage ? activePage : 0,
      });
    }
    dispatch({ type: actionTypes.PUSH_PAGE_FILTERS_TO_REDUX, pageFilters });
  };
}

export function getPageFilters(page) {
  return (dispatch, getState) => {
    let objPage = getState().config.PageFilters.filter(
      (f) => f.PageName === page
    );
    if (objPage.length > 0) {
      if (objPage[0].hasOwnProperty("SearchText")) {
        return {
          PageFilters: objPage[0].PageFilters,
          SearchText: objPage[0].SearchText,
          RecordFrom: objPage[0].RecordFrom ? objPage[0].RecordFrom : 0,
          ActivePage: objPage[0].ActivePage ? objPage[0].ActivePage : 1,
        };
      } else {
        return objPage[0].PageFilters;
      }
    } else {
      return null;
    }
  };
}

export function saveDashboardSettings(key, dashboardSettings) {
  return (dispatch, getState) => {
    dashboardSettings.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    dashboardSettings.ActionBy = getState().user.user.Name;
    dashboardSettings.ActionByUID = getState().user.user.UID;
    dashboardSettings.ActionByEmailID = getState().user.user.EmailID;

    if (key !== "") {
      dashboardSettings.Action = constants.AUDIT_TRAIL_EDIT;
      return database
        .collection("DashboardSettings")
        .doc(key)
        .update({
          ...dashboardSettings,
        })
        .then(() => {
          return key;
        })
        .catch(function(error) {
          console.error("Error updating dashboardSettings: ", error);
        });
    } else {
      dashboardSettings.Action = constants.AUDIT_TRAIL_NEW;
      return database
        .collection("DashboardSettings")
        .add({
          ...dashboardSettings,
        })
        .then(function(docRef) {
          return docRef.id;
        })
        .catch(function(error) {
          console.error("Error adding dashboardSettings: ", error);
        });
    }
  };
}

export function getDashBoardSettings(RegistrationID, UID) {
  return (dispatch) => {
    return database
      .collection("DashboardSettings")
      .where("RegistrationID", "==", RegistrationID)
      .get()
      .then((querySnapshot) => {
        let dashboardSetting = null;
        querySnapshot.forEach((doc) => {
          dashboardSetting = {
            key: doc.id,
            ...doc.data(),
          };
        });
        return dashboardSetting;
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getFoodDeliveryPlatforms() {
  return (dispatch) => {
    let foodDeliveryPlatforms = [
      {
        Code: "swiggystore",
        Name: "Swiggy Stores",
      },
      {
        Code: "zomatomarket",
        Name: "Zomato Market",
      },
      {
        Code: "swiggy",
        Name: "Swiggy",
      },
      {
        Code: "zomato",
        Name: "Zomato",
      },
      {
        Code: "ubereats",
        Name: "Uber Eats",
      },
      {
        Code: "urbanpiper",
        Name: "Urban piper",
      },
      {
        Code: "satellite",
        Name: "Satellite",
      },
    ];
    return foodDeliveryPlatforms;
  };
}

// export function getYears()
// {
//     return (dispatch) => {
//         let years = [2018, 2019, 2020];
//         return years
//     }
// }

export function getYears() {
  return (dispatch) => {
    let years = [2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018];
    return years;
  };
}

export function getMonths(year) {
  return (dispatch) => {
    let months = [];
    months.push({ Month: 0, MonthName: "January" });
    months.push({ Month: 1, MonthName: "February" });
    months.push({ Month: 2, MonthName: "March" });
    months.push({ Month: 3, MonthName: "April" });
    months.push({ Month: 4, MonthName: "May" });
    months.push({ Month: 5, MonthName: "June" });
    months.push({ Month: 6, MonthName: "July" });
    months.push({ Month: 7, MonthName: "August" });
    months.push({ Month: 8, MonthName: "September" });
    months.push({ Month: 9, MonthName: "October" });
    months.push({ Month: 10, MonthName: "November" });
    months.push({ Month: 11, MonthName: "December" });
    return months;
  };
}

export function getStartDateOfMonth(year, month) {
  return (dispatch) => {
    var firstDay = new Date(year, month, 1);
    let fromDate = new Date(firstDay).setHours(23, 59);
    console.log("startDate", new Date(fromDate).toISOString().split("T")[0]);
    return new Date(fromDate).toISOString().split("T")[0];
  };
}

export function getEndDateOfMonth(year, month) {
  console.log("year", year);
  console.log("month", month);

  return (dispatch) => {
    var lastDay = new Date(year, Number(month) + 1, 0);
    let toDate = new Date(lastDay).setHours(23, 59);
    console.log("endDate", new Date(toDate).toISOString().split("T")[0]);
    return new Date(toDate).toISOString().split("T")[0];
  };
}

//Offline Start
export function saveConnectionStatus(status, action) {
  // console.log(status);
  return (dispatch) => {
    let connectionStatus = {
      Status: status === true ? "connected" : "disconnected",
    };
    dispatch({ type: actionTypes.GET_CONNECTION_STATUS, connectionStatus });
  };
}

//Offline End

export function isElectron() {
  return (dispatch, getState) => {
    return false;
    if (typeof require !== "function") return false;
    if (typeof window !== "object") return false;
    try {
      const electron = require("electron");
      if (typeof electron !== "object") return false;
    } catch (e) {
      return false;
    }
    return true;
  };
}

export function getPetTypes() {
  return (dispatch) => {
    let petTypes = [];
    petTypes.push({ Value: "Dogs", Name: "Dogs" });
    petTypes.push({ Value: "Cats", Name: "Cats" });
    petTypes.push({ Value: "Fish", Name: "Fish" });
    petTypes.push({ Value: "Birds", Name: "Birds" });
    petTypes.push({ Value: "Others", Name: "Others" });
    return petTypes;
  };
}

export function getPetBreeds() {
  return (dispatch) => {
    let petBreeds = [];
    petBreeds.push({
      Type: "Dogs",
      Value: "Labrador Retriever",
      Name: "Labrador Retriever",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "German Shepherd",
      Name: "German Shepherd",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "Golden Retriever",
      Name: "Golden Retriever",
    });
    petBreeds.push({ Type: "Dogs", Value: "Dachshund", Name: "Dachshund" });
    petBreeds.push({ Type: "Dogs", Value: "Beagle", Name: "Beagle" });
    petBreeds.push({ Type: "Dogs", Value: "Boxer", Name: "Boxer" });
    petBreeds.push({
      Type: "Dogs",
      Value: "Tibetan Mastiff",
      Name: "Tibetan Mastiff",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "English Cocker Spaniel",
      Name: "English Cocker Spaniel",
    });
    petBreeds.push({ Type: "Dogs", Value: "Pug", Name: "Pug" });
    petBreeds.push({ Type: "Dogs", Value: "Rottweiler", Name: "Rottweiler" });
    petBreeds.push({ Type: "Dogs", Value: "Doberman", Name: "Doberman" });
    petBreeds.push({ Type: "Dogs", Value: "Great Dane", Name: "Great Dane" });
    petBreeds.push({ Type: "Dogs", Value: "Pomeranian", Name: "Pomeranian" });
    petBreeds.push({ Type: "Dogs", Value: "Dalmatian", Name: "Dalmatian" });
    petBreeds.push({
      Type: "Dogs",
      Value: "Indian Spitz",
      Name: "Indian Spitz",
    });
    petBreeds.push({ Type: "Dogs", Value: "Pekingese", Name: "Pekingese" });
    petBreeds.push({ Type: "Dogs", Value: "Akita", Name: "Akita" });
    petBreeds.push({ Type: "Dogs", Value: "St. Bernard", Name: "St. Bernard" });
    petBreeds.push({
      Type: "Dogs",
      Value: "Bichon Frise",
      Name: "Bichon Frise",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "French Bulldog",
      Name: "French Bulldog",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "English Bulldog",
      Name: "English Bulldog",
    });
    petBreeds.push({
      Type: "Dogs",
      Value: "Yorkshire Terrier",
      Name: "Yorkshire Terrier",
    });
    petBreeds.push({ Type: "Dogs", Value: "Cane Corso", Name: "Cane Corso" });
    petBreeds.push({ Type: "Dogs", Value: "Chow Chow", Name: "Chow Chow" });
    petBreeds.push({ Type: "Dogs", Value: "Maltese", Name: "Maltese" });
    petBreeds.push({ Type: "Dogs", Value: "Poodle", Name: "Poodle" });
    petBreeds.push({
      Type: "Dogs",
      Value: "Siberian Hasky",
      Name: "Siberian Hasky",
    });
    petBreeds.push({ Type: "Dogs", Value: "Shihtzu", Name: "Shihtzu" });
    petBreeds.push({ Type: "Dogs", Value: "Pitbull", Name: "Pitbull" });
    petBreeds.push({ Type: "Dogs", Value: "Indian Dog", Name: "Indian Dog" });

    petBreeds.push({ Type: "Cats", Value: "Bombay Cat", Name: "Bombay Cat" });
    petBreeds.push({
      Type: "Cats",
      Value: "Himalayan Cat",
      Name: "Himalayan Cat",
    });
    petBreeds.push({ Type: "Cats", Value: "Siamese Cat", Name: "Siamese Cat" });
    petBreeds.push({ Type: "Cats", Value: "Persian Cat", Name: "Persian Cat" });
    petBreeds.push({ Type: "Cats", Value: "Maine Coon", Name: "Maine Coon" });
    petBreeds.push({
      Type: "Cats",
      Value: "The American Bobtail",
      Name: "The American Bobtail",
    });
    petBreeds.push({ Type: "Cats", Value: "Spotted Cat", Name: "Spotted Cat" });

    petBreeds.push({ Type: "Birds", Value: "Dove", Name: "Dove" });
    petBreeds.push({
      Type: "Birds",
      Value: "Zebra Finches",
      Name: "Zebra Finches",
    });
    petBreeds.push({
      Type: "Birds",
      Value: "Hyacinth Macaw",
      Name: "Hyacinth Macaw",
    });
    petBreeds.push({
      Type: "Birds",
      Value: "Pineapple Conures",
      Name: "Pineapple Conures",
    });
    petBreeds.push({ Type: "Birds", Value: "Cockatiels", Name: "Cockatiels" });
    petBreeds.push({ Type: "Birds", Value: "Parrotlet", Name: "Parrotlet" });
    petBreeds.push({ Type: "Birds", Value: "Budgies", Name: "Budgies" });
    petBreeds.push({
      Type: "Birds",
      Value: "Hahn’s Macaw",
      Name: "Hahn’s Macaw",
    });
    petBreeds.push({ Type: "Birds", Value: "Love Birds", Name: "Love Birds" });

    petBreeds.push({ Type: "Fish", Value: "Betta Fish", Name: "Betta Fish" });
    petBreeds.push({ Type: "Fish", Value: "Guppy Fish", Name: "Guppy Fish" });
    petBreeds.push({
      Type: "Fish",
      Value: "Flowerhorn Fish",
      Name: "Flowerhorn Fish",
    });
    petBreeds.push({ Type: "Fish", Value: "Gold Fish", Name: "Gold Fish" });
    petBreeds.push({ Type: "Fish", Value: "Koi Fish", Name: "Koi Fish" });
    petBreeds.push({ Type: "Fish", Value: "Oscar Fish", Name: "Oscar Fish" });
    petBreeds.push({ Type: "Fish", Value: "Catfishes", Name: "Catfishes" });
    petBreeds.push({
      Type: "Fish",
      Value: "Grass Carp Fish",
      Name: "Grass Carp Fish",
    });

    return petBreeds;
  };
}

export function saveREExciseLog(log) {
  return (dispatch, getState) => {
    log.RegistrationID = getState().user.user.RegistrationID;
    log.StoreID = getState().user.store.key;
    log.ActionOn = firebase.firestore.FieldValue.serverTimestamp();
    log.ActionBy = getState().user.user.Name;
    log.ActionByUID = getState().user.user.UID;
    log.ActionByEmailID = getState().user.user.EmailID;
    log.Action = constants.AUDIT_TRAIL_NEW;
    return database
      .collection("REExciseLog")
      .add({
        ...log,
      })
      .then(function(docRef) {
        return docRef.id;
      })
      .catch(function(error) {
        console.error("Error adding REExciseLog: ", error);
      });
  };
}

export function getRELogs(logType, registrationID, storeID) {
  let searchParameters = {
    from: 0,
    size: 20,
    track_total_hits: true,
    query: {
      bool: {
        must: [
          { match: { "RegistrationID.keyword": registrationID } },
          { match: { "StoreID.keyword": storeID } },
          { match: { "Type.keyword": logType } },
        ],
      },
    },
    sort: [
      {
        ActionOn: {
          order: "desc",
        },
      },
    ],
  };

  return (dispatch) => {
    console.log("messageText", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("scrollData");

    return addMessage({
      collection: "reexciselog",
      scrollID: "",
      text: searchParameters,
    }).then(function(result) {
      const logs = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        logs.push({ key: data._id, ...data._source });
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: logs,
      };
    });
  };
}

export function getDamageDataForExcise(
  transactionTypeID,
  registrationID,
  storeID,
  searchTransactionToDate,
  searchTransactionFromDate
) {
  let searchParameters = {
    size: 0,
    query: {
      bool: {
        must: [
          {
            match: {
              RegistrationID: registrationID,
            },
          },
          {
            match: {
              StoreID: storeID,
            },
          },
          {
            match: {
              TransactionTypeID: transactionTypeID,
            },
          },
          {
            range: {
              TransactionDate: {
                lte: searchTransactionToDate,
                gte: searchTransactionFromDate,
                format: "yyyy-MM-dd",
              },
            },
          },
        ],
        must_not: [{ exists: { field: "CancellationReason" } }],
      },
    },
    aggs: {
      my_buckets: {
        composite: {
          size: 10000,
          sources: [
            {
              product: {
                terms: {
                  field: "ProductName.keyword",
                  order: "asc",
                },
              },
            },
            {
              productID: {
                terms: {
                  field: "ProductID.keyword",
                },
              },
            },
            {
              sku: {
                terms: {
                  field: "SKU.keyword",
                },
              },
            },
          ],
        },
        aggs: {
          total_qty: {
            sum: {
              field: "Qty",
            },
          },
          total_damage: {
            sum: {
              field: "Amount",
            },
          },
        },
      },
    },
  };
  return (dispatch) => {
    // console.log("messageText", JSON.stringify(searchParameters));

    let addMessage = firebase.functions().httpsCallable("getDenomInventory");

    return addMessage({ text: searchParameters }).then(function(result) {
      const damages = [];

      const itemDamages = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          sku: data.key.sku,
          product: data.key.product,
          productID: data.key.productID,
          total_quantity: Number(data.total_qty.value).toFixed(2),
          total_damage: Number(data.total_damage.value).toFixed(2),
        };
        itemDamages.push(item);
      });
      return {
        totalItemsCount: sanitizedMessage.hits.total.value,
        searchResult: itemDamages,
      };
    });
  };
}
