import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import axios from "axios";

class PincodeAdminDashboard extends Component {
  constructor() {
    super();
    this.state = {
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      loading: false,
      result: [],
    };
    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
  }

  componentDidMount() {
    //load data
    this.loadData(
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate
    );
  }

  async loadData(searchTransactionFromDate, searchTransactionToDate) {
    this.setState({ loading: true });
    const fromDateStr = searchTransactionFromDate + "T00:00:00";
    // Create a Date object
    const fromDate = new Date(fromDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisFrom = fromDate.getTime();

    // Define the date-time string
    const toDateStr = searchTransactionToDate + "T23:59:59";
    // Create a Date object
    const toDate = new Date(toDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisTo = toDate.getTime();

    let result = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/products/dashboard",
      {},
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    let tmp = [];
    if (
      result &&
      result.data &&
      result.data.result &&
      result.data.result.length > 0
    ) {
      tmp = result.data.result;
    }
    // console.log("tmp", tmp);
    let result1 = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/storesummary/dashboard",
      {
        fromDate: epochMillisFrom,
        toDate: epochMillisTo,
      },
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    let tmp1 = [];
    if (
      result1 &&
      result1.data &&
      result1.data.result &&
      result1.data.result.length > 0
    ) {
      tmp1 = result1.data.result;
    }

    tmp1.map((m) => {
      if (tmp.filter((f) => f.StoreID === m.storeId).length > 0) {
        let t = tmp.filter((f) => f.StoreID === m.storeId)[0];
        m.TotalInventoryCount = t.TotalInventoryCount;
        m.TotalGcCount = t.TotalGcCount;
        m.IssueReportedCount = t.IssueReportedCount;
        m.ActivePsinCount = t.ActivePsinCount;
        m.InstockGreaterThanZeroCount = t.InstockGreaterThanZeroCount;
      }
    });
    // console.log("tmp1", tmp1);
    this.setState({ result: tmp1, loading: false });
  }

  onClearSearch() {
    this.setState({
      searchStartDate: new Date().toISOString().split("T")[0],
      searchEndDate: new Date().toISOString().split("T")[0],
    });
    this.loadData(
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0]
    );
  }

  async onExportClick() {
    let result = this.state.result;
    const csvData = constatnts.objToCSV(result);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    let fileName = "PincodeDashboard.csv";
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  onSearchClick() {
    this.setState({ loading: true });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Pincode Dashboard
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>From</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionFromDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionFromDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group">
                        <label>To</label>
                        <input
                          type="date"
                          className="form-control"
                          onChange={(evt) =>
                            this.setState({
                              searchTransactionToDate: evt.target.value,
                            })
                          }
                          value={this.state.searchTransactionToDate}
                          onKeyPress={this.handleKeyPress}
                        />
                      </div>
                    </div>
                    <div className="col-md-5">
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                        onClick={this.onSearchClick}
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export{" "}
                        <i className="glyphicon glyphicon-download-alt "></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Store</th>
                            <th>Online Order Count</th>
                            <th>Offline Sales Transaction Count</th>
                            <th>Vendor Count</th>
                            <th>PE Count</th>
                            <th>PR Count</th>
                            <th>Stouck Update Count</th>
                            <th>Damage Count</th>
                            <th>Expense Count</th>
                            <th>Total Items with Inventory Activity</th>
                            <th>Found in GC</th>
                            <th>Reported Items</th>
                            <th>Active PSINs</th>
                            <th>In stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.result.map((m) => (
                            <tr>
                              <td>{m.storeName}</td>
                              <td className="align-right">
                                {m.onlineOrderCount}
                              </td>
                              <td className="align-right">
                                {m.oflineOrderCount}
                              </td>
                              <td className="align-right">{m.vendorCount}</td>
                              <td className="align-right">
                                {m.purchaseEntryCount}
                              </td>
                              <td className="align-right">
                                {m.purchaseReturnCount}
                              </td>
                              <td className="align-right">
                                {m.stockUpdateCount}
                              </td>
                              <td className="align-right">{m.damageCount}</td>
                              <td className="align-right">{m.expenseCount}</td>
                              <td className="align-right">
                                {m.TotalInventoryCount}
                              </td>
                              <td className="align-right">{m.TotalGcCount}</td>
                              <td className="align-right">
                                {m.IssueReportedCount}
                              </td>
                              <td className="align-right">
                                {m.ActivePsinCount}
                              </td>
                              <td className="align-right">
                                {m.InstockGreaterThanZeroCount}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(PincodeAdminDashboard);
