import React, { Component } from "react";
import Form from "../2/Controls/Form";
import Input from "../2/Controls/Input";
import PartySearch from "./PartySearch";

class Expense extends Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      hideTax: true,
      key: "",
      expenseNo: "",
      partyType: "vendor",
      // isPartyTypeAheadLoading: false,
      // partyTypeAheadSelected: [],
      // party: null,
      selectedParty: null,
      expenseCategory: "",
      expenseCategoryID: "",
      expenseCategorieslist: [],
      expenseDate: new Date().toISOString().split("T")[0],
      refNo: "",
      note: "",
      amount: null,
      hsn: "",
      taxInclusive: true,
      taxID: "",
      taxGroup: "",
      taxAmount: null,
      netAmount: null,
      paymentTypeID: "",
      paymentType: "",
      cashRegister: "",
      cashRegisterID: "",
      paymentRemark: "",
      status: "Pending",
      isShowingModalAddExpenseCategory: false,
      isShowingModalAddPaymentMode: false,
      expense: {},
      cashRegisterList: [],
      partyError: "",
      expenseCategoryError: "",
      expenseDateError: "",
      amountError: "",
      cashRegisterError: "",
      showTransactionWiseAdjustment: false,
      pendingTransactions: [],
    };
    this.onPartySelected = this.onPartySelected.bind(this);
    this.loadExpenseCategories = this.loadExpenseCategories.bind(this);
    this.onSaveExpense = this.onSaveExpense.bind(this);
    this.onPaymentSelectionChange = this.onPaymentSelectionChange.bind(this);
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onExpenseCateogrySelection = this.onExpenseCateogrySelection.bind(
      this
    );
    this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this);
  }

  componentDidMount() {
    this.setState({
      showTransactionWiseAdjustment: this.props.registration.registration
        .EnableExpenseTransactionWiseAdjustment,
    });
    this.loadExpenseCategories();
    this.props
      .getCashRegisters(this.props.user.store.key)
      .then((cashRegisters) => {
        this.setState({ cashRegisterList: cashRegisters });
      });
    // console.log("this.props.expense", this.props.expense);
    if (this.props.expense) {
      this.setState({
        key: this.props.expense.key,
        expenseNo: this.props.expense.ExpenseNo,
        selectedParty: this.props.expense.Party,
        expenseCategory: this.props.expense.ExpenseCategory,
        expenseCategoryID: this.props.expense.ExpenseCategoryID,
        expenseDate: this.props.expense.ExpenseDate,
        refNo: this.props.expense.RefNo,
        note: this.props.expense.Note,
        amount: this.props.expense.Amount,
        hsn: this.props.expense.Hsn,
        taxInclusive: this.props.expense.TaxInclusive,
        taxGroup: this.props.expense.TaxGroup,
        taxAmount: this.props.expense.TaxAmount,
        taxID: this.props.expense.TaxID,
        netAmount: this.props.expense.NetAmount,
        paymentTypeID: this.props.expense.PaymentTypeID,
        paymentType: this.props.expense.PaymentType,
        cashRegister: this.props.expense.CashRegister,
        cashRegisterID: this.props.expense.CashRegisterID,
        paymentRemark: this.props.expense.PaymentRemark,
        status: this.props.expense.Status,
        pendingTransactions: this.props.expense.Transactions
          ? this.props.expense.Transactions
          : [],
      });
    }
  }

  loadExpenseCategories() {
    this.props
      .getExpenseCategories(
        this.props.user.user.RegistrationID,
        this.props.user.store.key
      )
      .then((expenseCategories) => {
        this.setState({ expenseCategorieslist: expenseCategories });
      });
  }

  async onSaveExpense() {
    let partyError = "";
    let expenseCategoryError = "";
    let expenseDateError = "";
    let refNoError = "";
    let amountError = "";
    let cashRegisterError = "";

    if (!this.state.selectedParty) {
      partyError = "Please select party";
    }
    if (this.state.expenseCategoryID.trim() === "") {
      expenseCategoryError = "Please select expense category";
    }
    if (this.state.expenseDate.trim() === "") {
      expenseDateError = "Please select date";
    }
    if (this.state.amount <= 0) {
      amountError = "Please enter amount";
    }

    if (
      this.state.paymentType != "" &&
      this.state.paymentType.toLowerCase() === "cash from counter"
    ) {
      if (this.state.cashRegister.trim() === "") {
        cashRegisterError = "Please select cash register";
      }
    }
    if (
      this.state.expenseDate.trim() !== "" &&
      this.state.paymentType != "" &&
      this.state.paymentType.toLowerCase() === "cash from counter" &&
      this.props.user.store.CashRegisterClosing &&
      this.state.expenseDate !== new Date().toISOString().split("T")[0]
    ) {
      expenseDateError =
        "In case of payment mode 'Cash from Counter', expense Date cannot be more than current date";
    }

    if (
      partyError !== "" ||
      expenseCategoryError !== "" ||
      expenseDateError !== "" ||
      refNoError !== "" ||
      amountError !== "" ||
      cashRegisterError !== ""
    ) {
      this.setState({
        partyError: partyError,
        expenseCategoryError: expenseCategoryError,
        expenseDateError: expenseDateError,
        amountError: amountError,
        cashRegisterError: cashRegisterError,
      });
      return;
    }
    this.setState({ saving: true });
    let runningNo;
    let expenseNo = "";
    let expenseDate = new Date();
    if (this.state.paymentType.toLowerCase() !== "cash from counter") {
      expenseDate = new Date(this.state.expenseDate);
      expenseDate = new Date(expenseDate.setTime(new Date().getTime()));
    }
    if (this.state.key === "") {
      runningNo = await this.props.getExpenseRunningNo(
        this.props.user.store.key
      );
      expenseNo = "EX/" + this.props.user.store.StoreCode + "/" + runningNo;
    } else {
      expenseNo = this.state.expenseNo;
    }
    let transactions = [];
    if (this.state.pendingTransactions.filter((f) => f.Selected).length > 0) {
      transactions = this.state.pendingTransactions.filter((f) => f.Selected);
      console.log("Transactions", transactions);
    }
    let Expense = {
      ExpenseNo: expenseNo,
      Party: this.state.selectedParty,
      ExpenseCategory: this.state.expenseCategory,
      ExpenseCategoryID: this.state.expenseCategoryID,
      ExpenseDate: this.state.expenseDate,
      ExpenseDateTime: expenseDate,
      RefNo: this.state.refNo,
      Note: this.state.note,
      Amount: this.state.amount,
      Hsn: this.state.hsn,
      TaxInclusive: this.state.taxInclusive,
      TaxID: this.state.taxID,
      TaxGroup: this.state.taxGroup,
      TaxAmount: this.state.taxAmount,
      NetAmount: this.state.netAmount,
      PaymentTypeID: this.state.paymentTypeID,
      PaymentType: this.state.paymentType,
      PaymentRemark: this.state.paymentRemark,
      CashRegister: this.state.cashRegister,
      CashRegisterID: this.state.cashRegisterID,
      Status: this.state.status,
      StoreID: this.props.user.store.key,
      RegistrationID: this.props.user.user.RegistrationID,
      Transactions: transactions,
    };
    // console.log('expense', Expense)
    this.props.saveExpenses(Expense, this.state.key).then((key) => {
      this.props.getToastr("Expense Saved Successfully");
      setTimeout(
        function() {
          this.setState({ saving: false });
          this.props.onSave();
        }.bind(this),
        5000
      );
    });
  }

  onPaymentSelectionChange(paymentTypeID) {
    let paymentType = "";
    let status = this.state.status;
    if (
      this.props.config.PaymentTypes.filter((e) => e.key === paymentTypeID)
        .length > 0
    ) {
      paymentType = this.props.config.PaymentTypes.filter(
        (e) => e.key === paymentTypeID
      )[0].PaymentType;
      if (paymentType !== "") {
        status = "Paid";
      }
    }

    this.setState({
      status: status,
      paymentTypeID: paymentTypeID,
      paymentType: paymentType,
      cashRegisterError: "",
      cashRegisterID: "",
      cashRegister: "",
    });
  }

  async onPartySelected(party) {
    let selectedParty = null;
    let pendingTransactions = [];
    // console.log("party", party);
    if (
      this.state.showTransactionWiseAdjustment &&
      this.state.key !== "" &&
      this.state.pendingTransactions.length > 0
    ) {
      alert("You cannot change party as transations have been selcted.");
      return;
    }
    console.log("party", party);
    if (party) {
      selectedParty = {
        key: party.key,
        PartyCode: party.PartyCode,
        PartyFirstName: party.PartyFirstName,
        PartyLastName: party.PartyLastName,
        PartyType: party.PartyType,
        EmailID: party.EmailID,
        PhoneNo: party.PhoneNo,
        DueAmount: party.hasOwnProperty("DueAmount") ? party.DueAmount : 0,
      };
      let name = (
        selectedParty.PartyFirstName.trim() +
        " " +
        selectedParty.PartyLastName.trim()
      ).trim();
      selectedParty.PartyName = name;
      if (
        party.PartyType.toLowerCase() === "vendor" &&
        this.state.showTransactionWiseAdjustment
      ) {
        //get pending transaction for vendor
        let partyID = party.key.replace("vendor_", "");
        pendingTransactions = await this.props.getVendorPendingPayments(
          partyID,
          this.props.user.store.key
        );
      }
    }
    this.setState({
      selectedParty: selectedParty,
      partyType: selectedParty ? selectedParty.PartyType : "",
      partyError: "",
      pendingTransactions: pendingTransactions,
    });
  }

  onExpenseCateogrySelection(expenseCategoryID) {
    let expenseCategory = "";
    if (
      this.state.expenseCategorieslist.filter(
        (e) => !e.IsDeleted && e.key === expenseCategoryID
      ).length > 0
    ) {
      expenseCategory = this.state.expenseCategorieslist.filter(
        (e) => !e.IsDeleted && e.key === expenseCategoryID
      )[0].ExpenseCategory;
    }
    this.setState({
      expenseCategoryID: expenseCategoryID,
      expenseCategory: expenseCategory,
      expenseCategoryError: "",
    });
  }

  onCashRegisterSelection(cashRegisterID) {
    let cashRegister = "";
    if (
      this.state.cashRegisterList.filter((f) => f.key === cashRegisterID)
        .length > 0
    ) {
      cashRegister = this.state.cashRegisterList.filter(
        (f) => f.key === cashRegisterID
      )[0].CashRegisterName;
    }
    this.setState({
      cashRegisterID: cashRegisterID,
      cashRegister: cashRegister,
      cashRegisterError: "",
    });
  }

  onButtonClick(action) {
    if (action === "save") {
      this.onSaveExpense();
    } else {
      this.props.onClose();
    }
  }

  render() {
    return (
      <React.Fragment>
        <Form
          title={this.state.key !== "" ? "Edit Expense" : "New Expense"}
          onClick={(action) => this.onButtonClick(action)}
          processing={this.state.saving}
        >
          <PartySearch
            {...this.props}
            hideOutStanding={true}
            party={this.state.selectedParty}
            error={this.state.partyError}
            onPartyChange={(party) => this.onPartySelected(party)}
          />
          {this.state.partyError.trim() !== "" && (
            <span className="help-block">{this.state.partyError}</span>
          )}
          <div className="row">
            <div className="col-md-6">
              <Input
                type="select"
                label="Expense Category"
                value={this.state.expenseCategoryID}
                onChange={(value) => this.onExpenseCateogrySelection(value)}
                error={this.state.expenseCategoryError}
              >
                <option value="">Select Expense Category</option>
                {this.state.expenseCategorieslist
                  .filter((e) => !e.IsDeleted)
                  .map((expenseCategory) => (
                    <option value={expenseCategory.key}>
                      {expenseCategory.ExpenseCategory}
                    </option>
                  ))}
              </Input>
            </div>
            <div className="col-md-6">
              <Input
                type="date"
                label="Expense Date"
                onChange={(value) =>
                  this.setState({ expenseDate: value, expenseDateError: "" })
                }
                value={this.state.expenseDate}
                error={this.state.expenseDateError}
              />
            </div>
          </div>
          <Input
            type="text"
            label="Reference No"
            onChange={(value) => this.setState({ refNo: value })}
            value={this.state.refNo}
          />
          {this.state.showTransactionWiseAdjustment &&
            this.state.partyType.toLowerCase() === "vendor" && (
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    {this.state.key === "" && <th></th>}
                    <th>Transaction</th>
                    <th>Amount</th>
                    <th>Paid</th>
                    <th>Balance To Pay</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.pendingTransactions.map((m, index) => (
                    <tr>
                      {this.state.key === "" && (
                        <td>
                          <Input
                            type="checkbox"
                            onChange={(value) => {
                              let pendingTransactions = this.state
                                .pendingTransactions;
                              pendingTransactions[index].Selected = value;
                              let totalAmount = 0;
                              pendingTransactions.map((l) => {
                                if (l.Selected) {
                                  totalAmount =
                                    Number(totalAmount) +
                                    Number(l.BalanceAmountToBePaid);
                                }
                              });
                              this.setState({
                                pendingTransactions: pendingTransactions,
                                amount: totalAmount,
                              });
                            }}
                            value={m.Selected}
                          />
                        </td>
                      )}
                      <td>
                        {m.TransactionNo}
                        {m.RefNo && <div>{m.RefNo}</div>}
                        {m.Notes && <div>{m.Notes}</div>}
                        {m.TransactionDate && <div>{m.TransactionDate}</div>}
                      </td>
                      <td className="number">{m.BillAmount}</td>
                      <td className="number">{m.PaidAmount}</td>
                      <td>
                        {this.state.key !== "" && (
                          <div className="number">
                            {m.BalanceAmountToBePaid}
                          </div>
                        )}
                        {this.state.key === "" && (
                          <Input
                            type="number"
                            value={m.BalanceAmountToBePaid}
                            disabled={!m.Selected}
                            onChange={(value) => {
                              let pendingTransactions = this.state
                                .pendingTransactions;

                              let negativevalue = false;
                              if (
                                pendingTransactions[index].BillAmount -
                                  pendingTransactions[index].PaidAmount <
                                0
                              ) {
                                negativevalue = true;
                              }
                              if (
                                !negativevalue &&
                                value >
                                  pendingTransactions[index].BillAmount -
                                    pendingTransactions[index].PaidAmount
                              ) {
                                return;
                              }
                              if (
                                negativevalue &&
                                value <
                                  pendingTransactions[index].BillAmount -
                                    pendingTransactions[index].PaidAmount
                              ) {
                                return;
                              }
                              if (negativevalue && value > 0) {
                                return;
                              }
                              pendingTransactions[
                                index
                              ].BalanceAmountToBePaid = value;
                              let totalAmount = 0;
                              pendingTransactions.map((l) => {
                                if (l.Selected) {
                                  totalAmount =
                                    Number(totalAmount) +
                                    Number(l.BalanceAmountToBePaid);
                                }
                              });
                              this.setState({
                                pendingTransactions: pendingTransactions,
                                amount: totalAmount,
                              });
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          <div className="row">
            <div className="col-md-6">
              <Input
                type="number"
                label="Amount"
                disabled={
                  this.state.showTransactionWiseAdjustment &&
                  this.state.partyType.toLowerCase() === "vendor"
                }
                onChange={(value) => this.setState({ amount: value })}
                value={this.state.amount}
                error={this.state.amountError}
              />
            </div>
            <div className="col-md-6">
              <Input
                type="select"
                label="Payment Type"
                onChange={(value) => this.onPaymentSelectionChange(value)}
                value={this.state.paymentTypeID}
              >
                <option value="">Select Payment Type</option>
                {this.props.config.PaymentTypes.filter(
                  (e) => e.ShowInExpense && !e.IsDeleted
                ).map((e) => (
                  <option value={e.key}>{e.PaymentType}</option>
                ))}
              </Input>
            </div>
          </div>

          {this.state.paymentType.toLowerCase() === "cash from counter" && (
            <Input
              type="select"
              label="Cash Register"
              value={this.state.cashRegisterID}
              onChange={(value) => this.onCashRegisterSelection(value)}
              error={this.state.cashRegisterError}
            >
              <option value="">Select Cash Register</option>
              {this.state.cashRegisterList.map((cashRegister) => (
                <option value={cashRegister.key}>
                  {cashRegister.CashRegisterName}
                </option>
              ))}
            </Input>
          )}
          {this.state.paymentTypeID.trim() !== "" && (
            <Input
              type="text"
              label="Payment Remarks"
              onChange={(value) => this.setState({ paymentRemark: value })}
              value={this.state.paymentRemark}
            />
          )}
          <Input
            type="textarea"
            label="Notes"
            onChange={(value) => this.setState({ note: value })}
            value={this.state.note}
          />
        </Form>
      </React.Fragment>
    );
  }
}
export default Expense;
