import React, { Component } from "react";
import * as actionTypes from "../../actionTypes";
import ListSearch from "../Common/ListSearch";
import Input from "../2/Controls/Input";
import Window from "../2/Controls/Window";

class SalesIntermediate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Cash Register Selection",
      searchText: "",
      cashRegisters: [],
      cashRegisterOpeningBalance: 0,
      saving: false,
      showCashRegisterOpening: false,
      showCashRegisterSelection: false,
      loading: false,
      cashRegisterClosing: null,
    };
    this.redirectToTransactionPage = this.redirectToTransactionPage.bind(this);
    this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this);
    this.onEnteringCashRegisterOpeningBalance = this.onEnteringCashRegisterOpeningBalance.bind(
      this
    );
    this.renderCashRegisterSelection = this.renderCashRegisterSelection.bind(
      this
    );
    this.renderCashRegisterOpening = this.renderCashRegisterOpening.bind(this);
    this.onHeaderBackClick = this.onHeaderBackClick.bind(this);
    this.renderMobileCashRegisterSelection = this.renderMobileCashRegisterSelection.bind(
      this
    );
  }

  componentDidMount() {
    if (this.props.user.cashRegister === null) {
      //show list of cash registers  
      this.props
        .getCashRegisters(this.props.user.store.key)
        .then((cashRegisters) => {
          let c = cashRegisters.filter((e) => !e.IsDeleted);
          if (c.length == 0) {
            alert("No cash register exists. Pls define cash register first");
            this.props.history.push("/home/");
          } else if (c.length === 1) {
            //auto select cash register
            this.onCashRegisterSelection(c[0]);
            // this.props.setCashRegister(c[0])
          } else {
            //give user option to select cash register
            this.setState({
              cashRegisters: c,
              showCashRegisterSelection: true,
            });
          }
        });
    } else {
      this.redirectToTransactionPage();
    }
  }

  async onCashRegisterSelection(selectedCashRegister) {
    //check if cash regiter closing enabled
    let cashRegisterClosingEnbaled = false;
    if (this.props.user.store.hasOwnProperty("CashRegisterClosing")) {
      cashRegisterClosingEnbaled = this.props.user.store.CashRegisterClosing;
    }
    if (cashRegisterClosingEnbaled) {
      //check if cash register is already opened, if not then ask for opening balance 
      let cashRegisterClosing = await this.props.getCashRegisterOpeningDetails(
        selectedCashRegister.key
      );
      if (cashRegisterClosing) {
        //open cash register found
        this.props.setCashRegisterClosing(cashRegisterClosing);
        this.props.setCashRegister(selectedCashRegister);
        this.setState({
          showCashRegisterOpening: false,
          showCashRegisterSelection: false,
          cashRegisterClosing: cashRegisterClosing,
        });
        this.redirectToTransactionPage();
      } else {
        //show option to enter opening balance
        this.setState({
          selectedCashRegister: selectedCashRegister,
          showCashRegisterOpening: true,
          showCashRegisterSelection: false,
          cashRegisterClosing: null,
        });
        if (this.props.registration.registration.AutoPopulateOpeningBalance) {
          let lastClosingBalance = await this.props.getLastClosingBalance(
            selectedCashRegister.key
          );
          this.setState({ cashRegisterOpeningBalance: lastClosingBalance });
        }
      }
    } else {
      this.props.setCashRegister(selectedCashRegister);
      this.setState({
        showCashRegisterOpening: false,
        showCashRegisterSelection: false,
      });
      this.redirectToTransactionPage();
    }
  }

  async onEnteringCashRegisterOpeningBalance(openingBalance) {
    this.setState({ processing: true });
    let cashRegisterClosing = {
      CashRegisterID: this.state.selectedCashRegister.key,
      CashRegisterCode: this.state.selectedCashRegister.CashRegisterCode,
      CashRegisterName: this.state.selectedCashRegister.CashRegisterName,
      StoreID: this.props.user.store.key,
      StoreCode: this.props.user.store.StoreCode,
      StoreName: this.props.user.store.StoreName,
      RegistrationID: this.props.user.user.RegistrationID,
      OpeningBalance: openingBalance != 0 ? openingBalance : 0,
      OpeningTime: new Date(),
      OpeningByUserID: this.props.user.user.UID,
      OpeningByUserName: this.props.user.user.Name,
      Status: "Open",
    };
    let res = await this.props.openCashRegister(cashRegisterClosing);
    let cashRegisterClosingobj = await this.props.getCashRegisterOpeningDetails(
      res.CashRegisterID
    );
    if (!cashRegisterClosingobj) {
      alert(
        "System is experiencing problem opening cash register. Pls try again or contact support"
      );
      return;
    }
    this.props.setCashRegister(this.state.selectedCashRegister);
    this.props.setCashRegisterClosing(cashRegisterClosingobj);
    this.setState({
      showCashRegisterOpening: false,
      showCashRegisterSelection: false,
      cashRegisterClosing: cashRegisterClosingobj,
    });
    this.redirectToTransactionPage();
  }

  async redirectToTransactionPage() {
    if (
      this.props.location.state.transactionType &&
      this.props.location.state.transactionType.TransactionType.toLowerCase() ===
        "receive payment"
    ) {
      this.props.history.push({
        pathname: "/home/sales/receivepayment/",
        state: {
          transactionType: this.props.location.state.transactionType,
          type: this.props.location.state.type,
        },
      });
    } else {
      if (
        this.props.user.store.EnableCustomerDisplay &&
        !this.props.isMobileApp()
      ) {
        window.openCutomerDisplay();
      }

      if (this.props.location.state.type === actionTypes.SALE_DRAFT) {
        //draft invoice
        this.props.history.push({
          pathname: "/home/sales/newsales/",
          state: {
            type: this.props.location.state.type,
            key: this.props.location.state.key,
            draft: this.props.location.state.draft,
            transactionType: this.props.location.state.transactionType,
          },
        });
      } else if (this.props.location.state.type === actionTypes.ONLINE_ORDER) {
        this.props.history.push({
          pathname: "/home/sales/onlineorders/",
          state: { orderID: this.props.location.state.orderID },
        });
      } else if (this.props.location.state.type === actionTypes.SALE_EDIT) {
        this.props.history.push({
          pathname: "/home/sales/editsales/",
          state: {
            type: this.props.location.state.type,
            key: this.props.location.state.key,
            transactionType: this.props.location.state.transactionType,
            module: this.props.location.state.hasOwnProperty("module")
              ? this.props.location.state.module
              : "",
            table: this.props.location.state.hasOwnProperty("table")
              ? this.props.location.state.table
              : null,
            KOTType: this.props.location.state.hasOwnProperty("KOTType")
              ? this.props.location.state.KOTType
              : "",
            sale: this.props.location.state.sale,
          },
        });
      } else {
        this.props.history.push({
          pathname: "/home/sales/newsales/",
          state: {
            transactionType: this.props.location.state.transactionType,
            type: this.props.location.state.type,
            module: this.props.location.state.hasOwnProperty("module")
              ? this.props.location.state.module
              : "",
            table: this.props.location.state.hasOwnProperty("table")
              ? this.props.location.state.table
              : null,
            KOTType: this.props.location.state.hasOwnProperty("KOTType")
              ? this.props.location.state.KOTType
              : "",
            sale: this.props.location.state.hasOwnProperty("sale")
              ? this.props.location.state.sale
              : null,
            key: this.props.location.state.hasOwnProperty("key")
              ? this.props.location.state.key
              : "",
          },
        });

        // state: {type:actionTypes.SALE_NEW,
        //     key: this.state.key,
        //     transactionType: this.state.salesReturnTransactionType,
        //     sale: this.state.sale,}
      }
    }
  }

  onHeaderBackClick() {
    this.props.history.push("/home/sales/saleshistory");
  }

  renderCashRegisterSelection() {
    return (
      <React.Fragment>
        <Window
          header={"Cash register selection"}
          modal={false}
          onClose={() => this.props.history.push({ pathname: "/home" })}
          processing={this.state.processing}
          buttons={[]}
        >
          <Input
            type="search"
            placeholder="Search cash register"
            value={this.state.searchText}
            onChange={(value) => this.setState({ searchText: value })}
          />
          <div className="store-selection-list">
            {this.state.cashRegisters
              .filter((f) => {
                let searchText = this.state.searchText;
                if (searchText === 0) {
                  return true;
                } else {
                  if (
                    f.CashRegisterCode.toLowerCase().includes(
                      searchText.toLowerCase()
                    ) ||
                    f.CashRegisterName.toLowerCase().includes(
                      searchText.toLowerCase()
                    )
                  ) {
                    return true;
                  }
                }
                return false;
              })
              .map((cr, index) => (
                <React.Fragment>
                  <div
                    key={"cr" + index}
                    className="list-row"
                    onClick={() => {
                      this.setState({ title: "" });
                      this.onCashRegisterSelection(cr);
                    }}
                  >
                    <div>
                      <div className="row-title">
                        {cr.CashRegisterCode + " - " + cr.CashRegisterName}
                      </div>
                    </div>
                  </div>
                  <div key={"sep" + index} className="seperator"></div>
                </React.Fragment>
              ))}
          </div>
        </Window>
      </React.Fragment>
    );
  }

  //     renderCashRegisterSelection()
  //     {
  //         return <div className="content-wrapper">
  //         <section className="content">
  //         <div className="login-box">
  //             <div className="form-group">
  //                             <label >Please select cash register</label>
  //                             <Typeahead
  //                             autoFocus={true}
  //                             allowNew={false}
  //                             multiple={false}
  //                             options={this.state.cashRegisters}
  //                             labelKey="CashRegisterName"
  //                             placeholder="Select cash register"
  //                             filterBy={['CashRegisterCode', 'CashRegisterName']}
  //                             // bsSize={'large'}
  //                             dropup={false}
  //                             highlightOnlyResult={true}
  //                             onChange={(selected) => {
  //                             this.setState({ selectedCashRegister: selected[0] })
  //                             }}
  //                             renderMenuItemChildren={(option, props) => (
  //                                 <div>
  //                                     <div>{option.CashRegisterCode} - {option.CashRegisterName}</div>
  //                                 </div>
  //                             )}
  //                         />
  //                         </div>
  //                         <button type="submit"
  //                     className="btn btn-md btn-success btn-flat pull-right"
  //                     onClick={()=> this.onCashRegisterSelection(this.state.selectedCashRegister)}>OK
  //                 </button>
  //         </div>
  //     </section>
  //    </div>
  //    }

  renderCashRegisterOpening() {
    return (
      <React.Fragment>
        <Window
          header={"Opening balance"}
          height={"200px"}
          modal={true}
          processing={this.state.processing}
          onClose={() => this.props.history.push({ pathname: "/home" })}
          onButtonClick={(buttonType) => {
            if (buttonType.toLowerCase() === "ok") {
              this.setState({ processing: true });
              this.onEnteringCashRegisterOpeningBalance(
                this.state.cashRegisterOpeningBalance
              );
            } else {
              this.props.history.push({ pathname: "/home" });
            }
          }}
          buttons={[
            { Type: "secondary", Text: "Cancel", Align: "left" },
            { Type: "primary", Text: "OK", Align: "right" },
          ]}
        >
          <Input
            type="number"
            placeholder="Please provide opening balance"
            disabled={
              this.props.registration.registration.AutoPopulateOpeningBalance
            }
            value={this.state.cashRegisterOpeningBalance}
            onChange={(value) =>
              this.setState({ cashRegisterOpeningBalance: value })
            }
          />
        </Window>
      </React.Fragment>
    );
  }

  //    renderCashRegisterOpening()
  //    {
  //     return <React.Fragment>
  //     <section className="content">
  //         <div className="login-box">
  //             <div className="form-group">
  //                             <label >Please enter opening balance</label>
  //                             <input type="number" className="form-control"
  //                             onChange={(evt) => this.setState({ cashRegisterOpeningBalance: evt.target.value })}
  //                             value={this.state.cashRegisterOpeningBalance}
  //                             disabled={this.props.registration.registration.AutoPopulateOpeningBalance}
  //                              />
  //                 </div>
  //                 <button type="submit"
  //                     className="btn btn-md btn-success btn-flat pull-right"
  //                     disabled={this.state.saving}
  //                     onClick={()=> this.onEnteringCashRegisterOpeningBalance(this.state.cashRegisterOpeningBalance)}>OK
  //                 </button>
  //         </div>

  //         </section>
  //    </React.Fragment>
  //    }

  renderMobileCashRegisterSelection() {
    // this.setState({title:'Store Selection'})
    return (
      <React.Fragment>
        {/* <div className="header-new-background"/> */}
        <ListSearch
          value={this.state.searchText}
          onChange={(searchText) => {
            this.setState({ searchText: searchText });
          }}
          searchPlaceHolder="Search cash register"
        />
        <div className="list">
          {this.state.cashRegisters
            .filter((f) => {
              let searchText = this.state.searchText;
              if (searchText === 0) {
                return true;
              } else {
                if (
                  f.CashRegisterName.toLowerCase().includes(
                    searchText.toLowerCase()
                  ) ||
                  f.CashRegisterCode.toLowerCase().includes(
                    searchText.toLowerCase()
                  )
                ) {
                  return true;
                }
              }
              return false;
            })
            .map((cr) => (
              <div
                className="list-row seperator"
                onClick={() => {
                  this.setState({ title: "" });
                  this.onCashRegisterSelection(cr);
                }}
              >
                {/* <Avatar text={store.StoreCode}/> */}
                <div>
                  <div className="row-title">
                    {cr.CashRegisterCode + " - " + cr.CashRegisterName}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {/* {!this.props.isMobileApp() && <div> */}

        {!this.props.user.cashRegister &&
          this.state.showCashRegisterSelection &&
          this.renderCashRegisterSelection()}

        {this.state.showCashRegisterOpening && this.renderCashRegisterOpening()}

        {/* </div>} */}

        {/* {this.props.isMobileApp()  && <div>
            
                <HeaderNew {...this.props}
                back={true}
                title={this.state.title}
                filter={false}
                onBackClick={() => this.onHeaderBackClick()}
                />
            
                <div className="header-new-background"/>
                <div className="content-wrapper">
                    <div className="container">
                      
                        {(!this.props.user.cashRegister && this.state.showCashRegisterSelection )&& this.renderMobileCashRegisterSelection()}
                      
                        {this.state.showCashRegisterOpening && this.renderCashRegisterOpening()}
                    </div>              
                </div>
            </div>} */}
      </React.Fragment>
    );
  }
}

export default SalesIntermediate;
