import React, { Component } from "react";
import CSVReader from "react-csv-reader";
// import ReactExport from "react-data-export";
import { isNullOrUndefined } from "util";
import Loader from "./Loader";
import * as constatnts from "../Constatnts";
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
import { checkDateFormat } from "../CommonFunctions";
import Window from "./2/Controls/Window";

class ImportMaster extends Component {
  constructor() {
    super();
    this.state = {
      multipleCouponCodes:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMultipleCouponCode.csv?alt=media&token=61cc3018-bdff-4588-afb4-ee07d44d9b6d",
      multipleCouponCodesColumns: ["CouponCode"],
      multipleClientCouponCodeTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FClientCouponCode.csv?alt=media&token=f3d1d5ed-b674-43c3-a8dd-520fb68279cc",
      multipleClientCouponCodeColumns: ["CouponCode", "MobileNo"],

      salesImportTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FSalesImport.csv?alt=media&token=113cdc2d-225f-4540-87f5-e48d2183c969",
      // membershipImportTemplate:'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMembershipImport.csv?alt=media&token=09f6d2e5-6fb5-4230-a95f-b0a01facce37',
      membershipImportTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMembershipImportV1.csv?alt=media&token=8bee3f31-c2df-439b-bb67-4dfc2edc3740",
      // membershipImportDiscountTemplate:'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMemberShipOnDiscountLevel.csv?alt=media&token=88dea171-7bd6-4b0d-8e0f-66ee3eb1190f',
      inventoryImportQtyTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportQty.csv?alt=media&token=be093a5f-c4ad-49b5-85e9-155e3ad58bb8",
      inventoryImportPriceTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportPrice.csv?alt=media&token=6f7d2ec6-9e97-4420-ab29-57d0a4c6e05a",
      serviceImportTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FServicesImport.csv?alt=media&token=458328ff-a3f3-4e0f-a7be-e027ddc39cf4",
      tagImportTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FTagsImport.csv?alt=media&token=5dc7998a-1603-4923-b94a-30671fe125ad",

      inventoryUniqueNoTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportUniqueNo.csv?alt=media&token=53ee9b2d-e0b1-465c-bff9-d15269c71314",

      inventoryBatchTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FBatchImportFormat.csv?alt=media&token=0d15b461-cab1-4608-895a-b89ba56ba006",

      salesLegacyImportTemplate: "",
      vendorProductsImportTemplate:
        "https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FVendorProductImport.csv?alt=media&token=2a650b14-915f-4e39-a6b8-c15ad6f72c09",
      vendorProductsColumns: [
        "StoreCode",
        "ProductSKU",
        "SupplyPrice",
        "Discount",
      ],
      memberships: [],
      showFileUpload: true,
      importType: "",
      importedData: [],
      membershipColumns: [
        "SKU",
        "Discount Perc",
        "Discount Amt",
        "Retail Price",
      ],
      // membershipColumnsDiscount :["SKU","Discount Perc","Discount Amt"],
      tagColumns: ["SKU"],
      inventoryWithoutPriceColumns: ["SKU", "Qty", "Notes"],
      inventoryUniqueNo: [
        "SKU",
        "UniqueNo1",
        "UniqueNo2",
        "UniqueNo3",
        "UniqueNo4",
      ],

      inventoryBatches: [
        "SKU",
        "BatchNo",
        "BatchMRP",
        "BatchRetailPrice",
        "BatchMfgDate",
        "BatchExpDate",
        "Qty",
        "Discount Amount",
        "HSNSACCode",
        "Tax Inclusive",
        "Tax Group",
        "Notes",
      ],

      inventoryWithPriceColumns: [
        "SKU",
        "Supply Price",
        "Qty",
        "Discount Amount",
        "HSNSACCode",
        "Tax Inclusive",
        "Tax Group",
        "Notes",
      ],
      salesColumns: [
        "Barcode",
        "Description of Goods",
        "Batch",
        "MRP",
        "Qty",
        "Size",
        "Basic Rate",
        "Taxable Value",
        "Tax Inclusive",
        "SGST Rate",
        "SGST Amount",
        "CGST Rate",
        "CGST Amount",
        "IGST Rate",
        "IGST Amount",
        "Total Amount",
      ],
      errorSales: [],
      errorClientCodes: [],
      errorMemberships: [],
      totalCounter: 0,
      errorCounter: 0,
      successCounter: 0,
      inventoryError: [],
      importTemplate: "",
      inventoryImportTypeSelected: "",
      salesLegacyImportColumns: [
        "INVOICE NAME",
        "INVOICE DATE",
        "CLIENT NAME",
        "CONTACT NO",
        "ADDRESS",
        "ADDRESS2",
        "STATE",
        "CITY",
        "PINCODE",
        "ITEM CODE",
        "ITEM NAME",
        "PRICE",
        "QTY",
        "SUB TOTAL",
        "DISCOUNT PERCENT",
        "DISCOUNT AMOUNT",
        "GRAND TOTAL",
      ],
      inventoryErrors: [],
      store: null,
      stores: [],
      cashRegisters: [],
      cashRegister: null,
      errors: [],
      transactionType: null,
      loading: false,
      importStatus: "",
    };
    this.onFileLoaded = this.onFileLoaded.bind(this);
    this.salesImport = this.salesImport.bind(this);
    this.inventoryImportWithQty = this.inventoryImportWithQty.bind(this);
    this.inventoryImportWithPrice = this.inventoryImportWithPrice.bind(this);
    this.inventoryImportUniqueNo = this.inventoryImportUniqueNo.bind(this);
    this.onInventoryImportChange = this.onInventoryImportChange.bind(this);
    this.multipleCouponImport = this.multipleCouponImport.bind(this);
    this.salesLegacyImport = this.salesLegacyImport.bind(this);
    this.onStoreSelection = this.onStoreSelection.bind(this);
    this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.vendorProductsImport = this.vendorProductsImport.bind(this);
    this.inventoryImportBatches = this.inventoryImportBatches.bind(this);
    this.checkDateFormat = this.checkDateFormat.bind(this);
    this.getMonth = this.getMonth.bind(this);
    this.saleschannelImport = this.saleschannelImport.bind(this);
  }

  componentDidMount() {
    let importTemplate = "";
    let inventoryImportTypeSelected = "";
    if (this.props.importType === "membership") {
      importTemplate = this.state.membershipImportTemplate;
    } else if (this.props.importType === "sales") {
      importTemplate = this.state.salesImportTemplate;
    } else if (this.props.importType === "inventoryQty") {
      inventoryImportTypeSelected = this.props.importType;
      importTemplate = this.state.inventoryImportQtyTemplate;
    } else if (this.props.importType === "inventoryPrice") {
      inventoryImportTypeSelected = this.props.importType;
      importTemplate = this.state.inventoryImportPriceTemplate;
    } else if (this.props.importType === "producttags") {
      importTemplate = this.state.tagImportTemplate;
    } else if (this.props.importType === "multiplecouponcodes") {
      importTemplate = this.state.multipleCouponCodes;
    } else if (this.props.importType === "multipleclientcouponcodes") {
      importTemplate = this.state.multipleClientCouponCodeTemplate;
    } else if (this.props.importType === "salesLegacyImport") {
      // console.log('this.props.importType', this.props.importType)
      importTemplate = this.state.salesLegacyImportTemplate;
      this.props
        .getUserStores(
          this.props.user.user.RegistrationID,
          this.props.user.auth.uid
        )
        .then((stores) => {
          // console.log(stores)
          this.setState({ stores: stores });
        });
    } else if (this.props.importType === "employeeservices") {
      importTemplate = this.state.tagImportTemplate;
    } else if (this.props.importType === "vendorproducts") {
      importTemplate = this.state.vendorProductsImportTemplate;
    }
    this.setState({
      importTemplate: importTemplate,
      inventoryImportTypeSelected: inventoryImportTypeSelected,
    });
  }

  onStoreSelection(storeID) {
    this.props.getCashRegisters(storeID).then((cashRegisters) => {
      this.setState({
        cashRegisters: cashRegisters,
        store: this.state.stores.filter((f) => f.key === storeID)[0],
      });
    });
  }

  async multipleClientCouponImport(collection) {
    this.setState({ loading: true });

    if (
      this.state.multipleClientCouponCodeColumns.length !== collection[0].length
    ) {
      alert("Column missing:- File is not in desired format");
      this.setState({ loading: false });
      return;
    } else {
      let result = await this.props.getBulkCustomers(
        this.props.user.user.RegistrationID,
        "",
        "active",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      );

      let customers = result.searchResult;
      let scrollID = result.scrollID;

      for (let index = 1; index < result.totalItemsCount / 2000; index++) {
        let result = await this.props.getBulkCustomers(
          this.props.user.user.RegistrationID,
          scrollID,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          ""
        );
        result.searchResult.forEach((element) => {
          customers.push(element);
        });
      }

      // console.log("customers", JSON.stringify(customers));

      this.state.multipleClientCouponCodeColumns.map(
        (multipleCouponCodesColumns, index) => {
          if (collection[0][index] !== multipleCouponCodesColumns) {
            alert("Column sequence missing:- File is not in desired format");
            this.setState({ loading: false });
            return;
          }
        }
      );
      let couponCodes = [];
      collection.map((data, index) => {
        let errorMessage = "";
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            let code = {
              CouponCode: data[0],
              CustomerName: "",
              PhoneNo: data[1],
            };
            let customerID = "";
            customers
              .filter(
                (e) => code.PhoneNo.trim() === e.PhoneNo.trim() && !e.IsDeleted
              )
              .map((customer) => {
                customerID = customer.key;
                code.CustomerName = (
                  customer.FirstName.trim() +
                  " " +
                  customer.LastName.trim()
                ).trim();
              });
            if (!isNullOrUndefined(customerID) && customerID != "") {
              code.CustomerID = customerID;
            } else {
              errorMessage += "Customer Not Found!!!";
            }
            if (errorMessage != "") {
              let errorObj = {
                CouponCode: data[0],
                MobileNo: data[1],
              };
              this.state.errorClientCodes.push({
                ...errorObj,
                Message: errorMessage,
              });
              this.setState({ errorCounter: this.state.errorCounter + 1 });
            } else {
              this.setState({ successCounter: this.state.successCounter + 1 });
              couponCodes.push(code);
            }
          }
        }
      });
      let len = couponCodes.length;
      len = Number(len) + Number(this.state.errorClientCodes.length);
      this.setState({ totalCounter: len });
      if (this.state.errorClientCodes.length === 0) {
        this.setState({ loading: false });
        this.props.onImportCompletion(couponCodes);
      } else {
        this.setState({ loading: false });
      }
    }
  }

  onCashRegisterSelection(cashRegisterID) {
    this.setState({
      cashRegister: this.state.cashRegisters.filter(
        (f) => f.key === cashRegisterID
      )[0],
    });
  }

  multipleCouponImport(collection) {
    if (this.state.multipleCouponCodesColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.multipleCouponCodesColumns.map(
        (multipleCouponCodesColumns, index) => {
          if (collection[0][index] !== multipleCouponCodesColumns) {
            alert("Column sequence missing:- File is not in desired format");
            return;
          }
        }
      );
      let couponCodes = [];
      collection.map((data, index) => {
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            couponCodes.push({ CouponCode: data[0] });
          }
        }
      });
      this.props.onImportCompletion(couponCodes);
    }
  }

  onInventoryImportChange(inventoryImportTypeSelected) {
    let importTemplate = "";
    if (inventoryImportTypeSelected === "inventoryUniqueNo") {
      importTemplate = this.state.inventoryUniqueNoTemplate;
    }
    if (inventoryImportTypeSelected === "inventoryBatches") {
      importTemplate = this.state.inventoryBatchTemplate;
    } else if (inventoryImportTypeSelected === "inventoryQty") {
      importTemplate = this.state.inventoryImportQtyTemplate;
    } else if (inventoryImportTypeSelected === "inventoryPrice") {
      importTemplate = this.state.inventoryImportPriceTemplate;
    }
    this.setState({
      importTemplate: importTemplate,
      inventoryImportTypeSelected: inventoryImportTypeSelected,
    });
  }

  async vendorProductsImport(collection) {
    this.setState({ loading: true, importStatus: "Reading file" });
    if (this.state.vendorProductsColumns.length !== collection[0].length) {
      this.setState({
        loading: false,
        importStatus: "File is not in desired format - Column missing",
      });
      // alert("Column missing - File is not in desired format");
      return;
    }
    this.state.vendorProductsColumns.map((vendorProductsColumns, index) => {
      if (collection[0][index] !== vendorProductsColumns) {
        this.setState({
          loading: false,
          importStatus:
            "File is not in desired format - Column sequence missing",
        });
        // alert("Column sequence missing - File is not in desired format");
        return;
      }
    });
    let vendorProducts = [];
    collection.map((data, index) => {
      if (index > 0) {
        if (data.length < 4) {
          this.setState({
            loading: false,
            importStatus: "Data missing in column at row no " + index,
          });
          return;
        }
        vendorProducts.push({
          StoreCode: data[0],
          ProductSKU: data[1],
          SupplyPrice: data[2],
          Discount: data[3],
        });
      }
    });
    // console.log('vendorProducts',vendorProducts)
    this.setState({ importStatus: "Processing file" });
    let errors = [];
    errors = await this.props.onImportCompletion(vendorProducts);
    if (errors.length > 0) {
      this.setState({
        importStatus: "Processing Failed",
        loading: false,
        errors: errors,
      });
    } else {
      this.setState({ importStatus: "Processing Succesful", loading: false });
    }
  }

  onFileLoaded(collection) {
    // console.log('this.props.importType',this.props.importType)
    this.setState({ showFileUpload: false });
    this.setState({ totalCounter: collection.length });

    if (this.props.importType === "membership") {
      this.membershipImport(collection);
    }
    if (this.props.importType === "saleschannel") {
      this.saleschannelImport(collection);
    }
    // if(this.props.importType === "membershipDiscount"){
    //     this.membershipImportOnDiscount(collection);
    // }

    if (this.props.importType === "sales") {
      this.salesImport(collection);
    }
    if (this.props.importType === "multiplecouponcodes") {
      this.multipleCouponImport(collection);
    }
    if (this.props.importType === "multipleclientcouponcodes") {
      this.multipleClientCouponImport(collection);
    }
    if (this.props.importType === "inventoryQty") {
      if (this.state.inventoryImportTypeSelected === "inventoryUniqueNo") {
        this.inventoryImportUniqueNo(collection);
      } else if (
        this.state.inventoryImportTypeSelected === "inventoryBatches"
      ) {
        this.inventoryImportBatches(collection);
      } else {
        this.inventoryImportWithQty(collection);
      }
    }
    if (this.props.importType === "inventoryPrice") {
      if (this.state.inventoryImportTypeSelected === "inventoryUniqueNo") {
        this.inventoryImportUniqueNo(collection);
      } else if (
        this.state.inventoryImportTypeSelected === "inventoryBatches"
      ) {
        this.inventoryImportBatches(collection);
      } else {
        this.inventoryImportWithPrice(collection);
      }
    }
    if (this.props.importType === "producttags") {
      this.tagImport(collection);
    }
    if (this.props.importType === "salesimport") {
      this.tagImport(collection);
    }
    if (this.props.importType === "salesLegacyImport") {
      console.log("salesLegacyImport");
      this.salesLegacyImport(collection);
    }
    if (this.props.importType === "employeeservices") {
      this.serviceImport(collection);
    }
    if (this.props.importType === "vendorproducts") {
      this.vendorProductsImport(collection);
    }
  }

  async salesLegacyImport(collection) {
    this.setState({ loading: true, importStatus: "Validating import file" });
    console.log("salesLegacyImport", collection);
    if (!this.state.transactionType) {
      alert("Please select transaction type");
      this.setState({ loading: false, importStatus: "", showFileUpload: true });
      return;
    }
    if (!this.state.store) {
      alert("Please select store");
      this.setState({ loading: false, importStatus: "", showFileUpload: true });
      return;
    }
    if (!this.state.cashRegister) {
      alert("Please select cash register");
      this.setState({ loading: false, importStatus: "", showFileUpload: true });
      return;
    }
    if (collection.length > 2000) {
      alert("Record limit exceeded");
      this.setState({ loading: false, importStatus: "", showFileUpload: true });
      return;
    }
    console.log("collection: " + collection[0]);
    // console.log("state: "  + this.state.membershipColumns);

    if (this.state.salesLegacyImportColumns.length !== collection[0].length) {
      alert("Column missing. File is not in desired format");
      this.setState({ loading: false, importStatus: "", showFileUpload: true });
      return;
    } else {
      this.state.salesLegacyImportColumns.map((column, index) => {
        if (collection[0][index] !== column) {
          alert("Column sequence missing. File is not in desired format");
          this.setState({
            loading: false,
            importStatus: "",
            showFileUpload: true,
          });
          return;
        }
      });
      let errorCount = 0;
      let successCounter = 0;
      let finalCollection = [];
      let errors = [];
      for (let l = 1; l < collection.length; l++) {
        let data = collection[l];
        let index = l;
        if (data.length !== this.state.salesLegacyImportColumns.length) {
          continue;
        }
        let error = "";
        if (data[0].trim() === "") {
          error = "Invoice name missing.";
        }
        if (data[1].trim() === "") {
          error = error + "Invoice date missing.";
        }

        if (data[9].trim() === "") {
          error = error + "Item code missing.";
        }
        if (data[11].trim() === "") {
          error = error + "Price missing.";
        }
        if (data[12].trim() === "") {
          error = error + "Qty missing.";
        }
        if (data[13].trim() === "") {
          error = error + "Subtotal missing.";
        }
        if (data[16].trim() === "") {
          error = error + "Grand total missing.";
        }
        let producsFilter = this.props.config.Products.filter(
          (f) => f.SKU === data[9].trim().replace("'", "")
        );
        if (producsFilter.length === 0) {
          error = error + "Product not found.";
        }
        if (error.trim() !== "") {
          errorCount = errorCount + 1;
          data[17] = "Error";
          data[18] = error;
          //console.log(data);
          errors.push(data);
        } else {
          finalCollection.push(data);
          successCounter = successCounter + 1;
        }
      }
      if (errorCount > 0) {
        // console.log("errors", JSON.stringify(errors));
        this.setState({ loading: false, importStatus: "Import Failed!!!" });
        this.setState({
          totalCounter: Number(successCounter) + Number(errorCount),
          successCounter: successCounter,
          errorCounter: errorCount,
          errors: errors,
        });
      } else {
        //if no error then process data
        this.setState({ loading: true, importStatus: "Saving data" });
        let invoiceName = "";
        let sale = null;
        let billQty = 0;
        let billSubTotal = 0;
        let billDiscount = 0;
        let billAmount = 0;
        console.log("finalCollection", finalCollection);
        for (let i = 0; i < finalCollection.length; i++) {
          let data = finalCollection[i];
          console.log("data", data);
          if (invoiceName.trim() !== data[0].trim()) {
            if (invoiceName !== "") {
              sale.BillQty = billQty;
              sale.BillSubTotal = billSubTotal;
              sale.BillDiscount = billDiscount;
              sale.BillAmount = billAmount;
              console.log("sale - save", sale);
              //add sale
              let salekey = await this.props.addSales(sale);
              // .then((key)=> {
              console.log("sale saved", salekey);
              // })
            }
            //create new invoice object
            invoiceName = data[0].trim();
            billQty = 0;
            billSubTotal = 0;
            billDiscount = 0;
            billAmount = 0;
            //serch customer
            // let customers = this.props.getCustomers(this.props.user.user.RegistrationID, "",
            // 0,10,data[0], "","","","","","","","","","","","","","")
            let customer = null;
            if (data[3].trim() !== "") {
              let result = await this.props.searchCustomer(
                this.props.user.user.RegistrationID,
                this.state.store.key,
                data[3]
              );
              // this.state.store.key

              console.log("result.searchResult", result.searchResult);
              if (result.searchResult && result.searchResult.length > 0) {
                //customer found
                //
                let customerTmp = result.searchResult[0];
                customer = {
                  key: customerTmp.key,
                  CustomerCode: customerTmp.CustomerCode,
                  FirstName: customerTmp.FirstName,
                  LastName: customerTmp.LastName,
                  EmailID: customerTmp.EmailID,
                  PhoneNo: customerTmp.PhoneNo,
                  Membership: customerTmp.Membership,
                  Note: "",
                  CustomField1: customerTmp.CustomField1,
                  CustomField2: customerTmp.CustomField2,
                  CustomField3: customerTmp.CustomField3,
                  CustomField4: customerTmp.CustomField4,
                  CustomField5: customerTmp.CustomField5,
                  CustomField6: customerTmp.CustomField6,
                  WalletBalance: 0,
                  Addresses: null,
                  SaleToPurchaseStoreID: "",
                  OpeningOutStandingAmount: 0,
                  OpeningOutStandingAmountCurrent: 0,
                  OpeningOutStandingAmountReceived: 0,
                  OutStandingAmount: 0,
                };
                console.log("Customer found", customer);
              }
            } else {
              //create new customer
              if (data[2].trim() !== "" || data[3].trim() !== "") {
                let addresses = [];
                if (
                  data[4].trim() !== "" ||
                  data[5].trim() !== "" ||
                  data[6].trim() !== "" ||
                  data[7].trim() !== "" ||
                  data[8].trim() !== ""
                ) {
                  addresses.push({
                    AddressType: "Other",
                    Address1: data[4].trim(),
                    Address2: data[5].trim(),
                    City: data[7].trim(),
                    State: data[6].trim(),
                    PostalCode: data[8].trim(),
                    Country: "",
                  });
                }
                customer = {
                  CustomerCode: "",
                  FirstName: data[2].trim(),
                  LastName: "",
                  EmailID: "",
                  PhoneNo: data[3].trim(),
                  DOB: null,
                  Anniversary: null,
                  // DOBNew: null,
                  // AnniversaryNew: null,
                  Membership: {},
                  CustomerAcceptsMarketing: false,
                  Note: "",
                  Gender: "",
                  Addresses: addresses,
                  StoreID: this.state.store.key,
                  CustomField1: "",
                  CustomField2: "",
                  CustomField3: "",
                  CustomField4: "",
                  CustomField5: "",
                  CustomField6: "",
                  RegistrationID: this.props.user.user.RegistrationID,
                  IsDeleted: false,
                  BackEndUpdate: false,
                  IsMigrated: true,
                };
                console.log("customer - save", customer);
                let customerKey = await this.props.addCustomer(customer);
                // .then((key)=> {
                customer.key = customerKey;
                console.log("customerKey", customerKey);
                // })
              }
            }
            let datePart = data[1].split(" ");
            console.log("datePart", datePart);
            let dataSplit;
            if (datePart[0].includes("/")) {
              dataSplit = datePart[0].split("/");
            } else if (datePart[0].includes("-")) {
              dataSplit = datePart[0].split("-");
            } else {
              alert("Invalid date format");
              continue;
            }
            //data[1].substring(0, 8).split('/');
            console.log("dataSplit", dataSplit);

            let yy =
              dataSplit[2].length === 4 ? dataSplit[2] : "20" + dataSplit[2];
            let mm = dataSplit[1];
            let dd = dataSplit[0];
            let tDate = yy + "-" + mm + "-" + dd;
            console.log("tDate", tDate);
            let transactionDate = new Date(tDate);
            console.log("transactionDate", transactionDate);
            sale = {
              TransactionNo: data[0].trim(),
              TransactionType: this.state.transactionType,
              Customer: customer,
              TransactionDate: transactionDate,
              RegistrationID: this.props.user.user.RegistrationID,
              CashRegister: this.state.cashRegister,
              Store: this.state.store,
              LineItems: [],
              BillQty: 0,
              BillSubTotal: 0,
              BillDiscount: 0,
              BillTaxAmount: 0,
              BillAmount: 0,
              RoundOff: 0,
              BillBalanceAmount: 0,
              BillPaidAmount: 0,
              BillChangeDue: 0,
              Payments: [],
              ParentTransactionNo: "",
              ParentTransactionKey: "",
              Employee: null,
              Notes: "",
              TaxSummaryList: [],
              PlaceOfSupply: "",
              DeliveryCustomer: null,
              DeliveryCustomerAddress: null,
              DeliveryDate: null,
              DeliveryTime: null,
              DeliveryNotes: "",
              BackEndUpdate: false,
              ReceivedPaymentAmount: 0,
              ReceivedPaymentTransactions: [],
              IsMigrated: true,
            };
          }
          //add line item to sale object
          let product = this.props.config.Products.filter(
            (f) => f.SKU === data[9].trim().replace("'", "")
          )[0];
          let lineItem = {};
          lineItem.RetailPrice = Number(data[11]);
          lineItem.DiscountPer = Number(data[14]);
          lineItem.DiscountAmount = Number(data[15]);
          lineItem.Qty = Number(data[12]);
          lineItem.SubTotal = Number(data[13]);
          lineItem.ChargeTaxOnProduct = false;
          lineItem.PriceBasedTax = false;
          lineItem.HSNSACCode = product.HSNSACCode;
          lineItem.TaxInclusive = false;
          lineItem.TaxGroup = null;
          lineItem.TaxID = "";
          lineItem.SubTotalWithDiscount = 0;
          lineItem.TaxableAmount = 0;
          lineItem.TaxComponentAmount = [];
          lineItem.TotalTax = 0;
          lineItem.Amount = Number(data[16]);
          lineItem.Product = product;
          lineItem.Employee = { key: "" };
          lineItem.Notes = "";
          sale.LineItems.push(lineItem);
          billQty = billQty + lineItem.Qty;
          billSubTotal = billSubTotal + lineItem.SubTotal;
          billDiscount = billDiscount + lineItem.DiscountAmount;
          billAmount = billAmount + lineItem.Amount;
        }
        //finally add the last line item
        if (sale) {
          sale.BillQty = billQty;
          sale.BillSubTotal = billSubTotal;
          sale.BillDiscount = billDiscount;
          sale.BillAmount = billAmount;
          console.log("sale - save last", sale);
          // add sale

          // let salekey = await this.props.addSales(sale)

          // this.props.addSales(sale).then((key) => {
          //     console.log('sale saved', salekey)
          // })
        }
      }
    }
    alert("Import completed");
    this.setState({ loading: false, importStatus: "Import completed" });
  }

  tagImport(collection) {
    console.log("collection: " + collection[0]);
    // console.log("state: "  + this.state.membershipColumns);

    if (this.state.tagColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.tagColumns.map((tagColumn, index) => {
        if (collection[0][index] !== tagColumn) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      let products = [];
      collection.map((data, index) => {
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let product = {
              SKU: data[0],
              Name: "",
            };
            let productID = "";
            this.props.config.Products.filter(
              (e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted
            ).map((prod) => {
              productID = prod.key;
              product.Name = prod.Name;
            });
            if (!isNullOrUndefined(productID) && productID != "") {
              product.ProductID = productID;
              products.push(product);
            }
          }
        }
      });
      this.props.onImportCompletion(products);
    }
  }

  saleschannelImport(collection) {
    console.log("collection: " + collection);
    let salesChannelColumns = [
      "SKU",
      "Markup Percentage",
      "Markup Amount",
      "Retail Price",
    ];
    if (salesChannelColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      salesChannelColumns.map((column, index) => {
        if (collection[0][index] !== column) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      let products = [];
      collection.map((data, index) => {
        console.log("index", index);
        if (index > 0) {
          console.log("data", data);
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let product = {
              SKU: data[0].replace("'", ""),
              Name: "",
              RetailPrice: Number(data[3]),
              MarkupPercentage: Number(data[1]),
              MarkupAmount: Number(data[2]),
            };
            let tmp = this.props.config.Products.filter(
              (e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted
            );
            if (tmp.length > 0) {
              product.ProductID = tmp[0].key;
              products.push(product);
            }
          }
        }
      });
      console.log("products", products);
      this.props.onImportCompletion(products);
    }
  }

  membershipImport(collection) {
    console.log("collection: " + collection);
    // console.log("state: "  + this.state.membershipColumns);
    if (this.state.membershipColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.membershipColumns.map((membershipColumn, index) => {
        if (collection[0][index] !== membershipColumn) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      let products = [];
      collection.map((data, index) => {
        console.log("index", index);
        if (index > 0) {
          console.log("data", data);
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let product = {
              SKU: data[0].replace("'", ""),
              Name: "",
              RetailPrice: Number(data[3]),
              DiscountPercentage: Number(data[1]),
              DiscountAmount: Number(data[2]),
            };
            let productID = "";
            let tmp = this.props.config.Products.filter(
              (e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted
            );
            // .map((prod)=> {productID=prod.key;product.Name=prod.Name})
            if (tmp.length > 0) {
              product.ProductID = tmp[0].key;
              products.push(product);
            }
            // if(!isNullOrUndefined(productID) && productID !="")
            // {

            // }
          }
        }
      });
      console.log("products", products);
      this.props.onImportCompletion(products);
    }
  }

  membershipImportOnDiscount(collection) {
    if (this.state.membershipColumnsDiscount.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.membershipColumnsDiscount.map((membershipColumn, index) => {
        if (collection[0][index] !== membershipColumn) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      let products = [];
      collection.map((data, index) => {
        let errorMessage = "";
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let product = {
              SKU: data[0],
              Name: "",
              RetailPrice: 0,
              DiscountPercentage: Number(data[1]),
              DiscountAmount: Number(data[2]),
            };
            let productID = "";
            this.props.config.Products.filter(
              (e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted
            ).map((prod) => {
              productID = prod.key;
              product.Name = prod.Name;
            });
            if (!isNullOrUndefined(productID) && productID != "") {
              product.ProductID = productID;
            }
            if (product.DiscountAmount > 0 && product.DiscountPercentage > 0) {
              errorMessage +=
                "Discount Amt. and Discount Percentage cannot be non-zero.";
            }

            if (errorMessage != "") {
              let errorObj = {
                SKU: data[0],
                DiscountPercentage: Number(data[1]),
                DiscountAmount: Number(data[2]),
              };
              this.state.errorMemberships.push({
                ...errorObj,
                Message: errorMessage,
              });
              this.setState({ errorCounter: this.state.errorCounter + 1 });
            } else {
              this.setState({ successCounter: this.state.successCounter + 1 });
              products.push(product);
            }
          }
        }
      });
      let len = products.length;
      len = Number(len) + Number(this.state.errorMemberships.length);
      this.setState({ totalCounter: len });
      if (this.state.errorMemberships.length === 0) {
        this.props.onImportCompletion(products);
      }
    }
  }

  salesImport(collection) {
    if (this.state.salesColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.salesColumns.map((saleColumn, index) => {
        if (collection[0][index] !== saleColumn) {
          //console.log("Column sequence missing:- File is not in desired format", collection[0][index] + ' ' + saleColumn);
          alert(
            "Column sequence missing:- File is not in desired format",
            collection[0][index] + " " + saleColumn
          );
          return;
        }
      });
      let lineItems = [];
      collection.map((data, index) => {
        let errorMessage = "";
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let lineItem = {
              Notes: data[2],
              RetailPrice: data[6],
              Qty: data[4],
              Product: null,
              TaxableAmount: data[7],
              Amount: data[14],
              TaxComponentAmount: null,
            };
            this.props.config.Products.filter(
              (e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted
            ).map((prod) => {
              lineItem.Product = prod;
            });
            if (isNullOrUndefined(lineItem.Product)) {
              errorMessage += "Product does not exists.";
            }

            let taxComponent = [];
            if (data[9].trim() != "" && data[11].trim() != "") {
              lineItem.TotalTax = Number(data[10]) + Number(data[12]);
              lineItem.TaxGroup =
                "GST " +
                (Number(data[9].trim()) + Number(data[11].trim())) +
                "%";
              let tax1 = {
                TaxAmount: Number(data[10]),
                TaxName: "CGST",
                TaxPercentage: Number(data[9]),
                TaxType: "CGST",
              };
              taxComponent.push(tax1);
              let tax2 = {
                TaxAmount: Number(data[12]),
                TaxName: "SGST",
                TaxPercentage: Number(data[11]),
                TaxType: "SGST",
              };
              taxComponent.push(tax2);
            } else if (data[13] != "") {
              lineItem.TotalTax = Number(data[14]);
              lineItem.TaxGroup = "GST " + data[13].trim() + "%";
              let tax1 = {
                TaxAmount: Number(data[14]),
                TaxName: "IGST",
                TaxPercentage: Number(data[13]),
                TaxType: "IGST",
              };
              taxComponent.push(tax1);
            }
            this.props.config.ProductTaxes.filter(
              (e) =>
                lineItem.TaxGroup.trim() === e.TaxGroup.trim() && !e.IsDeleted
            ).map((productTax) => (lineItem.TaxID = productTax.key));
            if (isNullOrUndefined(lineItem.TaxID) || lineItem.TaxID === "") {
              errorMessage += "Please enter a valid Tax Group.";
            } else {
              lineItem.TaxComponentAmount = taxComponent;
            }
            let taxInclusive = false;
            if (
              data[8].toLowerCase() === "y" ||
              data[8].toLowerCase() === "yes"
            ) {
              taxInclusive = true;
            }
            lineItem.TaxInclusive = taxInclusive;
            if (errorMessage != "") {
              let errorObj = {
                Barcode: data[0],
                "Description of Goods": data[1],
                Batch: data[2],
                MRP: data[3],
                Qty: data[4],
                Size: data[5],
                "Basic Rate": data[6],
                "Taxable Value": data[7],
                "Taxable Inclusive": data[8],
                "SGST Rate": data[9],
                "SGST Amount": data[10],
                "CGST Rate": data[11],
                "CGST Amount": data[12],
                "IGST Rate": data[13],
                "IGST Amount": data[14],
                "Total Amount": data[15],
              };
              this.state.errorSales.push({
                ...errorObj,
                Message: errorMessage,
              });
              this.setState({ errorCounter: this.state.errorCounter + 1 });
              console.log(
                "Errored Line Items: " + JSON.stringify(this.state.errorSales)
              );
            } else {
              this.setState({ successCounter: this.state.successCounter + 1 });
              lineItems.push(lineItem);
            }
          }
        }
      });
      let len = lineItems.length;
      len = Number(len) + Number(this.state.errorSales.length);
      this.setState({ totalCounter: len });
      //this.props.onImportCompletion(lineItems);
    }
  }

  async inventoryImportWithQty(collection) {
    if (collection.length > 200) {
      alert("Maximum rows reached");
    }
    if (
      this.state.inventoryWithoutPriceColumns.length !== collection[0].length
    ) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.inventoryWithoutPriceColumns.map((column, index) => {
        if (collection[0][index] !== column) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      this.setState({ processing: true });
      let lineItems = [];
      let errors = [];
      let successCounter = 0;
      let errorCounter = 0;
      let totalCounter = collection.length - 1;

      for (let i = 1; i < collection.length; i++) {
        let data = collection[i];
        if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
          // data[1] != null && data[1]!= undefined && data[1] !== "")
          if (isNaN(data[1])) {
            let errorObj = {
              "Excel Row No": i + 1,
              SKU: data[0],
              Qty: data[1],
              Notes: data[2],
              Message: "Invalid qty'",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          // console.log("data[0]", data[0]);
          let products = await this.props.getProductsBySKU(
            this.props.registration.registration.key,
            data[0].trim()
          );
          if (products.length === 0) {
            let errorObj = {
              "Excel Row No": i + 1,
              SKU: data[0],
              Qty: data[1],
              Notes: data[2],
              Message: "Product not found",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (products.length > 1) {
            let errorObj = {
              "Excel Row No": i + 1,
              SKU: data[0],
              Qty: data[1],
              Notes: data[2],
              Message: "Multiple products with same SKU",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          let product = products[0];
          if (
            product.hasOwnProperty("Variants") &&
            product.Variants.length > 0
          ) {
            let errorObj = {
              "Excel Row No": i + 1,
              SKU: data[0],
              Qty: data[1],
              Notes: data[2],
              Message: "Invalid product. Please select the variant product'",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (product.ProductTypeID > 2) {
            let errorObj = {
              "Excel Row No": i + 1,
              SKU: data[0],
              Qty: data[1],
              Notes: data[2],
              Message:
                "Invalid product type. Should be 'Goods for sale' or 'Goods not for sale'",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          let lineItem = {
            Qty: data[1],
            Notes: data[2],
          };
          if (
            product.hasOwnProperty("PurchaseUOM") &&
            product.PurchaseUOM !== ""
          ) {
            lineItem.UOM = product.PurchaseUOM;
          } else if (product.hasOwnProperty("UOM") && product.UOM !== "") {
            lineItem.UOM = product.UOM;
          }
          lineItem.LineItemQty = lineItem.Qty;
          lineItem.FreeQty = 0;
          lineItem.Product = product;
          lineItem.SupplyPrice = 0;
          lineItem.SubTotal = 0;
          lineItem.ChargeTaxOnProduct = false;
          lineItem.PriceBasedTax = false;
          lineItem.HSNSACCode = "";
          lineItem.TaxInclusive = false;
          lineItem.TaxGroup = "";
          lineItem.TaxID = "";
          lineItem.DiscountPer = 0;
          lineItem.DiscountAmount = 0;
          lineItem.SubTotalWithDiscount = 0;
          lineItem.TaxableAmount = 0;
          lineItem.TotalTax = 0;
          lineItem.Amount = 0;
          lineItems.push(lineItem);
          successCounter = successCounter + 1;
          this.setState({ successCounter: successCounter });
        }
      }
      this.setState({
        totalCounter: totalCounter,
        successCounter: successCounter,
        errorCounter: errorCounter,
        inventoryError: errors,
        processing: false,
      });
      if (errors.length === 0) {
        this.props.onImportCompletion(lineItems);
      }
    }
  }

  async inventoryImportWithPrice(collection) {
    // console.log('collection', collection)
    // console.log('this.state.inventoryWithPriceColumns', this.state.inventoryWithPriceColumns)
    if (this.state.inventoryWithPriceColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.inventoryWithPriceColumns.map((column, index) => {
        if (collection[0][index] !== column) {
          alert(
            "Column sequence missing:- File is not in desired format",
            collection[0][index] + " " + column
          );
          return;
        }
      });
      this.setState({ processing: true });
      let lineItems = [];
      let errors = [];
      let successCounter = 0;
      let errorCounter = 0;
      let totalCounter = collection.length - 1;
      this.setState({ totalCounter: totalCounter });

      for (let i = 1; i < collection.length; i++) {
        let data = collection[i];
        let errorMessage = "";
        if (
          !isNullOrUndefined(data) &&
          data != "" &&
          data[0] != "" &&
          !isNaN(data[2])
        ) {
          // data[1] != null && data[1]!= undefined && data[1] !== "")
          let lineItem = {
            SKU: data[0],
            SupplyPrice: Number(data[1]),
            Qty: Number(data[2]),
            DiscountAmount: Number(data[3]),
            HSNSACCode: data[4],
            TaxGroup: data[6],
            Notes: data[7],
            LineItemQty: Number(data[2]),
            FreeQty: 0,
          };
          let chargeTaxOnProduct = false;
          let taxInclusive = false;
          let taxID = "";

          let products = await this.props.getProductsBySKU(
            this.props.registration.registration.key,
            data[0].trim()
          );
          if (products.length === 0) {
            let errorObj = {
              "Excel Row No": i,
              SKU: data[0],
              "Supply Price": data[1],
              Qty: data[2],
              "Discount Amount": data[3],
              HSNSACCode: data[4],
              "Tax Inclusive": data[5],
              "Tax Group": data[6],
              Notes: data[7],
              Message: "Product not found",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (products.length > 1) {
            let errorObj = {
              "Excel Row No": i,
              SKU: data[0],
              "Supply Price": data[1],
              Qty: data[2],
              "Discount Amount": data[3],
              HSNSACCode: data[4],
              "Tax Inclusive": data[5],
              "Tax Group": data[6],
              Notes: data[7],
              Message: "Multiple products with same SKU",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          let product = products[0];
          lineItem.Product = product;

          if (lineItem.Product.ProductTypeID > 2) {
            let errorObj = {
              "Excel Row No": i,
              SKU: data[0],
              "Supply Price": data[1],
              Qty: data[2],
              "Discount Amount": data[3],
              HSNSACCode: data[4],
              "Tax Inclusive": data[5],
              "Tax Group": data[6],
              Notes: data[7],
              Message:
                "Invalid product type. Should be 'Goods for sale' or 'Goods not for sale'",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (
            lineItem.Product.hasOwnProperty("Variants") &&
            lineItem.Product.Variants.length > 0
          ) {
            let errorObj = {
              "Excel Row No": i,
              SKU: data[0],
              "Supply Price": data[1],
              Qty: data[2],
              "Discount Amount": data[3],
              HSNSACCode: data[4],
              "Tax Inclusive": data[5],
              "Tax Group": data[6],
              Notes: data[7],
              Message: "Invalid product. Please select the variant product",
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (lineItem.TaxGroup.trim() !== "") {
            this.props.config.ProductTaxes.filter(
              (e) =>
                lineItem.TaxGroup.trim() === e.TaxGroup.trim() && !e.IsDeleted
            ).map((productTax) => (taxID = productTax.key));
            if (isNullOrUndefined(taxID) || taxID === "") {
              errorMessage += "Please enter a valid Tax Group.";
            } else {
              chargeTaxOnProduct = true;
            }
            if (chargeTaxOnProduct) {
              if (
                data[5].toLowerCase() === "y" ||
                data[5].toLowerCase() === "yes"
              ) {
                taxInclusive = true;
              }
            }
          }
          if (errorMessage !== "") {
            let errorObj = {
              "Excel Row No": i,
              SKU: data[0],
              "Supply Price": data[1],
              Qty: data[2],
              "Discount Amount": data[3],
              HSNSACCode: data[4],
              "Tax Inclusive": data[5],
              "Tax Group": data[6],
              Notes: data[7],
              Message: errorMessage,
            };
            errorCounter = errorCounter + 1;
            this.setState({ errorCounter: errorCounter });
            errors.push(errorObj);
            continue;
          }
          if (
            product.hasOwnProperty("PurchaseUOM") &&
            product.PurchaseUOM !== ""
          ) {
            lineItem.UOM = product.PurchaseUOM;
          } else if (product.hasOwnProperty("UOM") && product.UOM !== "") {
            lineItem.UOM = product.UOM;
          }
          lineItem.TaxID = taxID;
          lineItem.SubTotal = lineItem.SupplyPrice * lineItem.Qty;
          lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
          lineItem.TaxInclusive = taxInclusive;
          lineItem.PriceBasedTax = false;
          lineItem.DiscountPer = 0;
          lineItem.SubTotalWithDiscount = 0;
          lineItem.TaxableAmount = 0;
          lineItem.TotalTax = 0;
          lineItem.Amount = 0;
          successCounter = successCounter + 1;
          this.setState({ successCounter: successCounter });
          lineItems.push(lineItem);
        }
      }
      let len = lineItems.length;
      len = Number(len) + Number(errors.length);
      this.setState({
        totalCounter: totalCounter,
        successCounter: successCounter,
        errorCounter: errorCounter,
        inventoryError: errors,
        processing: false,
      });
      if (errors.length === 0) {
        this.props.onImportCompletion(lineItems);
      }
    }
  }

  async inventoryImportUniqueNo(collection) {
    // console.log('inventoryImportUniqueNo')
    if (collection.length > 2001) {
      alert("Maximum rows reached");
    }
    if (this.state.inventoryUniqueNo.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    }

    this.state.inventoryUniqueNo.map((column, index) => {
      if (collection[0][index] !== column) {
        console.log(column);
        console.log(collection[0][index]);

        alert("Column sequence missing:- File is not in desired format");
        return;
      }
    });

    let lineItems = [];
    let errors = [];
    let successCounter = 0;
    let errorCounter = 0;
    let totalCounter = collection.length;

    for (let index = 1; index < collection.length; index++) {
      const data = collection[index];
      if (data != "" && data[0].trim() != "") {
        let product = null;
        let productKey = "";
        let error = "";
        if (data[0].trim() === "") {
          // data[1] != null && data[1]!= undefined && data[1] !== "")
          error = "SKU missing.";
        }
        if (
          data[1].trim() === "" &&
          data[2].trim() === "" &&
          data[3].trim() === "" &&
          data[4].trim() === "" &&
          error.trim() === ""
        ) {
          error = "UniqueNo missing.";
        }
        if (
          this.props.config.Products.filter(
            (e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted
          ).length === 0 &&
          error.trim() === ""
        ) {
          error = "Invalid SKU.";
        } else {
          product = this.props.config.Products.filter(
            (e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted
          )[0];
          if (
            product.hasOwnProperty("InventoryType") &&
            product.InventoryType.toLowerCase() !== "unique no"
          ) {
            error = error + "Invalid inventory type.";
          }
          if (this.props.inventoryInWardOrOutward.toLowerCase() === "inward") {
            //check in lineitems collection if its is duplicate
            productKey = product.key;
            let ls = lineItems.filter((f) => f.Product.key === productKey);
            for (let l = 0; l < ls.length; l++) {
              if (
                data[1].trim() !== "" &&
                ls[l].UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1.trim() === data[1].trim() ||
                    f.UniqueNo2.trim() === data[1].trim() ||
                    f.UniqueNo3.trim() === data[1].trim() ||
                    f.UniqueNo4.trim() === data[1].trim()
                ).length > 0
              ) {
                error = error + "Unique no already exists in file.";
                break;
              }
              if (
                data[2].trim() !== "" &&
                ls[l].UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1.trim() === data[2].trim() ||
                    f.UniqueNo2.trim() === data[2].trim() ||
                    f.UniqueNo3.trim() === data[2].trim() ||
                    f.UniqueNo4.trim() === data[2].trim()
                ).length > 0
              ) {
                error = error + "Unique no already exists in file.";
                break;
              }
              if (
                data[3].trim() !== "" &&
                ls[l].UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1.trim() === data[3].trim() ||
                    f.UniqueNo2.trim() === data[3].trim() ||
                    f.UniqueNo3.trim() === data[3].trim() ||
                    f.UniqueNo4.trim() === data[3].trim()
                ).length > 0
              ) {
                error = error + "Unique no already exists in file.";
                break;
              }
              if (
                data[4].trim() !== "" &&
                ls[l].UniqueNoList.filter(
                  (f) =>
                    f.UniqueNo1.trim() === data[4].trim() ||
                    f.UniqueNo2.trim() === data[4].trim() ||
                    f.UniqueNo3.trim() === data[4].trim() ||
                    f.UniqueNo4.trim() === data[4].trim()
                ).length > 0
              ) {
                error = error + "Unique no already exists in file.";
                break;
              }
            }
            //check in db
            if (data[1].trim() !== "") {
              let uniqueNo = await this.props.checkIfUniqueNoIsUnique(
                this.props.user.store.key,
                productKey,
                data[1]
              );
              if (uniqueNo) {
                error = data[1] + " already exists.";
              }
            }
            if (data[2].trim() !== "") {
              let uniqueNo = await this.props.checkIfUniqueNoIsUnique(
                this.props.user.store.key,
                productKey,
                data[2]
              );
              if (uniqueNo) {
                error = data[2] + " already exists.";
              }
            }
            if (data[3].trim() !== "") {
              let uniqueNo = await this.props.checkIfUniqueNoIsUnique(
                this.props.user.store.key,
                productKey,
                data[3]
              );
              if (uniqueNo) {
                error = data[3] + " already exists.";
              }
            }
            if (data[4].trim() !== "") {
              let uniqueNo = await this.props.checkIfUniqueNoIsUnique(
                this.props.user.store.key,
                productKey,
                data[4]
              );
              if (uniqueNo) {
                error = data[4] + " already exists.";
              }
            }
          }
        }
        if (error.trim() !== "") {
          let errorObj = {
            "Excel Row No": index + 1,
            SKU: data[0],
            UniqueNo1: data[1],
            UniqueNo2: data[2],
            UniqueNo3: data[3],
            UniqueNo4: data[4],
            Message: error,
          };
          console.log("errorObj", errorObj);
          errorCounter = errorCounter + 1;
          errors.push(errorObj);
          continue;
        }

        if (product) {
          if (
            lineItems.filter((f) => f.Product.key === productKey).length > 0
          ) {
            lineItems
              .filter((f) => f.Product.key === productKey)
              .map((m) => {
                m.UniqueNoList.push({
                  UniqueNo1: data[1],
                  UniqueNo2: data[2],
                  UniqueNo3: data[3],
                  UniqueNo4: data[4],
                  AllowEdit: true,
                  Error: "",
                  key: "",
                });
                m.Qty = m.UniqueNoList.length;
                m.LineItemQty = m.UniqueNoList.length;
              });
          } else {
            let lineItem = {};
            lineItem.Product = product;
            lineItem.Qty = 1;
            lineItem.LineItemQty = lineItem.Qty;
            lineItem.FreeQty = 0;
            lineItem.SupplyPrice = product.SupplyPrice;
            lineItem.SubTotal = 0;
            lineItem.ChargeTaxOnProduct = false;
            lineItem.PriceBasedTax = false;
            lineItem.HSNSACCode = "";
            lineItem.TaxInclusive = false;
            lineItem.TaxGroup = "";
            lineItem.TaxID = "";
            lineItem.DiscountPer = 0;
            lineItem.DiscountAmount = 0;
            lineItem.SubTotalWithDiscount = 0;
            lineItem.TaxableAmount = 0;
            lineItem.TotalTax = 0;
            lineItem.Amount = 0;
            lineItem.UniqueNoList = [];
            lineItem.UniqueNoList.push({
              UniqueNo1: data[1],
              UniqueNo2: data[2],
              UniqueNo3: data[3],
              UniqueNo4: data[4],
              AllowEdit: true,
              Error: "",
              key: "",
            });
            lineItems.push(lineItem);
          }
          successCounter = successCounter + 1;
        }
      }
    }
    this.setState({
      totalCounter: successCounter + errorCounter,
      successCounter: successCounter,
      errorCounter: errorCounter,
      inventoryError: errors,
    });
    if (errors.length === 0) {
      this.props.onImportCompletion(lineItems);
    }
  }

  async inventoryImportBatches(collection) {
    if (collection.length > 2001) {
      alert("Maximum rows reached");
    }
    if (this.state.inventoryBatches.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    }

    this.state.inventoryBatches.map((column, index) => {
      if (collection[0][index] !== column) {
        console.log(column);
        console.log(collection[0][index]);

        alert("Column sequence missing:- File is not in desired format");
        return;
      }
    });

    let lineItems = [];
    let errors = [];
    let successCounter = 0;
    let errorCounter = 0;
    // let totalCounter = collection.length

    for (let index = 1; index < collection.length; index++) {
      const data = collection[index];
      if (data != "" && data[0].trim() != "") {
        let product = null;
        let batchExpDateValue = null;
        let batchMfgDateValue = null;
        let productKey = "";
        let error = "";
        let chargeTaxOnProduct = false;
        let taxInclusive = false;
        let taxID = "";
        if (data[0].trim() === "") {
          error = "SKU missing.";
        }
        if (isNaN(data[6])) {
          error = "Invalid Qty.";
        }

        if (
          this.props.config.Products.filter(
            (e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted
          ).length === 0 &&
          error.trim() === ""
        ) {
          error = "Invalid SKU.";
        } else {
          product = this.props.config.Products.filter(
            (e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted
          )[0];
          if (
            product.hasOwnProperty("InventoryType") &&
            product.InventoryType.toLowerCase() !== "batch tracking"
          ) {
            error = error + "Invalid inventory type.";
          }
          if (this.props.inventoryInWardOrOutward.toLowerCase() === "inward") {
            //check in lineitems collection if its is duplicate
            productKey = product.key;

            if (
              product.BatchTrackingBatchNoRequired &&
              !product.BatchTrackingAutoBatchNoRequired &&
              data[1].trim() === ""
            ) {
              error = error + "Batch No missing.";
            }
            if (product.BatchTrackingMRPRequired && data[2].trim() === "") {
              error = error + "Batch MRP missing.";
            }
            if (product.BatchTrackingMfgDateRequired && data[4].trim() === "") {
              error = error + "Mfg Date missing.";
            }
            if (product.BatchTrackingMfgDateRequired && data[4].trim() !== "") {
              // batchMfgDateValue = await this.checkDateFormat(data[4], product.BatchTrackingDateFormat, "mfg")
              batchMfgDateValue = await checkDateFormat(
                data[4],
                product.BatchTrackingDateFormat,
                "mfg"
              );

              if (!batchMfgDateValue) {
                error = error + "Mfg Date format is invalid.";
              }
            }
            if (product.BatchTrackingExpDateRequired && data[5].trim() === "") {
              error = error + "Exp Date missing.";
            }
            if (product.BatchTrackingExpDateRequired && data[5].trim() !== "") {
              // batchExpDateValue = await this.checkDateFormat(data[5].trim(), product.BatchTrackingDateFormat, "exp")
              console.log("data", data);
              console.log(
                "product.BatchTrackingDateFormat",
                product.BatchTrackingDateFormat
              );
              console.log("product", product);

              batchExpDateValue = await checkDateFormat(
                data[5].trim(),
                product.BatchTrackingDateFormat,
                "exp"
              );

              if (!batchExpDateValue) {
                error = error + "Exp Date format is invalid.";
              }
            }

            if (data[10].trim() !== "") {
              this.props.config.ProductTaxes.filter(
                (e) => data[10].trim() === e.TaxGroup.trim() && !e.IsDeleted
              ).map((productTax) => (taxID = productTax.key));
              if (!taxID || taxID === "") {
                error = error + "Tax Group is invalid.";
              } else {
                chargeTaxOnProduct = true;
              }
              if (chargeTaxOnProduct) {
                if (
                  data[9].toLowerCase() === "y" ||
                  data[9].toLowerCase() === "yes"
                ) {
                  taxInclusive = true;
                }
              }
            }
          }
        }
        if (error.trim() !== "") {
          let errorObj = {
            "Excel Row No": index + 1,
            SKU: data[0],
            BatchNo: data[1],
            BatchMRP: data[2],
            BatchRetailPrice: data[3],
            BatchMfgDate: data[4],
            BatchExpDate: data[5],
            Qty: data[6],
            "Discount Amount": data[7],
            HSNSACCode: data[8],
            "Tax Inclusive": data[9],
            "Tax Group": data[10],
            Notes: data[11],
            Message: error,
          };
          // console.log("errorObj", errorObj);
          errorCounter = errorCounter + 1;
          errors.push(errorObj);
          continue;
        }

        if (product) {
          let lineItem = {};
          lineItem.Notes = data[11].trim();
          lineItem.Product = product;
          lineItem.Qty = data[6];
          lineItem.LineItemQty = lineItem.Qty;
          lineItem.FreeQty = 0;
          lineItem.SupplyPrice = product.SupplyPrice;
          //lineItem.SubTotal = 0
          lineItem.ChargeTaxOnProduct = chargeTaxOnProduct;
          lineItem.PriceBasedTax = false;
          lineItem.HSNSACCode = data[8];
          lineItem.TaxInclusive = taxInclusive;
          lineItem.TaxGroup = data[10];
          lineItem.TaxID = taxID;
          lineItem.DiscountAmount = data[7];
          lineItem.DiscountPer = 0;
          lineItem.SubTotalWithDiscount = 0;
          lineItem.TaxableAmount = 0;
          lineItem.TotalTax = 0;
          lineItem.Amount = 0;
          lineItem.UniqueNoList = [];
          lineItem.BatchNo = data[1].trim();
          lineItem.BatchMRP = Number(data[2].trim());
          lineItem.Product.MRP = Number(data[2].trim());
          lineItem.BatchRetailPrice = Number(data[3].trim());
          lineItem.Product.RetailPrice = Number(data[3].trim());
          lineItem.BatchMfgDate = data[4];
          lineItem.BatchExpDate = data[5];

          lineItem.SubTotal = lineItem.SupplyPrice * lineItem.Qty;
          if (lineItem.SubTotal > 0) {
            lineItem.DiscountPer = Number(
              ((Number(data[7]) * 100) / Number(lineItem.SubTotal)).toFixed(3)
            );
          }

          if (product.BatchTrackingMfgDateRequired) {
            lineItem.BatchMfgDateValue = batchMfgDateValue;
          }
          if (product.BatchTrackingExpDateRequired) {
            lineItem.BatchExpDateValue = batchExpDateValue;
          }
          lineItems.push(lineItem);

          successCounter = successCounter + 1;
        }
      }
    }
    this.setState({
      totalCounter: successCounter + errorCounter,
      successCounter: successCounter,
      errorCounter: errorCounter,
      inventoryError: errors,
    });
    if (errors.length === 0) {
      this.props.onImportCompletion(lineItems);
    }
  }

  checkDateFormat(data, format, type) {
    let dd = "";
    let mm = "";
    let yy = "";
    if (format.toLowerCase() === "mm/yy" || format.toLowerCase() === "mm-yy") {
      if (data.length > 5) {
        return null;
      }
      let pattern = /^[0-9]{2}[\/\-][0-9]{2}/i;
      let result = pattern.test(data);
      if (!result) {
        return null;
      }
      // let datas = data.split("/")
      let datas = [];
      if (format.toLowerCase() === "mm/yy") {
        datas = data.split("/");
      } else {
        datas = data.split("-");
      }
      mm = datas[0];
      yy = datas[1];
      if (Number(mm) < 0 && Number(mm) > 12) {
        return null;
      }
      return new Date(
        "20" + yy,
        type === "exp" ? mm : Number(mm) - 1,
        type === "exp" ? 0 : 1
      );
    } else if (
      format.toLowerCase() === "mmm/yy" ||
      format.toLowerCase() === "mmm-yy"
    ) {
      if (data.length > 5) {
        return null;
      }
      let pattern = /^[a-z]{3}[\/\-][0-9]{2}/i;
      let result = pattern.test(data);
      if (!result) {
        return null;
      }
      // let datas = data.split("/")
      let datas = [];
      if (format.toLowerCase() === "mmm/yy") {
        datas = data.split("/");
      } else {
        datas = data.split("-");
      }
      mm = this.getMonth(datas[0]);
      if (mm.trim() === "") {
        return null;
      }
      yy = datas[1];
      return new Date(
        "20" + yy,
        type === "exp" ? mm : Number(mm) - 1,
        type === "exp" ? 0 : 1
      );
    } else if (
      format.toLowerCase() === "mmm/yyyy" ||
      format.toLowerCase() === "mmm-yyyy"
    ) {
      if (data.length > 5) {
        return null;
      }
      let pattern = /^[a-z]{3}[\/\-][0-9]{4}/i;
      let result = pattern.test(data);
      if (!result) {
        return null;
      }
      // let datas = data.split("/")
      let datas = [];
      if (format.toLowerCase() === "mmm/yyyy") {
        datas = data.split("/");
      } else {
        datas = data.split("-");
      }

      mm = this.getMonth(datas[0]);
      if (mm.trim() === "") {
        return null;
      }
      yy = datas[1];
      // return new Date("20" + yy, mm, "01")
      return new Date(
        "20" + yy,
        type === "exp" ? mm : Number(mm) - 1,
        type === "exp" ? 0 : 1
      );
    } else if (
      format.toLowerCase() === "dd/mm/yy" ||
      format.toLowerCase() === "dd-mm-yy"
    ) {
      if (data.length > 8) {
        return null;
      }
      let pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{2}$/;
      let result = pattern.test(data);
      if (!result) {
        return null;
      }
      let datas = [];
      if (format.toLowerCase() === "dd/mm/yy") {
        datas = data.split("/");
      } else {
        datas = data.split("-");
      }
      dd = datas[0];
      mm = datas[1];
      yy = datas[2];
      if (Number(mm) < 0 && Number(mm) > 12) {
        return null;
      }
      // console.log('date', data)
      // console.log('type',type)
      // console.log('format', format)
      // console.log('dd', dd)
      // console.log('mm',mm)
      // console.log('yy', yy)
      // console.log('new date') type==="exp" ? mm :
      // console.log('new date', new Date("20" + yy,  Number(mm) -1 ,dd))
      // return new Date("20" + yy, type==="exp" ? mm : Number(mm) -1 ,type==="exp" ? 0 : 1)
      return new Date("20" + yy, Number(mm) - 1, dd);
    } else if (
      format.toLowerCase() === "dd/mmm/yyyy" ||
      format.toLowerCase() === "dd-mmm-yyyy"
    ) {
      if (data.length > 11) {
        return null;
      }

      let pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
      let result = pattern.test(data);
      if (!result) {
        return null;
      }
      let datas = [];
      if (format.toLowerCase() === "dd/mmm/yyyy") {
        datas = data.split("/");
      } else {
        datas = data.split("-");
      }
      dd = datas[0];
      mm = this.getMonth(datas[1]);
      yy = datas[2];
      if (mm.trim() === "" || dd.trim() === "") {
        return null;
      }
      // return new Date("20" + yy, type==="exp" ? mm : Number(mm) -1 ,type==="exp" ? 0 : 1)
      return new Date("20" + yy, Number(mm) - 1, dd);
    }
  }

  getMonth(month) {
    switch (month.toLowerCase()) {
      case "jan":
        return "01";
      case "feb":
        return "02";
      case "mar":
        return "03";
      case "apr":
        return "04";
      case "may":
        return "05";
      case "jun":
        return "06";
      case "jul":
        return "07";
      case "aug":
        return "08";
      case "sep":
        return "09";
      case "oct":
        return "10";
      case "nov":
        return "11";
      case "dec":
        return "12";
      default:
        return "";
    }
  }

  serviceImport(collection) {
    console.log("collection: " + collection[0]);
    // console.log("state: "  + this.state.membershipColumns);

    if (this.state.tagColumns.length !== collection[0].length) {
      alert("Column missing:- File is not in desired format");
      return;
    } else {
      this.state.tagColumns.map((tagColumn, index) => {
        if (collection[0][index] !== tagColumn) {
          alert("Column sequence missing:- File is not in desired format");
          return;
        }
      });
      let products = [];
      collection.map((data, index) => {
        if (index > 0) {
          if (!isNullOrUndefined(data) && data != "" && data[0] != "") {
            // data[1] != null && data[1]!= undefined && data[1] !== "")
            let product = {
              SKU: data[0],
              Name: "",
            };
            let productID = "";
            let retailPrice = 0;

            this.props.config.Products.filter(
              (e) =>
                product.SKU.trim() === e.SKU.trim() &&
                e.ProductTypeID === 3 &&
                !e.IsDeleted
            ).map((prod) => {
              productID = prod.key;
              product.Name = prod.Name;
              retailPrice = prod.RetailPrice;
            });
            if (!isNullOrUndefined(productID) && productID != "") {
              product.ProductID = productID;
              product.RetailPrice = retailPrice;
              products.push(product);
            }
          }
        }
      });
      this.props.onImportCompletion(products);
    }
  }

  onExportClick() {
    let data = [];
    let fileName = "";

    // console.log(this.props.importType);

    // console.log("importType", this.props.importType);
    // console.log(
    //   "inventoryImportTypeSelected",
    //   this.state.inventoryImportTypeSelected
    // );

    if (
      this.props.importType === "salesLegacyImport" &&
      this.state.errors.length > 0
    ) {
      for (let index = 0; index < this.state.errors.length; index++) {
        const element = this.state.errors[index];

        data.push({
          "INVOICE NAME": element[0],
          "INVOICE DATE": element[1],
          "CLIENT NAME": element[2],
          "CONTACT NO": element[3],
          ADDRESS: element[4],
          ADDRESS2: element[5],
          STATE: element[6],
          CITY: element[7],
          PINCODE: element[8],
          "ITEM CODE": element[9],
          "ITEM NAME": element[10],
          PRICE: element[11],
          QTY: element[12],
          SUBTOTAL: element[13],
          "DISCOUT PERCENT": element[14],
          "DISCOUNT AMOUNT": element[15],
          GRANDTOTAL: element[16],
          Status: element[17],
          Message: element[18],
        });
      }
      fileName = "SalesImport.csv";
    } else if (
      this.props.importType === "sales" &&
      this.state.errorSales.length > 0
    ) {
      for (let index = 0; index < this.state.errorSales.length; index++) {
        const element = this.state.errorSales[index];

        data.push({
          BarCode: element.BarCode,
          "Description of Goods": element["Description of Goods"],
          Batch: element.Batch,
          MRP: element.MRP,
          Qty: element.Qty,
          Size: element.Size,
          "Basic Rate": element["Basic Rate"],
          "Taxable Value": element["Taxable Value"],
          "SGST Rate": element["SGST Rate"],
          "SGST Amount": element["SGST Amount"],
          "CGST Rate": element["CGST Rate"],
          "CGST Amount": element["CGST Amount"],
          "IGST Rate": element["IGST Rate"],
          "IGST Amount": element["IGST Amount"],
          "Total Amount": element["Total Amount"],
          //Status: element.Status,
          Message: element.Message,
        });
      }
      fileName = "LineItems.csv";
    } else if (
      this.props.importType === "inventoryQty" &&
      this.state.inventoryImportTypeSelected !== "inventoryUniqueNo" &&
      this.state.inventoryImportTypeSelected !== "inventoryBatches" &&
      this.state.inventoryError.length > 0
    ) {
      for (let index = 0; index < this.state.inventoryError.length; index++) {
        const element = this.state.inventoryError[index];
        data.push({
          ExcelRowNo: element["Excel Row No"],
          SKU: element.SKU,
          Qty: element.Qty,
          Notes: element.Notes,
          Message: element.Message,
        });
      }
      fileName = "InventoryImport.csv";
    } else if (
      (this.props.importType === "inventoryPrice" ||
        this.props.importType === "inventoryQty") &&
      this.state.inventoryImportTypeSelected === "inventoryUniqueNo" &&
      this.state.inventoryError.length > 0
    ) {
      // console.log("inventoryUniqueNo - error", this.state.inventoryError);
      for (let index = 0; index < this.state.inventoryError.length; index++) {
        const element = this.state.inventoryError[index];
        data.push({
          ExcelRowNo: element["Excel Row No"],
          SKU: element.SKU,
          UniqueNo1: element.UniqueNo1,
          UniqueNo2: element.UniqueNo2,
          UniqueNo3: element.UniqueNo3,
          UniqueNo4: element.UniqueNo4,
          Message: element.Message,
        });
      }
      fileName = "InventoryUniqueNoImport.csv";
    } else if (
      (this.props.importType === "inventoryPrice" ||
        this.props.importType === "inventoryQty") &&
      this.state.inventoryImportTypeSelected === "inventoryBatches" &&
      this.state.inventoryError.length > 0
    ) {
      for (let index = 0; index < this.state.inventoryError.length; index++) {
        const element = this.state.inventoryError[index];
        data.push(element);
      }
      fileName = "InventoryBatchImport.csv";
    } else if (
      this.props.importType === "inventoryPrice" &&
      this.state.inventoryImportTypeSelected !== "inventoryUniqueNo" &&
      this.state.inventoryImportTypeSelected !== "inventoryBatches" &&
      this.state.inventoryError.length > 0
    ) {
      for (let index = 0; index < this.state.inventoryError.length; index++) {
        const element = this.state.inventoryError[index];
        data.push({
          ExcelRowNo: element["Excel Row No"],
          SKU: element.SKU,
          "Supply Price": element["Supply Price"],
          Qty: element.Qty,
          "Discount Amount": element["Discount Amount"],
          HSNSACCode: element.HSNSACCode,
          "Tax Inclusive": element["Tax Inclusive"],
          "Tax Group": element["Tax Group"],
          Notes: element.Notes,
          Message: element.Message,
        });
      }
      fileName = "InventoryImport.csv";
    } else if (
      this.props.importType === "membershipDiscount" &&
      this.state.errorMemberships.length > 0
    ) {
      for (let index = 0; index < this.state.logs.length; index++) {
        const element = this.state.logs[index];

        data.push({
          SKU: element.SKU,
          "Discount Perc": element.DiscountPercentage,
          "Discount Amt": element.DiscountAmount,
          Message: element.Message,
        });
      }
      fileName = "Products.csv";
    } else if (
      this.props.importType === "multipleclientcouponcodes" &&
      this.state.errorClientCodes.length > 0
    ) {
      for (let index = 0; index < this.state.errorClientCodes.length; index++) {
        const element = this.state.errorClientCodes[index];

        data.push({
          CouponCode: element.CouponCode,
          MobileNo: element.MobileNo,
          Message: element.Message,
        });
      }
      fileName = "CouponCodes.csv";
    } else if (
      this.props.importType === "vendorproducts" &&
      this.state.errors.length > 0
    ) {
      for (let index = 0; index < this.state.errors.length; index++) {
        data.push(this.state.errors[index]);
      }
      fileName = "VendorProductsImportError.csv";
    }
    const csvData = constatnts.objToCSV(data);
    this.setState({ exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  renderProductTag() {
    return (
      <React.Fragment>
        <div className="info">
          <div className="info-header">Info</div>
          <div className="info-text">
            - Click here to download import template.{" "}
            <a href={this.state.importTemplate}>Import Template</a>
          </div>
        </div>
        <CSVReader
          cssClass="csv-input"
          label="Select .csv file"
          onFileLoaded={this.onFileLoaded}
          inputId="ObiWan"
        />
      </React.Fragment>
    );
  }

  renderOthers() {
    return (
      <div>
        {!this.props.showWindow && (
          <h3 style={{ marginTop: "0px" }}>{this.returnWindowTitle()}</h3>
        )}

        {this.state.showFileUpload && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="info">
                  <div className="info-header">Info</div>
                  {this.props.importType.trim() !== "inventoryPrice" &&
                    this.props.importType.trim() !== "inventoryQty" &&
                    this.props.importType !== "producttags" &&
                    this.props.importType.trim() !== "salesLegacyImport" && (
                      <div className="info-text">
                        - Maximum of 2000 rows allowed per file upload.
                      </div>
                    )}
                  {(this.props.importType.trim() === "inventoryPrice" ||
                    this.props.importType.trim() === "inventoryQty" ||
                    this.props.importType.trim() === "salesLegacyImport") && (
                    <div className="info-text">
                      - Maximum of 500 rows allowed per file upload.
                    </div>
                  )}
                  {this.props.importType.trim() !== "salesLegacyImport" && (
                    <React.Fragment>
                      {" "}
                      <div className="info-text">
                        - Click here to download import template.{" "}
                        <a href={this.state.importTemplate}>Import Template</a>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
            {this.props.importType.trim() === "salesLegacyImport" && (
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Please select transaction type</label>
                    <select
                      className="form-control"
                      value={
                        this.state.transactionType
                          ? this.state.transactionType.key
                          : ""
                      }
                      onChange={(evt) => {
                        this.setState({
                          transactionType: this.props.config.TransactionTypes.filter(
                            (e) => e.key === evt.target.value
                          )[0],
                        });
                      }}
                    >
                      <option value="">Select transaction type</option>
                      {this.props.config.TransactionTypes.filter(
                        (e) => e.Module === "sales" && !e.IsDeleted
                      ).map((e, index) => (
                        <option value={e.key}>{e.TransactionType}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Please select store</label>
                    <select
                      className="form-control"
                      value={this.state.store ? this.state.store.key : ""}
                      onChange={(evt) =>
                        this.onStoreSelection(evt.target.value)
                      }
                    >
                      <option value="">Select Store</option>
                      {this.state.stores.map((store) => (
                        <option value={store.key}>{store.StoreName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Please select cash register</label>
                    <select
                      className="form-control"
                      value={
                        this.state.cashRegister
                          ? this.state.cashRegister.key
                          : ""
                      }
                      onChange={(evt) =>
                        this.onCashRegisterSelection(evt.target.value)
                      }
                    >
                      <option value="">Select Cash Register</option>
                      {this.state.cashRegisters.map((cashRegister) => (
                        <option value={cashRegister.key}>
                          {cashRegister.CashRegisterName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            {(this.props.importType.trim() === "inventoryPrice" ||
              this.props.importType.trim() === "inventoryQty") && (
              <div className="row">
                <div className="col-md-12">
                  <select
                    className="form-control"
                    value={this.state.categoryID}
                    onChange={(e) =>
                      this.onInventoryImportChange(e.target.value)
                    }
                  >
                    <option value={this.props.importType}>
                      Inventory Import
                    </option>
                    {/* {(this.props.inventoryInWardOrOutward && this.props.inventoryInWardOrOutward.toLowerCase() === "inward") &&
                                <React.Fragment> */}
                    {this.props.showUniqueNoImport && (
                      <option value="inventoryUniqueNo">
                        Unique No Import
                      </option>
                    )}
                    {this.props.showBatchImport && (
                      <option value="inventoryBatches">Batch Import</option>
                    )}
                    {/* </React.Fragment>
                            } */}
                  </select>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-12">
                <CSVReader
                  cssClass="csv-input"
                  label="Select .csv file"
                  onFileLoaded={this.onFileLoaded}
                  inputId="ObiWan"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.loading && (
          <React.Fragment>
            <div>
              <h3>{this.state.importStatus}</h3>
            </div>
            <div>
              <Loader show={this.state.loading} />{" "}
            </div>
            <div>Please wait for processing to complete.</div>
          </React.Fragment>
        )}
        {(this.state.errorCounter > 0 || this.state.processing) && (
          <div className="box-body">
            <b> Total Count : {this.state.totalCounter}</b>
            <br />
            <b> Success Count : {this.state.successCounter}</b>
            <br />
            <b> Error Count : {this.state.errorCounter}</b>
          </div>
        )}
        {(this.state.errors.length > 0 ||
          this.state.errorSales.length > 0 ||
          this.state.inventoryError.length > 0 ||
          this.state.errorMemberships.length > 0) && (
          <button
            className="btn btn-default btn-flat btn-md pull-left"
            onClick={this.onExportClick}
          >
            Download Error File <i className="fa fa-download"></i>
          </button>
        )}
        <br />
        <br />
        {!this.props.showWindow && (
          <div className="box-footer">
            <button
              type="submit"
              className="btn btn-flat btn-md btn-flat btn-default pull-left"
              onClick={this.props.onClose}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    );
  }

  returnWindowTitle() {
    if (this.props.importType == "vendorproducts") {
      return "Import Vendor Product(s)";
    } else if (this.props.importType == "membership") {
      return "Import Product(s)";
    } else if (this.props.importType == "sales") {
      return "Import Product(s)";
    } else if (
      this.props.importType.trim() === "inventoryPrice" ||
      this.props.importType.trim() === "inventoryQty"
    ) {
      return "Import Product(s)";
    } else if (this.props.importType == "saleschannel") {
      return "Import Product(s)";
    }
  }

  renderSalesChannel() {
    return (
      <React.Fragment>
        <div className="info">
          <div className="info-header">Info</div>
          <div className="info-text">
            - Click here to download import template.
            <a
              onClick={() => {
                const csvData = constatnts.objToCSV([
                  {
                    SKU: "",
                    "Markup Percentage": "",
                    "Markup Amount": "",
                    "Retail Price": "",
                  },
                ]);
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "Sales_Channel_Template.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }}
            >
              Import Template
            </a>
          </div>
        </div>
        <CSVReader
          cssClass="csv-input"
          label="Select .csv file"
          onFileLoaded={this.onFileLoaded}
          inputId="ObiWan"
        />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.showWindow && (
          <React.Fragment>
            {this.props.importType === "producttags" && this.renderProductTag()}
            {this.props.importType === "saleschannel" &&
              this.renderSalesChannel()}
            {(this.props.importType === "membership" ||
              this.props.importType === "sales" ||
              this.props.importType === "inventoryQty" ||
              this.props.importType === "inventoryPrice" ||
              this.props.importType === "salesLegacyImport" ||
              this.props.importType === "multipleclientcouponcodes" ||
              this.props.importType === "employeeservices" ||
              this.props.importType === "vendorproducts" ||
              this.props.importType === "multiplecouponcodes") &&
              this.renderOthers()}
          </React.Fragment>
        )}

        {this.props.showWindow && (
          <Window
            title={this.returnWindowTitle()}
            onClose={() => {
              this.props.onClose();
            }}
            //   buttons
          >
            {this.props.importType === "producttags" && this.renderProductTag()}
            {this.props.importType === "saleschannel" &&
              this.renderSalesChannel()}
            {(this.props.importType === "membership" ||
              this.props.importType === "sales" ||
              this.props.importType === "inventoryQty" ||
              this.props.importType === "inventoryPrice" ||
              this.props.importType === "salesLegacyImport" ||
              this.props.importType === "multipleclientcouponcodes" ||
              this.props.importType === "employeeservices" ||
              this.props.importType === "vendorproducts" ||
              this.props.importType === "multiplecouponcodes") &&
              this.renderOthers()}
          </Window>
        )}
      </React.Fragment>
    );
  }
}
export default ImportMaster;
