import React, { Component } from "react";

class WidgetTopProducts extends Component {
  constructor({ stores, fromDate, toDate, widget }) {
    super();
    this.state = {
      stores: stores,
      fromDate: fromDate,
      toDate: toDate,
      widget: widget,
      products: [],
    };
  }

  async componentDidMount() {
    this.loadData(
      this.state.stores,
      this.state.fromDate,
      this.state.toDate,
      this.state.widget
    );
  }

  loadData(stores, fromDate, toDate, widget) {
    this.props
      .getProductWiseSales(
        this.props.user.user.RegistrationID,
        stores,
        "",
        fromDate,
        toDate,
        widget.WidgetOptionTopX,
        "qty"
      )
      .then((result) => {
        this.setState({
          products: result.searchResult,
          totalItemsCount: result.totalItemsCount,
        });
      });
  }

  componentWillReceiveProps({ stores, fromDate, toDate, widget }) {
    this.loadData(stores, fromDate, toDate, widget);
  }

  render() {
    return (
      <div className="dashboard-box">
        <div className="dashboard-box-header">
          <div className="dashboard-box-title left">Product</div>
          <div className="right">
            <button
              type="submit"
              className="btn pull-right btn-flat btn-default"
              onClick={() =>
                this.props.history.push({
                  pathname: "/home/reports/itemsolddetail",
                  state: {
                    action: "Sales Dashboard",
                    searchTransactionFromDate: this.state.fromDate,
                    searchTransactionToDate: this.state.toDate,
                    storeList: this.state.stores,
                  },
                })
              }
            >
              View More
            </button>
          </div>
        </div>
        <div className="dashboard-box-body">
          <div className="table-responsive scroll2">
            {this.state.products.length > 0 && (
              <table className="table  table-striped no-margin">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th style={{ textAlign: "right" }}>Qty</th>
                    <th style={{ textAlign: "right" }}>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((product, index) => (
                    <tr key={"prds_" + index}>
                      <td>{product.name}</td>
                      <td style={{ textAlign: "right" }}>
                        {product.total_qty}
                      </td>
                      <td style={{ textAlign: "right" }}>
                        {product.total_sales}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {this.state.products.length === 0 && "No records found"}
          </div>
        </div>
      </div>
    );
  }
}

export default WidgetTopProducts;
