import React, { Component } from "react";
import "./WidgetPincodeProductDashboard.css";
import axios from "axios";
class WidgetPincodeProductDashboard extends Component {
  constructor({ stores }) {
    super();
    this.state = {
      stores: stores,
      result: [],
    };
  }

  async componentDidMount() {
    // console.log("WidgetPincodeProductDashboard componentDidMount");
    this.loadData(this.state.stores, this.state.fromDate, this.state.toDate);
  }

  async loadData(stores) {
    // console.log("stores", stores);
    let storeids = [];
    stores.map((s) => {
      if (s.key !== "") {
        storeids.push(s.key);
      }
    });
    // console.log(storeids);
    // let result = await getPincodeProductDashboard(storeids);

    let result = await axios.post(
      "https://backend-197598313260.asia-south1.run.app/v1/reports/pincode/products/dashboard",
      {
        storeIds: storeids,
      },
      {
        headers: {
          "Content-Type": "application/json", // Explicitly set the content type
        },
      }
    );
    // console.log("WidgetPincodeProductDashboard result", result);
    let tmp = [];
    if (
      result &&
      result.data &&
      result.data.result &&
      result.data.result.length > 0
    ) {
      tmp = result.data.result;
    }
    this.setState({ result: tmp });
  }

  componentWillReceiveProps({ stores }) {
    // console.log("WidgetPincodeProductDashboard componentWillReceiveProps");

    this.loadData(stores);
  }

  render() {
    return (
      <div className="WidgetPincodeProductDashboard-box ">
        <div className="WidgetPincodeProductDashboard-box-header">
          <div className="WidgetPincodeProductDashboard-box-title left">
            Product Funnel
          </div>
        </div>
        <div className="WidgetPincodeProductDashboard-box-body">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Store</th>
                <th>Total Items with Inventory Activity</th>
                <th>Found in GC</th>
                <th>Reported Items</th>
                <th>Active PSINs</th>
                <th>In stock</th>
              </tr>
            </thead>
            <tbody>
              {this.state.result.map((m) => (
                <tr>
                  <td>{m.StoreName}</td>
                  <td className="align-right">{m.TotalInventoryCount}</td>
                  <td className="align-right">{m.TotalGcCount}</td>
                  <td className="align-right">{m.IssueReportedCount}</td>
                  <td className="align-right">{m.ActivePsinCount}</td>
                  <td className="align-right">
                    {m.InstockGreaterThanZeroCount}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default WidgetPincodeProductDashboard;
