import React, { Component } from "react";
import WidgetTotalSales from "./Widgets/WidgetTotalSales";
import WidgetTotalCreditSales from "./Widgets/WidgetTotalCreditSales";
import WidgetTotalTransactions from "./Widgets/WidgetTotalTransactions";
import WidgetTotalCustomers from "./Widgets/WidgetTotalCustomers";
import WidgetTotalExpenses from "./Widgets/WidgetTotalExpenses";
import WidgetTotalStoresSales from "./Widgets/WidgetTotalStoresSales";
import WidgetPaymentDashboard from "./Widgets/WidgetPaymentDashboard";
import WidgetLast12MonthsSales from "./Widgets/WidgetLast12MonthsSales";
import WidgetQuarterSales from "./Widgets/WidgetQuarterSales";
import WidgetTopBrands from "./Widgets/WidgetTopBrands";
import WidgetTopProducts from "./Widgets/WidgetTopProducts";
import WidgetTopCategories from "./Widgets/WidgetTopCategories";
import WidgetTopSubCategories from "./Widgets/WidgetTopSubCategories";
import WidgetTopProductTypes from "./Widgets/WidgetTopProductTypes";
import WidgetTopEmployees from "./Widgets/WidgetTopEmployees";
import WidgetTopProductType from "./Widgets/WidgetTopProductType";
import WidgetCustomerAnalysis from "./Widgets/WidgetCustomerAnalysis";
import WidgetSpecificPaymentSales from "./Widgets/WidgetSpecificPaymentSales";
import WidgetTotalReceived from "./Widgets/WidgetTotalReceived";
import ImageButton from "../2/Controls/ImageButton";
import Input from "../2/Controls/Input";
import Button from "../2/Controls/Button";
import WidgetSalesVsPurchaseStoreWise from "./Widgets/WidgetSalesVsPurchaseStoreWise";
import WidgetLast12MonthsSalesVsPurchase from "./Widgets/WidgetLast12MonthsSalesVsPurchase";
import WidgetPincodeStoreoperatingParameters from "./Widgets/WidgetPincodeStoreoperatingParameters";
import PincodeInStockDashboard from "./PincodeInStockDashboard";
import { isMobileApp } from "../../actions/Util";
import WidgetPincodeDashboard from "./Widgets/WidgetPincodeDashboard";
import WidgetPincodeTopProducts from "./Widgets/WidgetPincodeTopProducts";
import WidgetPincodeProductDashboard from "./Widgets/WidgetPincodeProductDashboard";
///
class DashboardCtrl extends Component {
  constructor() {
    super();
    this.state = {
      widgets: [],
      type: "today",
      storeID: "",
      stores: [],
      storeList: [],
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchTransactionFromDateTmp: new Date().toISOString().split("T")[0],
      searchTransactionToDateTmp: new Date().toISOString().split("T")[0],
    };
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadWidgets = this.loadWidgets.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.getWidgets = this.getWidgets.bind(this);
    this.renderDashboardFilter = this.renderDashboardFilter.bind(this);
    this.renderDashboardFilterDesktop = this.renderDashboardFilterDesktop.bind(
      this
    );
    this.renderDashboardFilterMobile = this.renderDashboardFilterMobile.bind(
      this
    );
    this.onCustomSearch = this.onCustomSearch.bind(this);
  }

  async componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    await this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        if (stores.length > 1) {
          stores.unshift({ key: "", StoreCode: "All", StoreName: "Stores" });
        }
        stores.map((m) => {
          storeList.push({ key: m.key });
        });
        this.setState({ stores: stores });
      });
    this.setState({
      storeList: storeList,
      storeID: storeList.length > 1 ? "" : this.props.user.store.key,
    });
    this.getWidgets();
  }

  onStoreChange(value) {
    let storeList = [];
    if (value != "") {
      storeList.push({ key: value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    this.setState({ storeList: storeList, storeID: value });
  }

  onSearchClick(type) {
    // console.log('type',type)
    let searchTransactionFromDate = "";
    let searchTransactionToDate = "";

    if (type === "today") {
      searchTransactionFromDate = new Date().toISOString().split("T")[0];
      searchTransactionToDate = new Date().toISOString().split("T")[0];
    } else if (type === "yesterday") {
      let date = new Date();
      date.setDate(date.getDate() - 1);
      searchTransactionFromDate = new Date(date).toISOString().split("T")[0];
      searchTransactionToDate = new Date(date).toISOString().split("T")[0];
    } else if (type === "month") {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
      var firstDay = new Date(y, m, 1);
      var lastDay = new Date(y, m + 1, 0);

      let fromDate = new Date(firstDay).setHours(23, 59);
      searchTransactionFromDate = new Date(fromDate)
        .toISOString()
        .split("T")[0];

      let toDate = new Date(lastDay).setHours(23, 59);
      searchTransactionToDate = new Date(toDate).toISOString().split("T")[0];
    } else if (type === "year") {
      var date = new Date(),
        y = date.getFullYear();

      let fromDate = new Date(y, 0, 1).setHours(23, 59);
      searchTransactionFromDate = new Date(fromDate)
        .toISOString()
        .split("T")[0];

      let toDate = new Date(y, 11, 31).setHours(23, 59);
      searchTransactionToDate = new Date(toDate).toISOString().split("T")[0];
    }
    if (type !== "custom") {
      this.setState({
        searchTransactionFromDate: searchTransactionFromDate,
        searchTransactionToDate: searchTransactionToDate,
        type: type,
      });
    } else {
      this.setState({ type: type });
    }
  }

  onCustomSearch() {
    this.setState({
      searchTransactionFromDate: this.state.searchTransactionFromDateTmp,
      searchTransactionToDate: this.state.searchTransactionToDateTmp,
    });
  }

  async getWidgets() {
    let dashboardSetting = await this.props.getDashBoardSettings(
      this.props.user.user.RegistrationID
    );
    // console.log('dashboardSetting', dashboardSetting)
    if (dashboardSetting) {
      this.setState({
        key: dashboardSetting.key,
        widgets: dashboardSetting.Widgets,
      });
    } else {
      let widgets = [];
      if (this.props.config.WhiteLabel.Code === "PINCODE") {
        widgets.push({ Widget: "WidgetTotalSales" });
        widgets.push({ Widget: "WidgetTotalTransactions" });
        widgets.push({ Widget: "WidgetTotalCustomers" });
        widgets.push({ Widget: "WidgetTotalExpenses" });
        widgets.push({ Widget: "WidgetTotalStoresSales" });
        // widgets.push({Widget:"WidgetPaymentDashboard"});
        widgets.push({ Widget: "WidgetLast12MonthsSales" });
        widgets.push({ Widget: "WidgetQuarterSales" });
        // widgets.push({Widget:"WidgetLast12MonthsSalesVsPurchase"});
        // widgets.push({Widget:"WidgetSalesVsPurchaseStoreWise"});
        widgets.push({ Widget: "WidgetTopProducts" });
        widgets.push({ Widget: "WidgetTopCategories" });
        widgets.push({ Widget: "WidgetTopSubCategories" });
        widgets.push({ Widget: "WidgetTopBrands" });
        widgets.push({ Widget: "WidgetTopProductTypes" });
        // widgets.push({Widget:"WidgetTopEmployees"});
      }
      if (this.props.registration.registration.EnablePosBillingForPincode) {
        widgets.push({ Widget: "WidgetPincodeDashboard" });
        widgets.push({ Widget: "WidgetPincodeProductDashboard" });

        widgets.push({ Widget: "WidgetTotalStoresSales" });
        widgets.push({ Widget: "WidgetPaymentDashboard" });
        widgets.push({ Widget: "WidgetLast12MonthsSales" });
        widgets.push({ Widget: "WidgetPincodeTopProducts" });
      } else {
        widgets.push({ Widget: "WidgetTotalSales" });
        widgets.push({ Widget: "WidgetTotalTransactions" });
        widgets.push({ Widget: "WidgetTotalCustomers" });
        widgets.push({ Widget: "WidgetTotalExpenses" });

        // if (this.props.registration.registration.EnablePosBillingForPincode) {
        //   widgets.push({ Widget: "PincodeInStockDashboard" });
        // }

        widgets.push({ Widget: "WidgetTotalStoresSales" });
        widgets.push({ Widget: "WidgetPaymentDashboard" });
        widgets.push({ Widget: "WidgetLast12MonthsSales" });
        widgets.push({ Widget: "WidgetQuarterSales" });
        widgets.push({ Widget: "WidgetLast12MonthsSalesVsPurchase" });
        widgets.push({ Widget: "WidgetSalesVsPurchaseStoreWise" });
        widgets.push({ Widget: "WidgetTopProducts" });
        widgets.push({ Widget: "WidgetTopCategories" });
        widgets.push({ Widget: "WidgetTopSubCategories" });
        widgets.push({ Widget: "WidgetTopBrands" });
        widgets.push({ Widget: "WidgetTopProductTypes" });
        widgets.push({ Widget: "WidgetTopEmployees" });
      }

      this.setState({ key: "", widgets: widgets });
    }
  }

  loadWidgets(widget, index) {
    // console.log('widget',widget)
    switch (widget.Widget) {
      case "WidgetPincodeDashboard":
        return (
          <WidgetPincodeDashboard
            {...this.props}
            widget={widget}
            stores={this.state.storeList}
            accessibleStores={this.state.stores}
            fromDate={this.state.searchTransactionFromDate}
            toDate={this.state.searchTransactionToDate}
          />
        );
      case "WidgetPincodeTopProducts":
        return (
          // <div key={"widget_" + index} className="col-md-3">
          <WidgetPincodeTopProducts
            {...this.props}
            widget={widget}
            stores={this.state.storeList}
            fromDate={this.state.searchTransactionFromDate}
            toDate={this.state.searchTransactionToDate}
          />
          // </div>
        );
      case "WidgetPincodeProductDashboard":
        return (
          <div key={"loadWidgets_" + index} className="col-md-12">
            <WidgetPincodeProductDashboard
              {...this.props}
              stores={this.state.storeList}
            />
          </div>
        );
      case "PincodeInStockDashboard":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <PincodeInStockDashboard {...this.props} />
          </div>
        );
      case "WidgetLast12MonthsSalesVsPurchase":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetLast12MonthsSalesVsPurchase
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              accessibleStores={this.state.stores}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetSalesVsPurchaseStoreWise":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetSalesVsPurchaseStoreWise
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              accessibleStores={this.state.stores}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalStoresSales":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTotalStoresSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              accessibleStores={this.state.stores}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetPaymentDashboard":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetPaymentDashboard
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetLast12MonthsSales":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetLast12MonthsSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
            />
          </div>
        );
      case "WidgetQuarterSales":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetQuarterSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
            />
          </div>
        );
      case "WidgetTopProducts":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopProducts
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopCategories":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopCategories
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopSubCategories":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopSubCategories
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopBrands":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopBrands
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopProductTypes":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopProductTypes
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopEmployees":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopEmployees
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTopProductType":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetTopProductType
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetCustomerAnalysis":
        return (
          <div key={"loadWidgets_" + index} className="col-md-4">
            <WidgetCustomerAnalysis
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      // default:
      //     return <div></div>
    }
  }

  loadSummaryWidgets(widget, index) {
    // console.log('widget',widget)
    switch (widget.Widget) {
      case "WidgetTotalSales":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalCreditSales":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalCreditSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalTransactions":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalTransactions
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalCustomers":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalCustomers
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetSpecificPaymentSales":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetSpecificPaymentSales
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalReceived":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalReceived
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      case "WidgetTotalExpenses":
        return (
          <div key={"widget_" + index} className="col-md-3">
            <WidgetTotalExpenses
              {...this.props}
              widget={widget}
              stores={this.state.storeList}
              fromDate={this.state.searchTransactionFromDate}
              toDate={this.state.searchTransactionToDate}
            />
          </div>
        );
      default:
        return <div key={"widget_" + index}></div>;
    }
  }

  renderDashboardFilter() {
    // console.log('isMobileApp', isMobileApp())
    if (!isMobileApp()) {
      return this.renderDashboardFilterDesktop();
    } else {
      return this.renderDashboardFilterMobile();
    }
  }

  renderDashboardFilterDesktop() {
    return (
      <div className="dashboard-header">
        <div className="dashboard-header-title left-30">Dashboard</div>
        <div className="dashboard-header-options right-70">
          <div className="input-group pull-right">
            <Input
              type="select"
              value={this.state.storeID}
              onChange={(value) => this.onStoreChange(value)}
            >
              {this.state.stores
                .filter((e) => !e.IsDeleted)
                .map((store) => (
                  <option key={store.key} value={store.key}>
                    {store.StoreCode}-{store.StoreName}
                  </option>
                ))}
            </Input>
            <div className="input-group-btn">
              <button
                type="button"
                className={
                  this.state.type === "custom"
                    ? "btn button-selected button-flat button-border dropdown-toggle button-lg"
                    : "btn button-flat button-border dropdown-toggle button-lg"
                }
                data-toggle="dropdown"
              >
                Custom &nbsp;
                <span className="fa fa-calendar"></span>
              </button>
              <ul className="dropdown-menu">
                <li>
                  <input
                    type="date"
                    onChange={(evt) =>
                      this.setState({
                        searchTransactionFromDateTmp: evt.target.value,
                      })
                    }
                    value={this.state.searchTransactionFromDateTmp}
                  />
                  <br />
                  <input
                    type="date"
                    onChange={(evt) =>
                      this.setState({
                        searchTransactionToDateTmp: evt.target.value,
                      })
                    }
                    value={this.state.searchTransactionToDateTmp}
                  />
                  <br />
                  {/* <button type="button" className="btn button-selected button-border"
                            disabled={this.state.searchTransactionFromDate === '' || this.state.searchTransactionToDate === ''}
                            onClick={this.onCustomSearch}>Search</button> */}
                  <Button
                    type="primary"
                    text="Search"
                    disabled={
                      this.state.searchTransactionFromDateTmp === "" ||
                      this.state.searchTransactionToDateTmp === ""
                    }
                    onClick={this.onCustomSearch}
                  />
                </li>
              </ul>
              <button
                type="button"
                className={
                  this.state.type === "year"
                    ? "btn button-selected button-flat button-border button-lg"
                    : "btn button-flat button-border button-lg"
                }
                onClick={() => this.onSearchClick("year")}
              >
                Year
              </button>
              <button
                type="button"
                className={
                  this.state.type === "month"
                    ? "btn button-selected button-flat button-border button-lg"
                    : "btn button-flat button-border button-lg"
                }
                onClick={() => this.onSearchClick("month")}
              >
                Month
              </button>
              <button
                type="button"
                className={
                  this.state.type === "yesterday"
                    ? "btn button-selected button-flat button-border button-lg"
                    : "btn button-flat button-border button-lg"
                }
                onClick={() => this.onSearchClick("yesterday")}
              >
                Yesterday
              </button>
              <button
                type="button"
                className={
                  this.state.type === "today"
                    ? "btn button-selected  button-border button-right-rounded button-lg"
                    : "btn button-border button-right-rounded button-lg"
                }
                onClick={() => this.onSearchClick("today")}
              >
                Today
              </button>
            </div>
          </div>
          {this.props.registration.registration.hasOwnProperty(
            "NewDashboard"
          ) &&
            this.props.registration.registration.NewDashboard && (
              <ImageButton
                type="setup"
                onClick={() =>
                  this.props.history.push("/home/reports/dashboardsettings")
                }
              />
            )}
        </div>
      </div>
    );
  }

  renderDashboardFilterMobile() {
    return (
      <div>
        <div className="dashboard-header-title">Dashboard</div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <Input
                type="select"
                value={this.state.storeID}
                onChange={(value) => this.onStoreChange(value)}
              >
                {this.state.stores
                  .filter((e) => !e.IsDeleted)
                  .map((store) => (
                    <option value={store.key}>
                      {store.StoreCode}-{store.StoreName}
                    </option>
                  ))}
              </Input>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                type="select"
                value={this.state.type}
                onChange={(value) => this.onSearchClick(value)}
              >
                <option value="custom">Custom</option>
                <option value="year">Year</option>
                <option value="month">Month</option>
                <option value="yesterday">Yesterday</option>
                <option value="today">Today</option>
              </Input>
            </div>
          </div>
          {this.state.type === "custom" && (
            <div className="row">
              <div className="col-xs-6">
                <Input
                  type="date"
                  onChange={(value) =>
                    this.setState({ searchTransactionFromDateTmp: value })
                  }
                  value={this.state.searchTransactionFromDateTmp}
                />
              </div>
              <div className="col-xs-6">
                <Input
                  type="date"
                  onChange={(value) =>
                    this.setState({ searchTransactionToDateTmp: value })
                  }
                  value={this.state.searchTransactionToDateTmp}
                />
              </div>
              <div className="col-xs-12">
                <Button
                  type="primary"
                  text="Search"
                  disabled={
                    this.state.searchTransactionFromDateTmp === "" ||
                    this.state.searchTransactionToDateTmp === ""
                  }
                  onClick={this.onCustomSearch}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderDashboardFilter()}
        {/* <div className="dashboard-summary"> */}
        {this.props.user &&
          this.props.user.store &&
          this.props.user.store.PincodeSelectionScore && (
            <WidgetPincodeStoreoperatingParameters {...this.props} />
          )}
        <div className="row">
          {this.state.widgets.map((m, index) =>
            this.loadSummaryWidgets(m, index)
          )}
        </div>
        {/* <div className="dashboard-details"> */}
        <div className="row">
          {this.state.widgets.map((m, index) => this.loadWidgets(m, index))}
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardCtrl;
