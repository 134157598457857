import React, { Component } from "react";

class WidgetPincodeTopProducts extends Component {
  constructor({ stores, fromDate, toDate, widget }) {
    super();
    this.state = {
      stores: stores,
      fromDate: fromDate,
      toDate: toDate,
      widget: widget,
      onlineProducts: [],
      offlineProducts: [],
      onlineCategories: [],
      offlineCategories: [],
      onlineBrands: [],
      offlineBrands: [],
    };
  }

  async componentDidMount() {
    this.loadData(
      this.state.stores,
      this.state.fromDate,
      this.state.toDate,
      this.state.widget
    );
  }

  async loadData(stores, fromDate, toDate, widget) {
    let onlineTransactiontypes = [];
    let offlineTransactionTypes = [];

    let salesTransactionTypes = this.props.config.TransactionTypes.filter(
      (f) => f.Module === "sales" && !f.IsDeleted
    );

    salesTransactionTypes.map((i) => {
      if (
        this.props.registration.registration.hasOwnProperty(
          "PinocdeOrderTransactionTypeID"
        ) &&
        this.props.registration.registration.PinocdeOrderTransactionTypeID ===
          i.key
      ) {
        onlineTransactiontypes.push({ key: i.key });
      } else {
        offlineTransactionTypes.push({ key: i.key });
      }
    });

    let resultOnline = await this.props.getProductTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      "",
      fromDate,
      toDate,
      50,
      "Qty",
      onlineTransactiontypes
    );
    //   .then((result) => {

    //   });

    let resultOffline = await this.props.getProductTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      "",
      fromDate,
      toDate,
      50,
      "Qty",
      offlineTransactionTypes
    );
    // this.setState({
    //   onlineProducts: resultOnline.searchResult,
    //   offlineProducts: resultOffline.searchResult,
    // });

    let resultOnline1 = await this.props.getCategoryTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      50,
      "Qty",
      onlineTransactiontypes
    );
    //   .then((result) => {

    //   });

    let resultOffline1 = await this.props.getCategoryTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      50,
      "Qty",
      offlineTransactionTypes
    );

    let resultOnlineBrands = await this.props.getBrandTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      50,
      "Qty",
      onlineTransactiontypes
    );

    let resultOfflineBrands = await this.props.getBrandTransactionTypeWiseSales(
      this.props.user.user.RegistrationID,
      stores,
      fromDate,
      toDate,
      50,
      "Qty",
      offlineTransactionTypes
    );
    // .then((result) => {
    //     this.setState({ brands: result.searchResult, totalItemsCount: result.totalItemsCount })
    // });

    this.setState({
      onlineProducts: resultOnline.searchResult,
      offlineProducts: resultOffline.searchResult,
      onlineCategories: resultOnline1.searchResult,
      offlineCategories: resultOffline1.searchResult,
      onlineBrands: resultOnlineBrands.searchResult,
      offlineBrands: resultOfflineBrands.searchResult,
    });
  }

  componentWillReceiveProps({ stores, fromDate, toDate, widget }) {
    this.loadData(stores, fromDate, toDate, widget);
  }

  render() {
    return (
      <>
        {/* <div className="row"> */}
        <div className="col-md-4">
          <div className="dashboard-box">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Product (Online)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.onlineProducts.length > 0 && (
                  <table className="table  table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.onlineProducts.map((product, index) => (
                        <tr key={"prds_" + index}>
                          <td>{product.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {product.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {product.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.onlineProducts.length === 0 && "No records found"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-box ">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Category (Online)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.onlineCategories.length > 0 && (
                  <table className="table table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.onlineCategories.map((category, index) => (
                        <tr key={"cat_" + index}>
                          <td>{category.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {category.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {category.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.onlineCategories.length === 0 && "No records found"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-box">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Brand (Online)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.onlineBrands.length > 0 && (
                  <table className="table table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.onlineBrands.map((brand, index) => (
                        <tr key={"brands_" + index}>
                          <td>{brand.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {brand.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {brand.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.onlineBrands.length === 0 && "No records found"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-box">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Product (Offline)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.offlineProducts.length > 0 && (
                  <table className="table  table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.offlineProducts.map((product, index) => (
                        <tr key={"prds_" + index}>
                          <td>{product.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {product.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {product.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.offlineProducts.length === 0 && "No records found"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-box ">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Category (Offline)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.offlineCategories.length > 0 && (
                  <table className="table table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Sales</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.offlineCategories.map((category, index) => (
                        <tr key={"cat_" + index}>
                          <td>{category.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {category.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {category.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.offlineCategories.length === 0 &&
                  "No records found"}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-box">
            <div className="dashboard-box-header">
              <div className="dashboard-box-title left">Brand (Offline)</div>
              <div className="right"></div>
            </div>
            <div className="dashboard-box-body">
              <div className="table-responsive scroll2">
                {this.state.offlineBrands.length > 0 && (
                  <table className="table table-striped no-margin">
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th style={{ textAlign: "right" }}>Qty</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.offlineBrands.map((brand, index) => (
                        <tr key={"brands_" + index}>
                          <td>{brand.name}</td>
                          <td style={{ textAlign: "right" }}>
                            {brand.total_qty}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {brand.total_sales}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {this.state.offlineBrands.length === 0 && "No records found"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WidgetPincodeTopProducts;
