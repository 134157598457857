import React, { Component } from "react";
import { isNullOrUndefined } from "util";
import Spinner from "../Common/Spinner";
import ImageButton from "../2/Controls/ImageButton";
import { isMobileApp } from "../../actions/Util";
import {
  getPincodeCategoryForRegistration,
  getPincodeBrandForRegistration,
  getPincodeProducts,
} from "../../services/api/PincodeAPI";
import Input from "../2/Controls/Input";
import "./PincodeProductQuickEdit.css";

class PincodeProductQuickEdit extends Component {
  constructor() {
    super();
    this.state = {
      searchText: "",
      itemsCountPerPage: 50,
      searchProduct: "",
      searchProductType: "",
      searchCategory: "",
      categoryID: "",
      searchBrand: "",
      searchSubCategory: "",
      subCategoryID: "",
      products: [],
      outOfStock: false,
      recordFrom: 0,
      totalItemsCount: 0,
      activePage: 1,
      categories: [],
      searchCategoryTA: [],
      searchBrandTA: [],
      loading: true,
      outOfStockManual: false,
      outOfStockCancellation: false,
      outOfStockOption: "",
    };
    this.loadProducts = this.loadProducts.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onSubCategoryChange = this.onSubCategoryChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.onFirstClick = this.onFirstClick.bind(this);
    this.onLastClick = this.onLastClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.onBrandChange = this.onBrandChange.bind(this);
    this.onClearSearch = this.onClearSearch.bind(this);
  }

  async componentDidMount() {
    let categories = await getPincodeCategoryForRegistration(
      this.props.registration.registration.key
    );
    let brands = await getPincodeBrandForRegistration(
      this.props.registration.registration.key
    );

    this.setState({ categories: categories, brands: brands });

    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchBrand,
      "",
      "",
      "",
      this.state.outOfStockManual,
      this.state.outOfStockCancellation
    );
  }

  onFirstClick() {
    let pageNumber = 1;
    let recordFrom = 0;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchBrand,
      "",
      "",
      "",
      this.state.outOfStockManual,
      this.state.outOfStockCancellation
    );
  }

  onPreviousClick() {
    if (this.state.activePage > 0) {
      let pageNo = Number(this.state.activePage) - 1;
      console.log("pageNo", pageNo);
      let recordFrom = pageNo * this.state.itemsCountPerPage;
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadProducts(
        recordFrom,
        "active",
        this.state.searchText,
        this.state.searchProductType,
        this.state.searchCategory,
        this.state.searchSubCategory,
        this.state.searchBrand,
        "",
        "",
        "",
        this.state.outOfStockManual,
        this.state.outOfStockCancellation
      );
    }
  }

  onNextClick() {
    let pageNo = Number(this.state.activePage) + 1;
    let recordFrom = pageNo * this.state.itemsCountPerPage;
    if (recordFrom < this.state.totalItemsCount) {
      this.setState({ activePage: pageNo, recordFrom: recordFrom });
      this.loadProducts(
        recordFrom,
        "active",
        this.state.searchText,
        this.state.searchProductType,
        this.state.searchCategory,
        this.state.searchSubCategory,
        this.state.searchBrand,
        "",
        "",
        "",
        this.state.outOfStockManual,
        this.state.outOfStockCancellation
      );
    }
  }

  onLastClick() {
    let pageNo = Number(
      Number(
        Number(this.state.totalItemsCount) /
          Number(this.state.itemsCountPerPage)
      ).toFixed(0)
    );
    // console.log("pageNo", pageNo);

    let recordFrom = Number(pageNo) * Number(this.state.itemsCountPerPage);
    this.setState({ activePage: pageNo, recordFrom: recordFrom });
    this.loadProducts(
      recordFrom,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchBrand,
      "",
      "",
      "",
      this.state.outOfStockManual,
      this.state.outOfStockCancellation
    );
  }

  onCategoryChange = (value) => {
    let searchCategoryTA = value;
    let searchCategory = value && value.length > 0 ? value[0].Name : "";
    this.setState({
      searchCategoryTA: searchCategoryTA,
      searchCategory: searchCategory,
    });
  };

  onBrandChange = (value) => {
    let searchBrandTA = value;
    let searchBrand = value && value.length > 0 ? value[0].Name : "";
    this.setState({
      searchBrandTA: searchBrandTA,
      searchBrand: searchBrand,
    });
  };

  onSubCategoryChange = (e) => {
    let subCategoryID = e.target.value;
    let searchSubCategory = e.target.options[e.target.selectedIndex].text;
    this.setState({
      searchSubCategory: searchSubCategory,
      subCategoryID: subCategoryID,
    });
  };

  async loadProducts(
    from,
    option,
    searchText,
    searchProductType,
    searchCategory,
    searchSubCategory,
    searchBrand,
    searchTags,
    searchDescription,
    searchHSNSAC,
    outOfStockManual,
    outOfStockCancellation
  ) {
    this.setState({ loading: true });

    let result = await getPincodeProducts(
      from,
      this.state.itemsCountPerPage,
      this.props.user.user.RegistrationID,
      this.props.user.store.key,
      searchText,
      searchCategory,
      searchBrand,
      outOfStockManual,
      outOfStockCancellation
    );

    // console.log("result.searchResult", result.searchResult);
    let products = [];
    let productIDs = [];
    result.searchResult.map((data) => {
      // console.log(JSON.stringify(data))
      let product = {
        Images: data.Images,
        ProductType: data.ProductType,
        SKU: data.SKU,
        BarCode: !isNullOrUndefined(data.BarCode) ? data.BarCode : "",
        Brand: !isNullOrUndefined(data.Brand) ? data.Brand : "",
        Category: data.Category,
        SubCategory: !isNullOrUndefined(data.SubCategory)
          ? data.SubCategory
          : "",
        Name: data.Name,
        Description: !isNullOrUndefined(data.Description)
          ? data.Description
          : "",
        SupplyPrice: !isNullOrUndefined(data.SupplyPrice)
          ? data.SupplyPrice
          : "",
        RetailPrice: !isNullOrUndefined(data.RetailPrice)
          ? data.RetailPrice
          : "",
        MRP: !isNullOrUndefined(data.MRP) ? data.MRP : "",
        key: data.key,
        Variants: data.hasOwnProperty("Variants") ? data.Variants : [],
        InventoryType: data.hasOwnProperty("InventoryType")
          ? data.InventoryType
          : "",
        OMNIEnabled: data.hasOwnProperty("OMNIEnabled")
          ? data.OMNIEnabled
          : false,
        OutOfStock: data.hasOwnProperty("OutOfStock") ? data.OutOfStock : false,
        ParentID: "",
        StoreSpecificPrices: data.StoreSpecificPrices,
        StoreSpecificPricing: data.StoreSpecificPricing,
        AccessibleToSelectedStores: data.AccessibleToSelectedStores,
      };

      let storePrice = data.StoreSpecificPrices.filter(
        (f) => f.StoreID === this.props.user.store.key
      );

      if (storePrice.length > 0) {
        product.SupplyPrice = storePrice[0].SupplyPrice;
        product.Markup = storePrice[0].Markup;
        product.RetailPrice = storePrice[0].RetailPrice;
        product.MRP = storePrice[0].MRP;
        product.OMNIEnabled = storePrice[0].hasOwnProperty("OMNIEnabled")
          ? storePrice[0].OMNIEnabled
          : product.OMNIEnabled;
        product.OutOfStock = storePrice[0].hasOwnProperty("OutOfStock")
          ? storePrice[0].OutOfStock
          : product.OutOfStock;
      }
      if (outOfStockManual || outOfStockCancellation) {
        if (product.OutOfStock) {
          products.push(product);
          productIDs.push(product.key);
        }
      } else {
        products.push(product);
        productIDs.push(product.key);
      }

      // if (product.Variants.length > 0) {
      //   for (let i = 0; i < product.Variants.length; i++) {
      //     let variant = product.Variants[i];
      //     // console.log("variant", variant);
      //     if (variant.IsDeleted) {
      //       continue;
      //     }
      //     let variantProduct = {
      //       ProductType: data.ProductType,
      //       SKU: variant.SKU,
      //       BarCode: !isNullOrUndefined(data.BarCode) ? data.BarCode : "",
      //       Brand: !isNullOrUndefined(data.Brand) ? data.Brand : "",
      //       Category: data.Category,
      //       SubCategory: !isNullOrUndefined(data.SubCategory)
      //         ? data.SubCategory
      //         : "",
      //       Name: data.Name + " / " + variant.Name,
      //       Description: !isNullOrUndefined(data.Description)
      //         ? data.Description
      //         : "",
      //       Type: data.ProductType,
      //       SupplyPrice: !isNullOrUndefined(variant.SupplyPrice)
      //         ? variant.SupplyPrice
      //         : "",
      //       RetailPrice: !isNullOrUndefined(variant.RetailPrice)
      //         ? variant.RetailPrice
      //         : "",
      //       MRP: !isNullOrUndefined(variant.MRP) ? variant.MRP : "",
      //       Variants: [],
      //       InventoryType: data.hasOwnProperty("InventoryType")
      //         ? data.InventoryType
      //         : "",
      //       OMNIEnabled: variant.hasOwnProperty("OMNIEnabled")
      //         ? variant.OMNIEnabled
      //         : data.hasOwnProperty("OMNIEnabled")
      //         ? data.OMNIEnabled
      //         : false,
      //       OutOfStock: variant.hasOwnProperty("OutOfStock")
      //         ? variant.OutOfStock
      //         : data.hasOwnProperty("OutOfStock")
      //         ? data.OutOfStock
      //         : false,
      //       ParentID: data.key,
      //       key: variant.ProductID,
      //     };
      //     products.push(variantProduct);
      //     productIDs.push(variantProduct.key);
      //   }
      // }
    });
    if (
      this.props.user.store
        .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount
    ) {
      //get instock qty
      let instockResult = await this.props.getInStockQtyForProducts(
        this.props.user.store.key,
        "",
        productIDs
      );
      for (let i = 0; i < products.length; i++) {
        if (
          instockResult.searchResult.filter((f) => f.key === products[i].key)
            .length > 0
        ) {
          let tmp = instockResult.searchResult.filter(
            (f) => f.key === products[i].key
          );
          products[i].InStockQty = tmp[0].InStockQty;
          products[i].ActivePSIN =
            tmp[0].PincodeProductID && tmp[0].PincodeProductID !== ""
              ? "Active PSIN"
              : "";
        } else {
          products[i].InStockQty = 0;
          products[i].ActivePSIN = "";
        }
      }
    }
    this.setState({
      products: products,
      totalItemsCount: result.totalItemsCount,
      loading: false,
      recordFrom: from,
    });
  }

  onSearchClick() {
    this.setState({ recordFrom: 0 });
    this.loadProducts(
      0,
      "active",
      this.state.searchText,
      this.state.searchProductType,
      this.state.searchCategory,
      this.state.searchSubCategory,
      this.state.searchBrand,
      "",
      "",
      "",
      this.state.outOfStockManual,
      this.state.outOfStockCancellation
    );
  }

  onClearSearch() {
    this.setState({
      recordFrom: 0,
      searchText: "",
      searchProductType: "",
      searchCategory: "",
      searchSubCategory: "",
      searchBrand: "",
      outOfStockManual: false,
      outOfStockCancellation: false,
      outOfStockOption: "",
    });
    this.loadProducts(
      0,
      "active",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      false,
      false
    );
  }

  async onSaveClick() {
    let products = this.state.products;
    if (products.filter((f) => f.Updated).length === 0) {
      alert("No records to update");
      return;
    }
    let error = false;
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.MRP === "") {
        error = true;
        product.MRPError = "Please provide value";
      }
      if (product.RetailPrice === "") {
        error = true;
        product.RetailPriceError = "Please provide value";
      }
      if (
        product.MRP !== "" &&
        product.RetailPrice !== "" &&
        Number(product.MRP) < Number(product.RetailPrice)
      ) {
        error = true;
        product.RetailPriceError = "Retail price can not be more than MRP";
      }
      if (product.RetailPrice !== "" && Number(product.RetailPrice) === 0) {
        error = true;
        product.RetailPriceError = "Retail price can not be 0";
      }
      if (product.RetailPrice !== "" && Number(product.RetailPrice) < 0) {
        error = true;
        product.RetailPriceError = "Retail price can not be negative";
      }
    }
    if (error) {
      this.setState({ products: products });
      return;
    }
    this.setState({ loading: true });
    for (let i = 0; i < products.length; i++) {
      let product = products[i];
      if (product.Updated) {
        products[i].Updated = false;
        let stores = this.props.config.Stores;
        let storeSpecificUpdated = false;
        for (let i = 0; i < product.StoreSpecificPrices.length; i++) {
          if (
            product.StoreSpecificPrices[i].StoreID === this.props.user.store.key
          ) {
            storeSpecificUpdated = true;
            // product.StoreSpecificPrices[i].SupplyPrice = product.SupplyPrice;
            product.StoreSpecificPrices[i].RetailPrice = product.RetailPrice;
            product.StoreSpecificPrices[i].MRP = product.MRP;
            product.StoreSpecificPrices[i].OutOfStock = product.OutOfStock;
            product.StoreSpecificPrices[i].OMNIEnabled = product.OMNIEnabled;
            if (
              !product.StoreSpecificPrices[i].OutOfStock &&
              product.OutOfStock
            ) {
              product.StoreSpecificPrices[i].OutOfStockReason = "";
            }
          }
        }
        if (!storeSpecificUpdated) {
          //add to list
          product.StoreSpecificPrices.push({
            DiscountAmount: 0,
            DiscountPercentage: 0,
            EditWalletTopUpAmount: false,
            MRP: product.MRP,
            MRPError: "",
            MSP: "",
            Markup: "",
            PriceError: "",
            RetailPrice: product.RetailPrice,
            Store:
              this.props.user.store.StoreCode +
              " " +
              this.props.user.store.StoreName,
            StoreID: this.props.user.store.key,
            SupplyPrice: 0,
            WalletTopUpAmount: 0,
            WalletTopUpSameAsRetailPrice: false,
            OMNIEnabled: product.OMNIEnabled,
            OutOfStock: product.OutOfStock,
            OutOfStockReason: "",
          });
        }

        for (let i = 0; i < product.StoreSpecificPrices.length; i++) {
          product.StoreSpecificPrices[i].MSP = 0;
          product.StoreSpecificPrices[i].PriceError = "";
          product.StoreSpecificPrices[i].DiscountAmount = 0;
          product.StoreSpecificPrices[i].DiscountPercentage = 0;
          product.StoreSpecificPrices[i].EditWalletTopUpAmount = 0;
          product.StoreSpecificPrices[i].MRPError = "";
          product.StoreSpecificPrices[i].Markup = 0;
          product.StoreSpecificPrices[i].PriceError = "";
          // product.StoreSpecificPrices[i].SupplyPrice = 0;
          product.StoreSpecificPrices[i].WalletTopUpAmount = 0;
          product.StoreSpecificPrices[i].WalletTopUpSameAsRetailPrice = false;
        }
        if (stores.length > 1) {
          await this.props.updateProduct(
            {
              RetailPrice: product.RetailPrice,
              MRP: product.MRP,
              StoreSpecificPrices: product.StoreSpecificPrices,
            },
            product.key
          );
        } else {
          await this.props.updateProduct(
            {
              RetailPrice: product.RetailPrice,
              MRP: product.MRP,
              OMNIEnabled: product.OMNIEnabled,
              OutOfStock: product.OutOfStock,
              StoreSpecificPrices: product.StoreSpecificPrices,
            },
            product.key
          );
        }
      }
    }
    alert("Data saved successfully");
    setTimeout(
      function() {
        //Start the timer
        this.setState({ loading: false });
        this.onSearchClick();
      }.bind(this),
      3000
    );
  }

  showPaginationStatus() {
    let paginationStatus;
    if (this.state.totalItemsCount === 0) {
      paginationStatus = "No record(s) found";
    } else if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    return (
      <div
        className={
          isMobileApp() ? "list-page-mobile-pagination" : "list-page-pagination"
        }
      >
        {paginationStatus}
      </div>
    );
  }

  renderProductImage(prodcut) {
    let image = "";
    if (prodcut.Images && prodcut.Images.length > 0) {
      image = prodcut.Images[0].ImagePath;
    }
    return (
      <React.Fragment>
        {image !== "" && (
          <div>
            {/* className="productquickedit-prodcut-image" */}
            <img className="list-page-image" src={image} />
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-wrapper">
          <section className="content">
            <div className="box ">
              <div className="box-header with-border">
                <font className="productquickedit-page-header-title">
                  Products - Quick Edit
                </font>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-md-2">
                    <Input
                      type="typeahead"
                      label="Brand"
                      options={this.state.brands}
                      value={this.state.searchBrandTA}
                      onChange={(value) => this.onBrandChange(value)}
                      labelKey="Name"
                    />
                  </div>
                  <div className="col-md-2">
                    <Input
                      type="typeahead"
                      label="Category"
                      options={this.state.categories}
                      value={this.state.searchCategoryTA}
                      onChange={(value) => this.onCategoryChange(value)}
                      labelKey="Name"
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="text"
                      label="Product Name, SKU, Barcode"
                      value={this.state.searchText}
                      onChange={(value) => this.setState({ searchText: value })}
                    />
                  </div>
                  <div className="col-md-3">
                    <Input
                      type="select"
                      label="Out of stock on Pincode App"
                      value={this.state.outOfStockOption}
                      onChange={(value) => {
                        let outOfStockManual =
                          value === "manual" ? true : false;
                        let outOfStockCancellation =
                          value === "cancellation" ? true : false;
                        this.setState({
                          outOfStockOption: value,
                          outOfStockManual: outOfStockManual,
                          outOfStockCancellation: outOfStockCancellation,
                        });
                      }}
                    >
                      <option></option>
                      <option value={"manual"}>
                        Due to merchant selection
                      </option>
                      <option value={"cancellation"}>
                        Due to order cancellation
                      </option>
                    </Input>
                  </div>
                  <div className="col-md-2">
                    <button
                      type="button"
                      className="btn btn-default btn-flat btn-md "
                      style={{ marginTop: "25px", marginRight: "5px" }}
                      onClick={this.onSearchClick}
                    >
                      Search
                    </button>
                    <button
                      type="button"
                      className="btn btn-default btn-flat btn-md"
                      style={{ marginTop: "25px" }}
                      onClick={this.onClearSearch}
                    >
                      Clear
                    </button>
                  </div>
                </div>
                {!this.state.loading && this.state.totalItemsCount > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-success btn-flat btn-md pull-right"
                        onClick={this.onSaveClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
                <br />
                {this.state.loading && <Spinner hideOverlay={true} />}
                <div className="row">
                  <div className="col-md-12">
                    {!this.state.loading && (
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="productquickedit-th-image"></th>
                            <th>Code</th>
                            <th>Category</th>
                            <th>Sub Category</th>
                            <th>Brand</th>
                            <th>Product</th>
                            {this.props.user.store
                              .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount && (
                              <th>In Stock Qty</th>
                            )}
                            <th className="productquickedit-th-mrp">MRP</th>
                            <th className="productquickedit-th-retailprice">
                              Retail Price
                            </th>
                            <th>
                              <input
                                type="checkbox"
                                checked={this.state.outOfStock}
                                onChange={() => {
                                  let outOfStock = !this.state.outOfStock;
                                  let products = this.state.products;
                                  products.map((m) => {
                                    m.OutOfStock = outOfStock;
                                    m.Updated = true;
                                  });
                                  this.setState({
                                    products: products,
                                    outOfStock: outOfStock,
                                  });
                                }}
                              />{" "}
                              Show Out of Stock On Pincode
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.products.map((product, index) => (
                            <tr key={product.key}>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {this.renderProductImage(product)}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <div>{product.SKU}</div>
                                <div>{product.BarCode}</div>
                                {product.ActivePSIN &&
                                  product.ActivePSIN !== "" && (
                                    <div className="badge green">
                                      Active PSIN
                                    </div>
                                  )}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {product.Category}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {product.SubCategory}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {product.Brand}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {product.ParentID === "" && (
                                  <div>{product.Name}</div>
                                )}
                                {product.ParentID !== "" && (
                                  <div>
                                    <i className="fa fa-arrow-right"></i>
                                    &nbsp;&nbsp;&nbsp;{product.Name}
                                  </div>
                                )}
                              </td>
                              {this.props.user.store
                                .ShowProductsOnlineAvailabilityBasedOnStockInventoryCount && (
                                <td className="number">{product.InStockQty}</td>
                              )}
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                {!this.props.registration.registration
                                  .EnablePosBillingForPincode && (
                                  <Input
                                    type="number"
                                    onChange={(value) => {
                                      let products = this.state.products;
                                      products[index].MRP = value;
                                      products[index].MRPError = "";
                                      products[index].Updated = true;
                                      this.setState({ products: products });
                                    }}
                                    value={product.MRP}
                                    error={product.MRPError}
                                  />
                                )}
                                {this.props.registration.registration
                                  .EnablePosBillingForPincode && (
                                  <div className="number">{product.MRP}</div>
                                )}
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <Input
                                  type="number"
                                  onChange={(value) => {
                                    let products = this.state.products;
                                    products[index].RetailPrice = value;
                                    products[index].RetailPriceError = "";
                                    products[index].Updated = true;
                                    this.setState({ products: products });
                                  }}
                                  value={product.RetailPrice}
                                  error={product.RetailPriceError}
                                />
                              </td>
                              <td
                                style={{
                                  borderBottom: product.Updated
                                    ? "solid 1px orange"
                                    : "",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={product.OutOfStock}
                                  onChange={() => {
                                    let products = this.state.products;
                                    products[index].OutOfStock = !products[
                                      index
                                    ].OutOfStock;
                                    products[index].Updated = true;
                                    if (products[index].Variants.length > 0) {
                                      //parent prodcut with variants
                                      for (
                                        let p = 0;
                                        p < products.length;
                                        p++
                                      ) {
                                        if (
                                          products[p].ParentID ===
                                          products[index].key
                                        ) {
                                          products[p].Updated = true;
                                          products[p].OutOfStock =
                                            products[index].OutOfStock;
                                        }
                                      }
                                    }
                                    this.setState({ products: products });
                                  }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>

                {this.state.totalItemsCount > 0 && (
                  <div className="list-page-footer">
                    <div className="left">{this.showPaginationStatus()}</div>
                    <div className="right">
                      <div className="list-page-footer-pagination">
                        <ImageButton
                          type={"leftarrowdouble"}
                          label={"First"}
                          onClick={() => this.onFirstClick()}
                        />
                        <ImageButton
                          type={"leftarrow"}
                          label={"Previous"}
                          onClick={() => this.onPreviousClick()}
                        />
                        <ImageButton
                          type={"rightarrow"}
                          label={"Next"}
                          onClick={() => this.onNextClick()}
                        />
                        <ImageButton
                          type={"rightarrowdouble"}
                          label={"Last"}
                          onClick={() => this.onLastClick()}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {!this.state.loading && this.state.totalItemsCount > 0 && (
                  <div className="row">
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-success btn-flat btn-md pull-right"
                        onClick={this.onSaveClick}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default PincodeProductQuickEdit;
