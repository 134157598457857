import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import ReactHtmlParser from "react-html-parser";

class WidgetTotalStoresSales extends Component {
  constructor({ stores, accessibleStores, fromDate, toDate }) {
    super();
    this.state = {
      stores: stores,
      accessibleStores: accessibleStores,
      fromDate: fromDate,
      toDate: toDate,
      salesDashboardData: {},
      storesSale: [],
      selectePeriodTransactions: 0,
      selectedPeriodQty: 0,
      selectedPeriodCustomers: 0,
      selectedPeriodSales: 0,
      selectedPeriodAvgTicketSize: 0,
      showSalesChart: true,
      showSalesList: false,
    };
  }

  async componentDidMount() {
    this.loadData(this.state.stores, this.state.fromDate, this.state.toDate);
  }

  loadData(stores, fromDate, toDate) {
    //
    this.props
      .getSalesDashboard(
        this.props.user.user.RegistrationID,
        stores,
        "",
        "",
        fromDate,
        toDate
      )
      .then((result) => {
        let salesDashboardData = {
          labels: [],
          datasets: [
            {
              label: "Sales",
              backgroundColor: "#6E2D90",
              borderColor: "#6e2d90",
              hoverBorderColor: "#6e2d90",
              hoverBackgroundColor: "#6e2d90",
              data: [],
            },
          ],
        };
        let selectePeriodTransactions = 0;
        let selectedPeriodQty = 0;
        let selectedPeriodCustomers = 0;
        let selectedPeriodSales = 0;
        let selectedPeriodAvgTicketSize = 0;
        let storesSale = [];

        //console.log("sales", JSON.stringify(result.searchResult))
        for (let l = 0; l < result.searchResult.length; l++) {
          const data = result.searchResult[l];

          for (let k = 0; k < this.state.accessibleStores.length; k++) {
            const store = this.state.accessibleStores[k];

            if (store.key === data.key) {
              salesDashboardData.labels.push(store.StoreCode);
              salesDashboardData.datasets[0].data.push(
                Number(data.total_sales.value).toFixed(2)
              );

              storesSale.push({
                "Store Name": store.StoreCode + "-" + store.StoreName,
                Transactions: Number(data.total_transactions.value).toFixed(0),
                Qty: Number(data.total_qty.value).toFixed(2),
                Customers: Number(data.total_customers.value).toFixed(0),
                Sales: Number(data.total_sales.value).toFixed(2),
              });
              selectePeriodTransactions =
                Number(selectePeriodTransactions) +
                Number(data.total_transactions.value);
              selectedPeriodQty =
                Number(selectedPeriodQty) + Number(data.total_qty.value);
              selectedPeriodCustomers =
                Number(selectedPeriodCustomers) +
                Number(data.total_customers.value);
              selectedPeriodSales =
                Number(selectedPeriodSales) + Number(data.total_sales.value);
            }
          }
        }
        selectedPeriodAvgTicketSize =
          selectedPeriodSales === 0
            ? 0
            : Number(selectedPeriodSales / selectePeriodTransactions);
        // console.log('storesSale',storesSale)
        var col = [];
        for (var k = 0; k < storesSale.length; k++) {
          for (var key in storesSale[k]) {
            if (col.indexOf(key) === -1) {
              col.push(key);
            }
          }
        }
        // console.log("columns", col)
        var resultHtml =
          "<table class='table table-bordered table-striped'><thead><tr>";
        for (var l = 0; l < col.length; l++) {
          resultHtml += "<th>";
          resultHtml += col[l];
          resultHtml += "</th>";
        }
        resultHtml += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        resultHtml += "<tbody>";
        for (var i = 0; i < storesSale.length; i++) {
          resultHtml += "<tr>";
          for (var j = 0; j < col.length; j++) {
            resultHtml += j === 0 ? "<td>" : "<td style='text-align:right'>";
            resultHtml +=
              storesSale[i][col[j]] == undefined ? 0 : storesSale[i][col[j]];
            resultHtml += "</td>";
          }
          resultHtml += "</tr>";
        }
        resultHtml += "</tbody>";
        resultHtml += "<tfoot>";
        resultHtml += "<tr><td style='font-weight:bold'>Totals</td>";
        for (var j = 1; j < col.length; j++) {
          let total = 0;
          for (var i = 0; i < storesSale.length; i++) {
            total +=
              storesSale[i][col[j]] === undefined
                ? 0
                : Number(storesSale[i][col[j]]);
          }
          resultHtml +=
            j === 0 ? "<td>" : "<td style='font-weight:bold;text-align:right'>";
          resultHtml +=
            j === 1 || j == 3
              ? Number(total).toFixed(0)
              : Number(total).toFixed(2);
          resultHtml += "</td>";
        }
        resultHtml += "</tr>";
        resultHtml += "</tfoot>";
        resultHtml += "</table>";

        // console.log('resultHtml',resultHtml)
        this.setState({
          salesDashboardData: salesDashboardData,
          storesSale: resultHtml,
          selectePeriodTransactions: Number(selectePeriodTransactions).toFixed(
            0
          ),
          selectedPeriodQty: Number(selectedPeriodQty).toFixed(0),
          selectedPeriodCustomers: Number(selectedPeriodCustomers).toFixed(0),
          selectedPeriodSales: Number(selectedPeriodSales).toFixed(0),
          selectedPeriodAvgTicketSize: Number(
            selectedPeriodAvgTicketSize
          ).toFixed(2),
        });
      });
  }

  componentWillReceiveProps({ stores, fromDate, toDate }) {
    this.loadData(stores, fromDate, toDate);
  }

  render() {
    return (
      <div className="dashboard-box ">
        <div className="dashboard-box-header ">
          <div className="dashboard-box-title left">Store Sales</div>
          <div className="right">
            <button
              type="button"
              className="btn btn-default btn-flat btn-md pull-right fa fa-list"
              style={{
                padding: "6px",
                marginLeft: 0,
                marginRight: 0,
                color: this.state.showSalesList ? "#6e2d90" : "gray",
              }}
              onClick={() =>
                this.setState({ showSalesChart: false, showSalesList: true })
              }
            ></button>
            <button
              type="button"
              className="btn btn-default btn-flat btn-md pull-right fa fa-bar-chart"
              style={{
                padding: "6px",
                marginLeft: 0,
                marginRight: 0,
                color: this.state.showSalesChart ? "#6e2d90" : "gray",
              }}
              onClick={() =>
                this.setState({ showSalesChart: true, showSalesList: false })
              }
            ></button>
          </div>
        </div>
        <div className="dashboard-box-body">
          {this.state.showSalesChart && (
            <React.Fragment>
              <div className="chart">
                <Bar
                  width={500}
                  height={100}
                  data={this.state.salesDashboardData}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            beginAtZero: true,
                          },
                        },
                      ],
                    },
                  }}
                />
              </div>
            </React.Fragment>
          )}
          {this.state.showSalesList && (
            <div className="row">
              <div className="col-md-12">
                <div
                  className="table-responsive scroll2"
                  style={{
                    height: "245px",
                    overflowY: "scroll",
                    overflowX: "auto",
                  }}
                >
                  {ReactHtmlParser(this.state.storesSale)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="dashboard-box-footer">
          <div className="description-block border-right">
            <h5 className="description-header">
              {this.state.selectedPeriodSales}
            </h5>
            <span className="description-text">TOTAL SALES</span>
          </div>
          <div className="description-block border-right">
            <h5 className="description-header">
              {this.state.selectePeriodTransactions}
            </h5>
            <span className="description-text">TOTAL TRANSACTIONS</span>
          </div>
          <div className="description-block border-right">
            <h5 className="description-header">
              {this.state.selectedPeriodAvgTicketSize}
            </h5>
            <span className="description-text">AVG TICKET</span>
          </div>
          <div className="description-block">
            <h5 className="description-header">
              {this.state.selectedPeriodCustomers}
            </h5>
            <span className="description-text">CUSTOMERS</span>
          </div>
        </div>
      </div>
    );
  }
}

export default WidgetTotalStoresSales;
