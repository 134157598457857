import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Pagination from "react-js-pagination";
import * as constatnts from "../../Constatnts";
import Loader from "../Loader";
import ReactHtmlParser from "react-html-parser";
import Input from "../2/Controls/Input";
import "./InvoiceDetail.css";
import firebase from "../../firebase";

class InvoiceDetailList extends Component {
  constructor() {
    super();
    this.state = {
      taxes: [
        { name: "CGST", perc: "CGST%" },
        { name: "SGST", perc: "SGST%" },
        { name: "IGST", perc: "IGST%" },
        { name: "CESS", perc: "CESS%" },
      ],
      invoices: [],
      stores: [],
      storeList: [],
      searchText: "",
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchProductDetail: "",
      searchDiscount: "",
      searchTaxableAmount: "",
      searchTaxSummary: "",
      activePage: 1,
      itemsCountPerPage: 20,
      totalItemsCount: 0,
      recordFrom: 0,
      title: "",
      paginationStatus: "No records to display",
      exportCalled: false,
      loading: true,
      productCustomField1DisplayName: "ProductCustomField1",
      productCustomField2DisplayName: "ProductCustomField2",
      productCustomField3DisplayName: "ProductCustomField3",
      productCustomField4DisplayName: "ProductCustomField4",
      productCustomField5DisplayName: "ProductCustomField5",
      productCustomField6DisplayName: "ProductCustomField6",
      customerCustomField1DisplayName: "CustomerCustomField1",
      customerCustomField2DisplayName: "CustomerCustomField2",
      customerCustomField3DisplayName: "CustomerCustomField3",
      customerCustomField4DisplayName: "CustomerCustomField4",
      customerCustomField5DisplayName: "CustomerCustomField5",
      customerCustomField6DisplayName: "CustomerCustomField6",
      searchBrand: "",
      showCustomFields: false,
      showBatchesAndUniqueNo: false,
      showProductImage: false,
    };

    this.onClearSearch = this.onClearSearch.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.showPaginationStatus = this.showPaginationStatus.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onExportClick = this.onExportClick.bind(this);
    this.onStoreChange = this.onStoreChange.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getElasticData = this.getElasticData.bind(this);
  }

  componentDidMount() {
    this.setState({ storeID: this.props.user.store.key });
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.props
      .getUserStores(this.props.user.user.RegistrationID, "")
      .then((stores) => {
        this.setState({ stores: stores });
      });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Customer") {
        let gstinCustomFieldNo = 0;
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }

        let customerCustomField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "CustomerCustomField1"
            : customField.CustomField1Name.trim();
        let customerCustomField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "CustomerCustomField2"
            : customField.CustomField2Name.trim();
        let customerCustomField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "CustomerCustomField3"
            : customField.CustomField3Name.trim();
        let customerCustomField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "CustomerCustomField4"
            : customField.CustomField4Name.trim();
        let customerCustomField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "CustomerCustomField5"
            : customField.CustomField5Name.trim();
        let customerCustomField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "CustomerCustomField6"
            : customField.CustomField6Name.trim();
        this.setState({
          customerCustomField1DisplayName: customerCustomField1DisplayName,
          customerCustomField2DisplayName: customerCustomField2DisplayName,
          customerCustomField3DisplayName: customerCustomField3DisplayName,
          customerCustomField4DisplayName: customerCustomField4DisplayName,
          customerCustomField5DisplayName: customerCustomField5DisplayName,
          customerCustomField6DisplayName: customerCustomField6DisplayName,
          gstinCustomFieldNo: gstinCustomFieldNo,
        });

        // this.setState({ })
      } else if (customField.ModuleName === "Product") {
        let productCustomField1DisplayName =
          customField.CustomField1Name.trim() === ""
            ? "ProductCustomField1"
            : customField.CustomField1Name.trim();
        let productCustomField2DisplayName =
          customField.CustomField2Name.trim() === ""
            ? "ProductCustomField2"
            : customField.CustomField2Name.trim();
        let productCustomField3DisplayName =
          customField.CustomField3Name.trim() === ""
            ? "ProductCustomField3"
            : customField.CustomField3Name.trim();
        let productCustomField4DisplayName =
          customField.CustomField4Name.trim() === ""
            ? "ProductCustomField4"
            : customField.CustomField4Name.trim();
        let productCustomField5DisplayName =
          customField.CustomField5Name.trim() === ""
            ? "ProductCustomField5"
            : customField.CustomField5Name.trim();
        let productCustomField6DisplayName =
          customField.CustomField6Name.trim() === ""
            ? "ProductCustomField6"
            : customField.CustomField6Name.trim();
        this.setState({
          productCustomField1DisplayName: productCustomField1DisplayName,
          productCustomField2DisplayName: productCustomField2DisplayName,
          productCustomField3DisplayName: productCustomField3DisplayName,
          productCustomField4DisplayName: productCustomField4DisplayName,
          productCustomField5DisplayName: productCustomField5DisplayName,
          productCustomField6DisplayName: productCustomField6DisplayName,
        });
      }
    });

    this.loadInvoiceDetailReport(
      storeList,
      this.state.recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchProductDetail,
      this.state.searchDiscount,
      this.state.searchTaxableAmount,
      this.state.searchTaxSummary,
      this.state.searchBrand
    );
  }

  async getElasticData(
    registrationID,
    searchStores,
    from,
    size,
    searchTransactionNo,
    searchTransactionType,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchProductDetail,
    searchBrand = "",
    exportData = false
  ) {
    const fromDateStr = searchTransactionFromDate + "T00:00:00";
    // Create a Date object
    const fromDate = new Date(fromDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisFrom = fromDate.getTime();
    // console.log(epochMillisFrom);

    // Define the date-time string
    const toDateStr = searchTransactionToDate + "T23:59:59";
    // Create a Date object
    const toDate = new Date(toDateStr);
    // Convert the Date object to epoch milliseconds
    const epochMillisTo = toDate.getTime();
    // console.log(epochMillisTo);

    // console.log("getElasticData");

    let searchParameters = {
      _source: [
        "StoreID",
        "StoreName",
        "StoreCode",
        "CashRegisterName",
        "CashRegisterCode",
        "CustomerCode",
        "CustomerName",
        "CustomerPhoneNo",
        "CustomerCustomField1",
        "CustomerCustomField2",
        "CustomerCustomField3",
        "CustomerCustomField4",
        "CustomerCustomField5",
        "CustomerCustomField6",
        "TransactionNo",
        "TransactionType",
        "TransactionDate",
        "CancellationReason",
        "ProductType",
        "HSNSACCode",
        "Brand",
        "Category",
        "SubCategory",
        "SKU",
        "BarCode",
        "ProductID",
        "ProductName",
        "ProductCustomField1",
        "ProductCustomField2",
        "ProductCustomField3",
        "ProductCustomField4",
        "ProductCustomField5",
        "ProductCustomField6",
        "MRP",
        "SupplyPrice",
        "RetailPrice",
        "Qty",
        "TaxInclusive",
        "TaxableAmount",
        "DiscountPer",
        "TaxComponentAmount",
        "SubTotal",
        "TotalTax",
        "DiscountAmount",
        "Amount",
        "UniqueNo1",
        "UniqueNo2",
        "UniqueNo3",
        "UniqueNo4",
        "BatchExpDate",
        "BatchExpDateValue",
        "BatchMRP",
        "BatchMfgDate",
        "BatchMfgDateValue",
        "BatchNo",
        "BatchRetailPrice",
        "TransactionID",
        "EmployeeName",
        "SalesChannelName",
      ],
      from: from,
      size: size,
      track_total_hits: true,
      query: {
        bool: {
          must: [
            {
              term: {
                RegistrationID: registrationID,
              },
            },
            {
              range: {
                TransactionDate: {
                  lte: epochMillisTo,
                  gte: epochMillisFrom,
                },
              },
            },
          ],
          should: [],
          minimum_should_match: 1,
          filter: {
            bool: {
              must: [],
            },
          },
        },
      },
      sort: [
        {
          TransactionDate: {
            order: "desc",
          },
        },
      ],
    };
    for (let i = 0; i < searchStores.length; i++) {
      const store = searchStores[i];

      searchParameters.query.bool.should.push({
        term: {
          "StoreID.keyword": store.key,
        },
      });
    }
    if (searchCustomerDetail.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          CustomerDetails:
            "@" + searchCustomerDetail.trim().toLowerCase() + ".*",
        },
      });
    }
    if (searchProductDetail.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          ProductDetails: "@" + searchProductDetail.trim().toLowerCase() + ".*",
        },
      });
    }
    if (searchTransactionNo.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { TransactionNo: searchTransactionNo },
      });
    }
    if (searchTransactionType.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        regexp: {
          TransactionTypeID: searchTransactionType.trim().toLowerCase(),
        },
      });
    }
    if (searchBrand.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        match_phrase: { Brand: searchBrand.trim().toLowerCase() },
      });
    }

    // console.log("getElasticData", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable("scrollData");
    let result = await addMessage({
      text: searchParameters,
      collection: "denomsales",
      scrollID: "",
    });

    const invoices = [];
    let sanitizedMessage = result.data.text;
    let total = sanitizedMessage.hits.total.value;
    sanitizedMessage.hits.hits.map((data, index) => {
      invoices.push({ ...data._source, key: data._id });
    });
    // console.log("getElasticData 0");

    if (total > size && exportData) {
      let scrollID = sanitizedMessage._scroll_id;
      searchParameters = {
        scroll: "9m",
        scroll_id: scrollID,
      };
      for (let index = 1; index < total / size; index++) {
        // console.log("getElasticData ", index);
        let addMessage = firebase.functions().httpsCallable("scrollData");
        let loopResult = await addMessage({
          text: searchParameters,
          scrollID: scrollID,
        });
        loopResult.data.text.hits.hits.map((data, index) => {
          invoices.push({ ...data._source, key: data._id });
        });
      }
    }

    return {
      totalItemsCount: total,
      searchResult: invoices,
    };
  }

  // search method
  async loadData(
    storeList,
    from,
    size,
    searchTransactionNo,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchProductDetail,
    searchDiscount,
    searchTaxableAmount,
    searchTaxSummary,
    searchBrand,
    forExport = false
  ) {
    // console.log("searchBrand", searchBrand)
    let gstinCustomFieldNo = this.getGSTINCustomField();

    // let result = await this.props.getInvoiceDetailReport(
    //   this.props.user.user.RegistrationID,
    //   storeList,
    //   from,
    //   size,
    //   searchTransactionNo,
    //   searchTransactiontype,
    //   searchTransactionFromDate,
    //   searchTransactionToDate,
    //   searchCustomerDetail,
    //   searchProductDetail,
    //   searchBrand
    // );
    let result = await this.getElasticData(
      this.props.user.user.RegistrationID,
      storeList,
      from,
      size,
      searchTransactionNo,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchProductDetail,
      searchBrand,
      forExport
    );

    let invoices = [];
    if (result && result.searchResult && result.searchResult.length > 0) {
      let productIDs = [];
      for (let i = 0; i < result.searchResult.length; i++) {
        if (
          productIDs.filter((f) => f === result.searchResult[i].ProductID)
            .length === 0
        ) {
          const productID = result.searchResult[i].ProductID;
          productIDs.push(productID);
        }
      }

      let res1 = await this.props.getProductsByProductIDs(productIDs);
      let productList = res1.searchResult;

      let invoiceIDs = [];
      for (let i = 0; i < result.searchResult.length; i++) {
        if (
          invoiceIDs.filter((f) => f === result.searchResult[i].TransactionID)
            .length === 0
        ) {
          invoiceIDs.push(result.searchResult[i].TransactionID);
        }
      }
      let payments = await this.props.getSalesPaymentModesBySalesIDs(
        invoiceIDs
      );
      let transactionID = "";
      for (let index = 0; index < result.searchResult.length; index++) {
        const data = result.searchResult[index];
        // console.log('data', data)
        let invoice = {
          StoreName: data.StoreName,
          CashRegister: data.CashRegisterName,
        };
        invoice["TransactionNo"] = data.TransactionNo;
        invoice["TransactionType"] = data.TransactionType;
        // invoice["TransactionDate"] = new Date(data.TransactionDate)
        //   .toISOString()
        //   .split("T")[0];
        invoice["TransactionDate"] = new Date(data.TransactionDate)
          .toString()
          .substring(0, 15);
        invoice.Cancelled = "No";
        if (data.CancellationReason) {
          invoice.Cancelled = "Yes";
        }

        invoice["CustomerCode"] = data.CustomerCode;
        invoice["CustomerName"] = data.CustomerName;
        invoice["CustomerMobileNo"] = this.props.registration.registration
          .EnablePosBillingForPincode
          ? ""
          : data.CustomerPhoneNo;
        invoice["CustomerGSTIN"] = "";
        if (gstinCustomFieldNo === 1) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField1")
            ? data.CustomerCustomField1
            : "";
        } else if (gstinCustomFieldNo === 2) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField2")
            ? data.CustomerCustomField2
            : "";
        } else if (gstinCustomFieldNo === 3) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField3")
            ? data.CustomerCustomField3
            : "";
        } else if (gstinCustomFieldNo === 4) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField4")
            ? data.CustomerCustomField4
            : "";
        } else if (gstinCustomFieldNo === 5) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField5")
            ? data.CustomerCustomField5
            : "";
        } else if (gstinCustomFieldNo === 6) {
          invoice["CustomerGSTIN"] = data.hasOwnProperty("CustomerCustomField6")
            ? data.CustomerCustomField6
            : "";
        }

        invoice["Brand"] = data.Brand ? data.Brand : "";
        invoice["Category"] = data.Category;
        invoice["SubCategory"] = data.SubCategory ? data.SubCategory : "";
        invoice["ProductType"] = data.ProductType;
        invoice["HSNSACCode"] = data.HSNSACCode;
        if (this.state.showProductImage) {
          if (productList && productList.length > 0 && !forExport) {
            let objProd = productList.filter((p) => p.key === data.ProductID);
            if (objProd && objProd.length > 0) {
              invoice.ProductImage =
                objProd[0].images && objProd[0].images.length > 0
                  ? objProd[0].images[0].ImagePath
                  : "";
            }
          }
        }
        invoice["SKU"] = data.SKU;
        invoice["BarCode"] = data.BarCode;
        invoice["ItemName"] = data.ProductName.replace(/,/g, "");
        if (this.state.showCustomFields) {
          invoice[
            this.state.productCustomField1DisplayName
          ] = data.hasOwnProperty("ProductCustomField1")
            ? data.ProductCustomField1
            : "";
          invoice[
            this.state.productCustomField2DisplayName
          ] = data.hasOwnProperty("ProductCustomField2")
            ? data.ProductCustomField2
            : "";
          invoice[
            this.state.productCustomField3DisplayName
          ] = data.hasOwnProperty("ProductCustomField3")
            ? data.ProductCustomField3
            : "";
          invoice[
            this.state.productCustomField4DisplayName
          ] = data.hasOwnProperty("ProductCustomField4")
            ? data.ProductCustomField4
            : "";
          invoice[
            this.state.productCustomField5DisplayName
          ] = data.hasOwnProperty("ProductCustomField5")
            ? data.ProductCustomField5
            : "";
          invoice[
            this.state.productCustomField6DisplayName
          ] = data.hasOwnProperty("ProductCustomField6")
            ? data.ProductCustomField6
            : "";
        }
        if (this.state.showBatchesAndUniqueNo) {
          invoice["UniqueNo1"] = data.UniqueNo1;
          invoice["UniqueNo2"] = data.UniqueNo2;
          invoice["UniqueNo3"] = data.UniqueNo3;
          invoice["UniqueNo4"] = data.UniqueNo4;
          invoice["BatchExpDate"] = data.BatchExpDate ? data.BatchExpDate : "";
          invoice["BatchExpDateValue"] = data.BatchExpDateValue
            ? data.BatchExpDateValue
            : "";
          invoice["BatchMRP"] = data.BatchMRP;
          invoice["BatchMfgDate"] = data.BatchMfgDate ? data.BatchMfgDate : "";
          invoice["BatchMfgDateValue"] = data.BatchMfgDateValue
            ? data.BatchMfgDateValue
            : "";
          invoice["BatchNo"] = data.BatchNo;
          invoice["BatchRetailPrice"] = data.BatchRetailPrice;
        }
        invoice["MRP"] = data.MRP;
        invoice["SupplyPrice"] = data.hasOwnProperty("SupplyPrice")
          ? data.SupplyPrice
          : 0;
        invoice["RetailPrice"] = data.RetailPrice;
        invoice["Qty"] = data.Qty;
        invoice["TaxInclusive"] = data.TaxInclusive ? "Yes" : "No";
        invoice["TaxbleAmount"] = data.TaxableAmount;
        invoice["DiscountPer"] = data.DiscountPer;
        for (let index = 0; index < this.state.taxes.length; index++) {
          invoice[this.state.taxes[index].name] = "";
          invoice[this.state.taxes[index].perc] = "";
        }
        if (data.TaxComponentAmount) {
          data.TaxComponentAmount.map((tax) => {
            if (tax.TaxName !== "") {
              invoice[tax.TaxName] = tax.TaxAmount;
              invoice[tax.TaxName + "%"] = tax.TaxPercentage;
            }
          });
        }
        invoice["SubTotal"] = data.SubTotal;
        invoice["TotalTax"] = data.TotalTax;
        invoice["DiscountAmount"] = data.DiscountAmount;
        invoice["GrandTotal"] = data.Amount;
        this.props.config.PaymentTypes.filter(
          (f) => f.ShowInSales && !f.IsDeleted
        ).map((m) => {
          invoice[m.PaymentType] = 0;
        });
        payments
          .filter((f) => f.key === data.TransactionID)
          .map((m) => {
            if (this.props.config.WhiteLabel.Code === "WRX") {
              invoice["SalesChannel"] = m.SalesChannelName
                ? m.SalesChannelName
                : "";
            }
            m.Payments.map((p) => {
              if (transactionID !== data.TransactionID) {
                invoice[p.PaymentMode] = p.Amount;
              } else {
                invoice[p.PaymentMode] = "";
              }
            });
          });
        invoice["Employee"] = data.hasOwnProperty("EmployeeName")
          ? data.EmployeeName
          : "";
        if (this.state.showCustomFields) {
          invoice[
            this.state.customerCustomField1DisplayName
          ] = data.hasOwnProperty("CustomerCustomField1")
            ? data.CustomerCustomField1
            : "";
          invoice[
            this.state.customerCustomField2DisplayName
          ] = data.hasOwnProperty("CustomerCustomField2")
            ? data.CustomerCustomField2
            : "";
          invoice[
            this.state.customerCustomField3DisplayName
          ] = data.hasOwnProperty("CustomerCustomField3")
            ? data.CustomerCustomField3
            : "";
          invoice[
            this.state.customerCustomField4DisplayName
          ] = data.hasOwnProperty("CustomerCustomField4")
            ? data.CustomerCustomField4
            : "";
          invoice[
            this.state.customerCustomField5DisplayName
          ] = data.hasOwnProperty("CustomerCustomField5")
            ? data.CustomerCustomField5
            : "";
          invoice[
            this.state.customerCustomField6DisplayName
          ] = data.hasOwnProperty("CustomerCustomField6")
            ? data.CustomerCustomField6
            : "";
        }
        transactionID = data.TransactionID;
        invoices.push(invoice);
      }
      this.setState({ totalItemsCount: result.totalItemsCount });
    }
    return invoices;
  }

  getGSTINCustomField() {
    let gstinCustomFieldNo = 0;
    this.props.config.CustomFields.map((customField) => {
      if (customField.ModuleName === "Customer") {
        if (customField.CustomField1Name.trim().toLowerCase() === "gstin") {
          gstinCustomFieldNo = 1;
        } else if (
          customField.CustomField2Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 2;
        } else if (
          customField.CustomField3Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 3;
        } else if (
          customField.CustomField4Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 4;
        } else if (
          customField.CustomField5Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 5;
        } else if (
          customField.CustomField6Name.trim().toLowerCase() === "gstin"
        ) {
          gstinCustomFieldNo = 6;
        }
        this.setState({ gstinCustomFieldNo: gstinCustomFieldNo });
      }
    });
    return gstinCustomFieldNo;
  }

  async loadInvoiceDetailReport(
    storeList,
    from,
    size,
    searchTransactionNo,
    searchTransactiontype,
    searchTransactionFromDate,
    searchTransactionToDate,
    searchCustomerDetail,
    searchProductDetail,
    searchDiscount,
    searchTaxableAmount,
    searchTaxSummary,
    searchBrand
  ) {
    this.setState({ loading: true });

    let invoices = await this.loadData(
      storeList,
      from,
      size,
      searchTransactionNo,
      searchTransactiontype,
      searchTransactionFromDate,
      searchTransactionToDate,
      searchCustomerDetail,
      searchProductDetail,
      searchDiscount,
      searchTaxableAmount,
      searchTaxSummary,
      searchBrand
    );

    var col = [];
    for (var i = 0; i < invoices.length; i++) {
      for (var key in invoices[i]) {
        if (col.indexOf(key) === -1) {
          col.push(key);
        }
      }
    }
    // console.log("col", col);
    var result = "<table class='table table-bordered'><thead><tr>";
    for (var i = 0; i < col.length; i++) {
      result += "<th>";
      result += col[i];
      result += "</th>";
    }
    result += "</tr></thead>";

    // ADD JSON DATA TO THE TABLE AS ROWS.
    result += "<tbody>";
    for (var i = 0; i < invoices.length; i++) {
      result += "<tr>";
      for (var j = 0; j < col.length; j++) {
        result += "<td>";
        if (col[j] === "ProductImage") {
          if (invoices[i][col[j]] !== "") {
            result +=
              "<img class='invoicedetail-image' src='" +
              invoices[i][col[j]] +
              "'/>";
          }
        } else {
          result += invoices[i][col[j]] === undefined ? 0 : invoices[i][col[j]];
        }
        result += "</td>";
      }
      result += "</tr>";
    }
    result += "</tbody>";
    result += "</table>";
    // this.setState({ finalTable: result, loading: false })

    this.setState({ invoices: invoices, finalTable: result, loading: false });
    this.showPaginationStatus();
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.onSearchClick();
    }
  }

  onClearSearch() {
    let storeList = [];
    storeList.push({ key: this.props.user.store.key });

    this.setState({
      storeList: storeList,
      storeID: this.props.user.store.key,
      recordFrom: 0,
      searchTransactionNo: "",
      searchTransactiontype: "",
      searchTransactionFromDate: new Date().toISOString().split("T")[0],
      searchTransactionToDate: new Date().toISOString().split("T")[0],
      searchCustomerDetail: "",
      searchProductDetail: "",
      searchDiscount: "",
      searchTaxableAmount: "",
      searchTaxSummary: "",
      searchBrand: "",
    });

    this.loadInvoiceDetailReport(
      storeList,
      0,
      this.state.itemsCountPerPage,
      "",
      "",
      new Date().toISOString().split("T")[0],
      new Date().toISOString().split("T")[0],
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }

  async onExportClick() {
    this.setState({ loading: true });
    let invoices = await this.loadData(
      this.state.storeList,
      0,
      1000,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchProductDetail,
      this.state.searchDiscount,
      this.state.searchTaxableAmount,
      this.state.searchTaxSummary,
      this.state.searchBrand,
      true
    );

    const csvData = constatnts.objToCSV(invoices);
    this.setState({ loading: false, exportCalled: false });
    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(csvBlob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "InvoiceDetails.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ loading: false });
  }

  onSearchClick() {
    this.setState({ searchFrom: 0, loading: true });
    this.loadInvoiceDetailReport(
      this.state.storeList,
      0,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchProductDetail,
      this.state.searchDiscount,
      this.state.searchTaxableAmount,
      this.state.searchTaxSummary,
      this.state.searchBrand
    );
  }

  onStoreChange(value) {
    let storeList = [];
    //console.log("key", e.target.value);
    if (value != "") {
      storeList.push({ key: value });
    } else {
      for (let i = 0; i < this.state.stores.length; i++) {
        const store = this.state.stores[i];
        storeList.push({ key: store.key });
      }
    }
    //console.log("storeList", JSON.stringify(storeList));
    this.setState({ storeList: storeList, storeID: value });
  }

  handlePageChange(pageNumber) {
    let recordFrom = (pageNumber - 1) * this.state.itemsCountPerPage;
    this.setState({ activePage: pageNumber, recordFrom: recordFrom });
    this.loadInvoiceDetailReport(
      this.state.storeList,
      recordFrom,
      this.state.itemsCountPerPage,
      this.state.searchTransactionNo,
      this.state.searchTransactiontype,
      this.state.searchTransactionFromDate,
      this.state.searchTransactionToDate,
      this.state.searchCustomerDetail,
      this.state.searchProductDetail,
      this.state.searchDiscount,
      this.state.searchTaxableAmount,
      this.state.searchTaxSummary,
      this.state.searchBrand
    );
  }

  showPaginationStatus() {
    // console.log('this.state.recordFrom', this.state.recordFrom)
    let paginationStatus;
    if (this.state.recordFrom + 1 === this.state.totalItemsCount) {
      paginationStatus =
        "Showing " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else if (
      this.state.recordFrom + this.state.itemsCountPerPage + 1 >
      this.state.totalItemsCount
    ) {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        this.state.totalItemsCount +
        " of " +
        this.state.totalItemsCount;
    } else {
      paginationStatus =
        "Showing " +
        (this.state.recordFrom + 1) +
        " to  " +
        (this.state.recordFrom + this.state.itemsCountPerPage) +
        " of " +
        this.state.totalItemsCount;
    }
    this.setState({ paginationStatus: paginationStatus });
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content">
          <div className="row">
            <div className="col-md-12">
              <div className="box ">
                <div className="box-header with-border">
                  <div className="row">
                    <div className="col-md-6 col-xs-9">
                      <font style={{ fontSize: "30px" }}>
                        Transaction Detail Report
                      </font>
                    </div>
                    <div className="col-md-6 col-xs-3">
                      <button
                        type="cancel"
                        className="btn btn-default pull-right btn-flat"
                        style={{ marginTop: "5px", marginLeft: "10px" }}
                        onClick={() =>
                          this.props.history.push({
                            pathname: "/home/reports/allreport",
                          })
                        }
                      >
                        <i className="fa fa-arrow-left"></i> Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="box-body">
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Store"
                        value={this.state.storeID}
                        onChange={this.onStoreChange}
                      >
                        <option value="">All Stores</option>
                        {this.state.stores
                          .filter((e) => !e.IsDeleted)
                          .map((store) => (
                            <option value={store.key}>
                              {store.StoreCode}-{store.StoreName}
                            </option>
                          ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction From"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionFromDate: value,
                          })
                        }
                        value={this.state.searchTransactionFromDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="date"
                        label="Transaction To"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionToDate: value,
                          })
                        }
                        value={this.state.searchTransactionToDate}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="select"
                        label="Transaction Type"
                        value={this.state.searchTransactiontype}
                        onChange={(value) =>
                          this.setState({
                            searchTransactiontype: value,
                          })
                        }
                      >
                        <option value="">All</option>
                        {this.props.config.TransactionTypes.filter(
                          (e) => e.Module === "sales" && !e.IsDeleted
                        ).map((e) => (
                          <option value={e.key}>{e.TransactionType}</option>
                        ))}
                      </Input>
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Transaction No"
                        onChange={(value) =>
                          this.setState({
                            searchTransactionNo: value,
                          })
                        }
                        value={this.state.searchTransactionNo}
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Customer Details"
                        onChange={(value) =>
                          this.setState({
                            searchCustomerDetail: value,
                          })
                        }
                        value={this.state.searchCustomerDetail}
                        placeholder="Customer Name, Code, Phone Number, Email"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Product Details"
                        onChange={(value) =>
                          this.setState({
                            searchProductDetail: value,
                          })
                        }
                        value={this.state.searchProductDetail}
                        placeholder="Product Name, SKU, Barcode"
                      />
                    </div>
                    <div className="col-md-2">
                      <Input
                        type="text"
                        label="Brand"
                        onChange={(value) =>
                          this.setState({
                            searchBrand: value,
                          })
                        }
                        value={this.state.searchBrand}
                      />
                    </div>
                    {!this.props.registration.registration
                      .EnablePosBillingForPincode && (
                      <>
                        <div className="col-md-2">
                          <Input
                            type="switch"
                            label="Show custom fields"
                            onChange={(value) =>
                              this.setState({
                                showCustomFields: value,
                              })
                            }
                            value={this.state.showCustomFields}
                          />
                        </div>

                        <div className="col-md-2">
                          <Input
                            type="switch"
                            label="Show batches / unique nos"
                            onChange={(value) =>
                              this.setState({
                                showBatchesAndUniqueNo: value,
                              })
                            }
                            value={this.state.showBatchesAndUniqueNo}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-md-2">
                      <Input
                        type="switch"
                        label="Show product image"
                        onChange={(value) =>
                          this.setState({
                            showProductImage: value,
                          })
                        }
                        value={this.state.showProductImage}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                      <button
                        type="button"
                        className="btn btn-primary btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginLeft: "5px" }}
                        onClick={this.onSearchClick}
                        disabled={
                          this.state.searchTransactionFromDate === "" ||
                          this.state.searchTransactionToDate === ""
                        }
                      >
                        Search
                      </button>
                      <button
                        type="button"
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px" }}
                        onClick={this.onClearSearch}
                      >
                        Clear
                      </button>
                      <button
                        className="btn btn-default btn-flat btn-md pull-right"
                        style={{ marginTop: "25px", marginRight: "5px" }}
                        onClick={this.onExportClick}
                      >
                        Export <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <br></br>
                  {this.state.loading && <Loader show={this.state.loading} />}
                  {!this.state.loading && (
                    <div
                      style={{ width: "auto", overflowX: "auto" }}
                      className="scroll2"
                    >
                      {ReactHtmlParser(this.state.finalTable)}
                    </div>
                  )}
                  {this.state.totalItemsCount > 0 && (
                    <div className="col-md-12">
                      <div className="pull-left" style={{ marginTop: "25px" }}>
                        {this.state.paginationStatus}
                      </div>
                      <div className="pull-right">
                        <Pagination
                          activePage={this.state.activePage} //this.state.activePage
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={2}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(InvoiceDetailList);
